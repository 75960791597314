import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Select,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { styled } from '@mui/system';

export const AttractionsPageContainer = styled(Box)`
  width: 100%;
  margin-top: 100px;
  position: relative;
`;

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled(Box)`
  width: 250px;
  text-decoration: none;
  .MuiButton-root {
    color: #fff;
  }
`;

export const StyledVisitsTableHead = styled(TableHead)`
  .MuiTableCell-root {
    padding: 5px 16px;

    :nth-of-type(1) {
      width: 0%;
    }
    :nth-of-type(2) {
      width: 265px;
    }
    :nth-of-type(3) {
      width: 265px;
    }
    :nth-of-type(4) {
      padding-right: 7px;
    }
    :nth-of-type(5) {
      padding-right: 7px;
    }
    :nth-of-type(6) {
      padding-right: 7px;
    }
    :nth-of-type(7) {
      padding-right: 7px;
    }
    :nth-of-type(8) {
      padding-right: 7px;
    }
  }
`;

export const StyledTableHeadRow = styled(TableRow)`
  user-select: none;
  .MuiTableSortLabel-icon {
    opacity: 0.3;
  }

  th {
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
  }

  th:first-of-type {
    border-left: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    padding: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const CircularRowProgress = styled(TableRow)`
  width: 100%;
  height: 450px;
  position: relative;

  .MuiCircularProgress-root {
    position: absolute;
    left: 50%;
    top: 200px;
  }
`;

export const StyledVisitsTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 1.43;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: #ffa94c;
    cursor: pointer;
  }
`;

export const StyledAttractionTableBox = styled(Box)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VisitsFormField = styled(TextField)`
  max-width: 200px;
  width: 100%;
`;

export const VisitsTableFilterFormWrapper = styled(Box)`
  display: flex;
  gap: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const VisitsFilterFormDateField = styled(MobileDatePicker)`
  max-width: 200px;
  width: 100%;
`;

export const VisitsFilterDateTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const StyledVisitsFilterAttractionsField = styled(TextField)`
  max-width: 300px;
  width: 100%;
`;

export const VisitAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const CustomSelect = styled(Select)`
  width: 128px;
`;

export const StyledFilterOption = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block !important;
`;

export const AttractionsTableFilterFormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const FilterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
`
