import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import ArticlesTab from './ArticlesTab';

import { getArticleListThunk } from '../../../redux/publishingReducers/articlesReducer/articlesActions';
import { CONTENT_LANGUAGES } from '../../../consts/consts';
import { sortLanguageListInDestinationSettingsOrder } from '../../../helpers/sortLanguageListInDestinationSettingsOrder';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ARTICLES_TYPES } from './constants';
import { incrementCompletedTasks, setRefresh, setStateRefreshData, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const ArticlesTabContainer = (props) => {
  const { languageList, articleList, isLoading, currentDestinationId } = props;
  const dispatch = useDispatch();

  const [activeRowId, setActiveRowId] = useState(-1);
  const [typeValue, setTypeValue] = useState('');

  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const articleTypeList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === ARTICLES_TYPES
    )
  );
  const handleRowExpand = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const resetFilters = () => {
    setTypeValue('');
  };
  const navigate = useNavigate();

  const handleAddArticle = () => navigate('new-article');

  const { isBaseTasksLoaded, needToRefresh } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    dispatch(setTasks(4))
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh && currentDestinationId) {
        await dispatch(getArticleListThunk(currentDestinationId, typeValue, '', false));
        dispatch(incrementCompletedTasks());
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh]);
  

  useEffect(() => {
    dispatch(getArticleListThunk(currentDestinationId, typeValue));
  }, [dispatch, currentDestinationId, typeValue]);

  return (
    <ArticlesTab
      onRowExpand={handleRowExpand}
      activeRowId={activeRowId}
      setActiveRowId={setActiveRowId}
      languageList={languageList}
      articleList={articleList}
      isLoading={isLoading}
      resetActiveRow={resetActiveRow}
      typeValue={typeValue}
      setTypeValue={setTypeValue}
      selectedLanguage={selectedLanguage}
      handleAddArticle={handleAddArticle}
      articleTypeList={articleTypeList}
      resetFilters={resetFilters}
    />
  );
};

const mapStateToProps = (state) => {
  const languageList = state.appReducer.globalSettingsData.find(
    (item) => item.name.en === CONTENT_LANGUAGES
  );

  const selectedDestinationLanguages =
    state.appReducer.selectedDestination.languages;

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };

  return {
    languageList: destinationLanguageList,
    articleList: state.articlesReducer.articleList,
    isLoading: state.articlesReducer.isLoading,
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps)(ArticlesTabContainer);
