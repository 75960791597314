import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  Typography,
} from '@mui/material';

import { ProductPageContainer } from './styled';

import ProductTableHeader from './productsTable/ProductTableHeader';
import ProductTableRow from './productsTable/ProductTableRow';
import { setTitle } from '../../redux/appReducer';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../redux/refreshDataStateReducer/refreshDataStateReducer';

const ProductsList = ({
  getProductsThunk,
  products,
  currentDestinationData,
  isDataLoading,
  languages,
  getProductSettingsThunk,
  isProductSettingsLoading,
  productSettings,
  currenciesList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeRowId, setActiveRowId] = useState(-1);

  const { id, valueAddedTaxes, currency } = currentDestinationData;

  const currencyCode =
    currenciesList &&
    currency &&
    currenciesList.parameters.find((curr) => curr.id === currency)?.value;

  const handleExpandRow = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_products')));
    getProductSettingsThunk();
  }, [getProductSettingsThunk]);

  useEffect(() => {
    id && getProductsThunk(id);
  }, [getProductsThunk, id]);

  useEffect(() => {
    if(id){
      dispatch(setTasks(5))
    }
    else {
      dispatch(setTasks(4))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await getProductSettingsThunk();
        dispatch(incrementCompletedTasks());
  
        if(id)
        {
          await getProductsThunk(id);
          dispatch(incrementCompletedTasks());
        }
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <ProductPageContainer>
      <Box mb="10px">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('products.headline')}
        </Typography>
      </Box>

      {isDataLoading || isProductSettingsLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <ProductTableHeader />
          <TableBody>
            {products &&
              productSettings &&
              products.map((product, idx) => (
                <ProductTableRow
                  key={idx}
                  productData={product}
                  isExpanded={activeRowId === idx}
                  onExpand={() => handleExpandRow(idx)}
                  vatOptions={valueAddedTaxes}
                  currency={currencyCode}
                  resetActiveRow={() => setActiveRowId(-1)}
                  destinationId={id}
                  languages={languages}
                  productSettings={productSettings}
                />
              ))}
          </TableBody>
        </Table>
      )}
    </ProductPageContainer>
  );
};

export default ProductsList;
