import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, Outlet } from 'react-router-dom';

import { ButtonListContainer, StyledButton, StyledLink } from './styled';
import { Box, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getOpenedTabIdByUrlParameter, homeTabs } from './homeTabs';
import { a11yProps } from '../../components/tabPanel/TabPanel';

import {
  NAVIGATION_BUTTON_LIST,
  ADMIN_ROLE_LIST,
  MANAGER_ROLE_LIST,
  ATTRACTION_MANAGER,
  ATTRACTION_MANAGER_PRIMARY
} from './constants';
import { getDecodedToken } from '../../auth/AuthorizeToken';

const getCompanyPath = async () => {
  const token = await getDecodedToken();
  return `my-company/${token.company}`;
};

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
  tabsShortened: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      width: '410px',
    },
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: '100%',
    },
  },
  iconButton: {
    '&.MuiIconButton-root': {
      width: '30px',
      height: '30px',
      padding: 0,
      content: '',
      position: 'absolute',
      top: '7px',
      left: '-76px',
    },
  },
  wrapper: {
    maxWidth: '1350px',
    width: '100%',
    margin: '95px auto',
    position: 'relative',
  },
});

const Home = (props) => {
  const { authReducer, setTitle } = props;

  const { t } = useTranslation();
  const authorizedUserRole = authReducer.role;
  const location = useLocation();
  const classes = useStyles();

  const [updatedHomeTabs, setUpdatedHomeTabs] = useState(homeTabs);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const responseToken = await getDecodedToken();
      const filteredRole = Array.isArray(responseToken.role) 
        ? responseToken.role.filter(role => role !== 'visitor')[0]
        : responseToken.role === 'visitor' ? null : responseToken.role;
      setRole(filteredRole);
    };

    const fetchCompanyId = async () => {
      const companyId = await getCompanyPath();
      const companyPath = `/${companyId}`;      
      const companyTab = {
        name: 'home.company_tab',
        showForRoles: [ATTRACTION_MANAGER_PRIMARY],
        path: companyPath,
        matchUrls: ['my-company'],
      };
      setUpdatedHomeTabs([...homeTabs.slice(0,2), companyTab, ...homeTabs.slice(2)]);      
    };

    fetchToken();
    fetchCompanyId();
  }, []);

  const filteredHomeTabs = useMemo(() => updatedHomeTabs.filter(tab => {
    return !tab.showForRoles || tab.showForRoles.includes(role);
  }), [updatedHomeTabs, role]);

  const [activeTabId, setActiveTabId] = useState(
    getOpenedTabIdByUrlParameter(filteredHomeTabs, location.pathname)
  );

  const handleTabsChange = (_, tabIndex) => {
    setTitle(filteredHomeTabs[tabIndex].name)
    setActiveTabId(tabIndex);
  };

  useEffect(() => {
    if(filteredHomeTabs.length > 0) {
      let tabIndex = getOpenedTabIdByUrlParameter(filteredHomeTabs, location.pathname);
      setTitle(filteredHomeTabs[tabIndex].name)
      setActiveTabId(tabIndex);
    }
  }, [location.pathname, filteredHomeTabs])

  const isAdminRoleSelected = ADMIN_ROLE_LIST.includes(role);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(role);
  const isAttractionManager = ATTRACTION_MANAGER === role;

  var buttons = null;

  if (isAdminRoleSelected) {
    buttons = NAVIGATION_BUTTON_LIST.map((buttonData) =>
      buttonData.showForRoles.some((role) =>
        authorizedUserRole.includes(role)
      ) ? (
        <StyledLink to={buttonData.link} key={buttonData.name}>
          <StyledButton variant="outlined">
            {t(`${buttonData.name}`)}
          </StyledButton>
        </StyledLink>
      ) : null
    );
  }

  if (isManagerRoleSelected) {
    buttons = 
    <Box component="div" className={classes.wrapper}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTabId}
          className={isAttractionManager || role ===  'regionalmanager' ? classes.tabsShortened : classes.tabs}
          onChange={handleTabsChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {filteredHomeTabs.map((tab, tabIndex) => {
            if (tabIndex === 0) {
              return (
                <Tab
                  component={Link}
                  to={tab.path}
                  label={t(tab.name)}
                  {...a11yProps(tabIndex)}
                  key={tabIndex}
                />
              );
            }
            return (
              <Tab
                component={Link}
                to={tab.path}
                label={t(tab.name)}
                x={{ width: '20%' }}
                //disabled={attractionsState.addElement}
                {...a11yProps(tabIndex)}
                key={tabIndex}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box sx={{ pt: 2, pb: 2, px: 0 }}>
        <Outlet />
      </Box>
    </Box>
  }

  return <ButtonListContainer style={{width: isManagerRoleSelected ? '100%' : '80%'}}>{buttons}</ButtonListContainer>;
};

export default Home;
