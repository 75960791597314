import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import UserInfoForm from './forms/UserInfoForm';
import PasswordChangeDialog from '../users/usersTab/components/PasswordChangeDialog';
import ConfirmationDialog from '../../components/confirmationDialog/ConfirmationDialog';

import { LoaderWrapper, MyProfilePageWrapper } from './styled';

import useAPIError from '../../apIErrorProvider/useAPIError';
import { getSmallDataFromList } from '../users/usersTab/helpers.js';
import {
  getAvailableCategoriesForDestination,
  getFormatedItemsByDestinationId,
} from './utils';
import { ApplicationPaths } from '../../auth/ApiAuthorizationConstants';
import { USER_ROLES } from '../../consts/consts';
import { USER_INFO_FORM, MANAGER_ROLE_LIST } from './constants';
import { createVisitorThunk } from "../../redux/usersReducers/users/userActions";
import { setTitle } from '../../redux/appReducer.js';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../redux/refreshDataStateReducer/refreshDataStateReducer.js';

const useStyles = makeStyles({
  wrapper: {
    margin: '0 0 0 -15px',
  },
});

const MyProfilePage = (props) => {
  const {
    userData,
    availableLanguages,
    selectedDestinationId,
    selectedLanguage,

    companyReducer,
    categoryReducer,
    settingsReducer,
    userReducer,
    getCurrentUserThunk,
    getUserSettingsThunk,
    getCompaniesListThunk,
    getCategoryThunk,
    getSmallAttractionsDataThunk,
    changeCurrentUserPasswordThunk,
    attractionsReducer,
    deleteCurrentUserThunk,
    editCurrentUserThunk,
    pointList,
    getSalesPointShortDataListThunk,
  } = props;

  const logoutPath = ApplicationPaths.LogOut;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showPasswordChangePopup, setShowPasswordChangePopup] = useState(false);
  const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false);
  const [showVisitorCreateAlert, setVisitorCreateAlert] = useState(false);

  const userRoles = userReducer.userSettings.find(
    (item) => item.name.en === USER_ROLES
  );

  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(userData.role);

  const [filteredRegions, filteredAreas] = getFormatedItemsByDestinationId(
    settingsReducer.dictionaryRegionData,
    selectedDestinationId
  );


  const filteredCompanies = getSmallDataFromList(companyReducer.companiesData);

  const sortedAttractions = attractionsReducer.attractionsSmallData.sort(
    (a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
  );

  const handleFormSubmit = (fields) => {
    setShowSaveAnimation(true);
    
    if(!!fields.position)
      fields.position = fields.position.trim()

    if(!!fields.name)
      fields.name = fields.name.trim()
    
    if(!!fields.email)
      fields.email = fields.email.trim()
    
    if(!!fields.phone)
      fields.phone = fields.phone.trim()

    const updatedUserData = {
      ...userData,
      name: fields.name,
      position: fields.position,
      email: fields.email,
      phone: fields.phone,
      language: fields.language,
    };

    editCurrentUserThunk(updatedUserData)
      .then(() => {
        addError(`${t('users.success_edit_user')}`, 'Success');
        getCurrentUserThunk();
        setShowSaveAnimation(false);
        setIsInEditMode(false);
      })
      .catch(() => {
        addError(`${t('users.error_edit_user')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  const resetFormValues = () => {
    dispatch(reset(USER_INFO_FORM));
  };

  const handleFormEdit = () => {
    setIsInEditMode(true);
  };
  const handleFormCancel = () => {
    setIsInEditMode(false);
    resetFormValues();
  };

  const deleteUserAccount = () => {
    setIsInEditMode(false);
    setShowSaveAnimation(true);
    deleteCurrentUserThunk()
      .then(() => {
        addError(`${t('users.success_delete_user')}`, 'Success');
        setShowSaveAnimation(false);
        navigate(logoutPath);
      })
      .catch(() => {
        addError(`${t('users.error_delete_user')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  const hanldeFormDelete = () => {
    setShowDeletePopup(true);
  };

  const handleDeletePopupCancel = () => {
    setShowDeletePopup(false);
  };

  const handleDeletePopupSubmit = () => {
    setShowDeletePopup(false);
    deleteUserAccount();
  };

  const handleFormPasswordChange = () => {
    setShowPasswordChangePopup(true);
  };

  const handleFormVisitorCreation = () => {
    setVisitorCreateAlert(true);
  };

  const handlePasswordChangePopupCancel = () => {
    setShowPasswordChangePopup(false);
  };

  const handleVisitorCreationPopupCancel = () => {
    setVisitorCreateAlert(false);
  };

  const handlePasswordChangePopupSubmit = () => {
    setShowPasswordChangePopup(false);
    setShowPasswordChangeForm(true);
  };

  const handlePasswordChangeFormCancel = () => {
    setShowPasswordChangeForm(false);
  };

  const handleVisitorCreationPopupSubmit = () => {
    setShowSaveAnimation(true);
    setVisitorCreateAlert(false);
    dispatch(createVisitorThunk(userData.id))
        .then(() => {
          getCurrentUserThunk();
          setIsInEditMode(false);
          setShowSaveAnimation(false);
          addError(`${t('users.success_edit_user')}`, 'Success');
        })
        .catch((e) => {
          addError(`${t('users.error_edit_user')}`, 'Error');
          setShowSaveAnimation(false);
        });
  };

  const handlePasswordChangeFormSubmit = (newPasswordValue) => {
    const updatedUser = {
      password: newPasswordValue,
    };

    changeCurrentUserPasswordThunk(updatedUser)
      .then(() => {
        setShowPasswordChangeForm(false);
        addError(`${t('users.password_change_success')}`, 'Success');
      })
      .catch((e) => {
        setShowPasswordChangeForm(false);
        addError(`${t('users.error_password_change')}`, 'Error', e);
      });
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_my_profile')));

    if (selectedDestinationId) {
      dispatch(setTasks(8))
    } else {
      dispatch(setTasks(4))
    }
  }, [])

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    if (isBaseTasksLoaded && needToRefresh) {
      const fetchData = async () => {
        await getUserSettingsThunk();
        dispatch(incrementCompletedTasks());
    
        if (selectedDestinationId) {
          await getCategoryThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());

          await getCompaniesListThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());
    
          await getSmallAttractionsDataThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());
    
          await getSalesPointShortDataListThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());
        }
      };
    
      fetchData();
    }
    
  }, [isBaseTasksLoaded, needToRefresh]);

  useEffect(() => {
    getCurrentUserThunk();
    getUserSettingsThunk();
    if (selectedDestinationId) {
      getCategoryThunk(selectedDestinationId);
      getCompaniesListThunk(selectedDestinationId);
      getSmallAttractionsDataThunk(selectedDestinationId);
      getSalesPointShortDataListThunk(selectedDestinationId);
    }
  }, [
    selectedDestinationId,
    getCurrentUserThunk,
    getUserSettingsThunk,
    getCompaniesListThunk,
    getCategoryThunk,
    getSmallAttractionsDataThunk,
    getSalesPointShortDataListThunk,
  ]);

  const isContentLoading =
    userReducer.isCurrentUserLoading && !userReducer.currentUser?.id;

  return (
    <MyProfilePageWrapper className={isManagerRoleSelected ? classes.wrapper : null}>
      {!isContentLoading ? (
        <>
          <UserInfoForm
            userData={userData}
            isInEditMode={isInEditMode}
            onSubmit={handleFormSubmit}
            onCancel={handleFormCancel}
            onEdit={handleFormEdit}
            onDelete={hanldeFormDelete}
            onFormPasswordChange={handleFormPasswordChange}
            onFormVisitorCreate={handleFormVisitorCreation}
            options={{
              attractionList: sortedAttractions,
              companyList: filteredCompanies,
              regionList: filteredRegions,
              areaList: filteredAreas,
              roleList: userRoles,
              defaultUserCompany: companyReducer.defaultCompany,
              languages: availableLanguages,
            }}
            selectedLanguage={selectedLanguage}
            showSaveAnimation={showSaveAnimation}
          />
          <ConfirmationDialog
            showAlert={showDeletePopup}
            handleClose={handleDeletePopupCancel}
            handleCloseSuccess={handleDeletePopupSubmit}
            text={t('users.delete_user_account')}
          />
          <ConfirmationDialog
            showAlert={showPasswordChangePopup}
            handleClose={handlePasswordChangePopupCancel}
            handleCloseSuccess={handlePasswordChangePopupSubmit}
            text={t('users.change_account_password')}
          />
          <ConfirmationDialog
              showAlert={showVisitorCreateAlert}
              handleClose={handleVisitorCreationPopupCancel}
              handleCloseSuccess={handleVisitorCreationPopupSubmit}
              text={t('users.create_visitor_alert')}
          />
          <PasswordChangeDialog
            onClose={handlePasswordChangeFormCancel}
            isOpen={showPasswordChangeForm}
            onDialogSubmit={handlePasswordChangeFormSubmit}
          />
        </>
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </MyProfilePageWrapper>
  );
};

export default MyProfilePage;
