import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import cl from './Seasons.module.css';

import { ICON_DEFAULT_COLOR } from './constants';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { validateTextFieldValue } from '../../../helpers/validateTextFieldValue';

const useStyles = makeStyles({
  listBox: {
    width: '400px',
    '& .MuiListItem-root': {
      padding: '0px 2px',
      width: '100%',
      '&:hover': {
        padding: '0px 2px',
        borderRadius: '5px',
      },
      '&.Mui-selected': {
        borderRadius: '5px',
      },
    },
  },
  button: {
    '&.MuiButton-root': {
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
  },
});

const Seasons = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const seasonsState = props.seasonsReducer;

  useEffect(() => {
    props.getSettingsSeasonsThunk(props.appReducer.selectedDestination.id);

    if(props.appReducer.selectedDestination.id){
      dispatch(setTasks(4))
    }
    else {
      dispatch(setTasks(3))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appReducer.selectedDestination.id]);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if(props.appReducer.selectedDestination.id)
        {
          await props.getSettingsSearchSeasonsThunk(props.appReducer.selectedDestination.id, searchValue);
          dispatch(incrementCompletedTasks());
        }
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  const onAddSeason = () => {
    navigate('new-season');
  };

  const clearValue = () => {
    setSearchValue('');
    props.getSettingsSeasonsThunk(props.appReducer.selectedDestination.id);
  };

  const handleListItemClick = (item, index) => {
    navigate(`view/${item.id}`);
  };

  const onChangeSearchInput = (searchValue) => {
    setSearchValue(searchValue);
    props.getSettingsSearchSeasonsThunk(props.appReducer.selectedDestination.id, searchValue);
  };

  if (!props.seasonsReducer.isLoadingSeasonsData) {
    return (
      <Box
        component="div"
        sx={{ position: 'absolute', top: '50%', right: '50%' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="div" className={cl.wrapper}>
      <div className={cl.leftBox}>
        <Typography
          fontSize="20px"
          lineHeight={1.5}
          fontWeight="500"
          textTransform="uppercase"
          mb="10px"
        >
          {t('settings_page.seasons.headline')}
        </Typography>
        <div className={cl.addButton}>
          <Button
            variant="contained"
            onClick={onAddSeason}
            sx={{ color: '#fff', width: '100%', height: '100%' }}
          >
            {t('settings_page.seasons.add_button')}
          </Button>
        </div>

        <Box sx={{ position: 'relative' }}>
          <TextField
            className={cl.searchInput}
            type="text"
            onChange={(e) => {
              const searchValue = validateTextFieldValue(e.target.value);
              onChangeSearchInput(searchValue);
            }}
            placeholder={t('settings_page.seasons.search_seasons')}
            value={searchValue}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue ? (
                    <IconButton
                      onClick={clearValue}
                      sx={{ padding: 0, color: ICON_DEFAULT_COLOR }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon sx={{ color: ICON_DEFAULT_COLOR }} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box component="div" className={classes.listBox}>
          {!seasonsState.settingsSeasonsData.length ? (
            <div className={cl.absent}>{t('settings_page.seasons.no_season')}</div>
          ) : (
            <List className={classes.list}>
              {seasonsState.settingsSeasonsData.map((item, index) => (
                <ListItem
                  button
                  key={item.id}
                  selected={seasonsState.currentSeason.index === index}
                  sx={{
                    paddingLeft: '2px',
                    width: '100%',
                    borderBottom: '1px solid rgba(0,0,0, 0.1)',
                  }}
                  onClick={() => handleListItemClick(item, index)}
                >
                  <ListItemText
                    sx={{ wordWrap: 'break-word' }}
                    primary={
                      item.name[props.appReducer.selectedLanguage] ||
                      item.name.en
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </div>
      <div className={cl.rightBox}>
        <Outlet />
      </div>
    </Box>
  );
};

export default Seasons;
