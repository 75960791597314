import { Field } from 'redux-form';

import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  FieldWrapper,
  ContentFormIconButton,
  ContentFormTextField,
} from '../styled';

import { validateTextFieldValue } from '../../../../helpers/validateTextFieldValue';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TEXT_FIELD_MAX_LENGTH_500 } from '../../../../consts/validation';

const FieldList = (props) => {
  const {
    fields,
    meta: { error },
    disabled,
    selectedLanguage,
    handleDeleteItem
  } = props;

  const { t } = useTranslation();

  const handleAddItemButtonClick = () => fields.push('');

  const useMaxLengthValidator = (max) => {
    return useCallback(
      (value) => value && value.length > max ? `${t('attractions.content.validation.max_length_begin')} ${max} ${t('attractions.content.validation.max_length_end')}` : undefined,
      [max, selectedLanguage]
    );
  };

  const maxLength500 = useMaxLengthValidator(TEXT_FIELD_MAX_LENGTH_500);

  return (
    <>
      <ContentFormIconButton
        disabled={disabled}
        onClick={handleAddItemButtonClick}
      >
        <AddCircleOutlineIcon fontSize="large" />
      </ContentFormIconButton>
      {fields.map((item, index) => (
        <FieldWrapper key={index}>
          <Field
            name={item}
            type="text"
            component={ContentFormTextField}
            disabled={disabled}
            validate={[maxLength500]}
            parse={(value) => validateTextFieldValue(value)}
          />
          <ContentFormIconButton
            disabled={disabled}
            onClick={() => handleDeleteItem ? handleDeleteItem(index): fields.remove(index)}
          >
            <DeleteIcon fontSize="large" />
          </ContentFormIconButton>
        </FieldWrapper>
      ))}
      {error && <Box className="error">{error}</Box>}
    </>
  );
};

export default FieldList;
