import {
  createHelpTopic,
  deleteHelpTopic,
  getHelpTopicList,
  updateHelpTopic,
} from '../../../api/publishing/helpTopicsApi';

export const SET_HELP_TOPICS_LIST = 'helpTopics/setArticleList';
export const SET_IS_LOADING = 'helpTopics/setIsLoading';

export const setHelpTopicsList = (data) => {
  return {
    type: SET_HELP_TOPICS_LIST,
    payload: data,
  };
};

export const setIsHelpTopicListLoading = (data) => {
  return {
    type: SET_IS_LOADING,
    payload: data,
  };
};

export const getHelpTopicsListThunk = (destinationId, isLoading = true) => async (dispatch) => {
  isLoading && dispatch(setIsHelpTopicListLoading(true));

  const response = await getHelpTopicList(destinationId);

  if (response.status === 200) {
    dispatch(setHelpTopicsList(response.data));
    isLoading && dispatch(setIsHelpTopicListLoading(false));
  }
};

export const updateHelpTopicThunk = (topicId, data) => async () => {
  const response = await updateHelpTopic(topicId, data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const createHelpTopicThunk = (data) => async () => {
  const response = await createHelpTopic(data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const deleteHelpTopicThunk = (itemId) => async () => {
  const response = await deleteHelpTopic(itemId);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
