const initialState = {
  tasks: 3,
  completedTask: 0,
  needToRefresh: false,
  isBaseTasksLoaded: false,
};

// Константы для типов действий
const SET_TASKS = 'SET_TASKS';
const INCREMENT_COMPLETED_TASKS = 'INCREMENT_COMPLETED_TASKS';
const SET_REFRESH = 'SET_REFRESH';
const RESET_TASKS = 'RESET_TASKS';

const refreshDataStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
        completedTask: 0,
        isBaseTasksLoaded: false,
      };

    case INCREMENT_COMPLETED_TASKS:
      const newCompleted = state.completedTask + 1;
      const isLoaded = newCompleted === 3;

      return {
        ...state,
        completedTask: newCompleted,
        isBaseTasksLoaded: isLoaded,
      };

    case SET_REFRESH:
      return {
        ...state,
        needToRefresh: action.payload,
        completedTask: 0,
      };

    case RESET_TASKS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

// Экшен-криэйторы
export const setTasks = (count) => ({ type: SET_TASKS, payload: count });
export const incrementCompletedTasks = () => ({ type: INCREMENT_COMPLETED_TASKS });
export const setRefresh = (status) => ({ type: SET_REFRESH, payload: status });
export const resetTasks = () => ({ type: RESET_TASKS });

export default refreshDataStateReducer;
