import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CircularProgress, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import LocationTreeView from './locationTreeVliew/LocationTreeView';

import cl from './Destination.module.css';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const useStyles = makeStyles({
  treeView: {
    '& .MuiTreeItem-root': {
      width: '100%',
    },
    '& .MuiTreeItem-content': {
      minHeight: '35px',
      '& .MuiTreeItem-label': {
        width: '280px',
        height: 'auto',
        wordWrap: 'break-word',
      },
    },
    '& .Mui-focused': {
      borderRadius: '4px',
    },
    '& .Mui-selected': {
      borderRadius: '4px',
    },
    '& .MuiTreeItem-content:hover': {
      borderRadius: '4px',
    },
  },
  item: {
    position: 'relative',
    '& .MuiTreeItem-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

const Destination = (props) => {
  const { getPredefinedSettingsThunk, selectedDestinationId } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const destinationsState = props.settingsReducer;
  const predefinedState = props.predefinedReducer;

  const locationData = destinationsState.destinationData.filter(destination => destination.id === selectedDestinationId)

  useEffect(() => {
    getPredefinedSettingsThunk();

    dispatch(setTasks(4))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await getPredefinedSettingsThunk();
        dispatch(incrementCompletedTasks());
      }
    }

    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  const handleDestinationAdd = () => {
    navigate('new/destination');
  };

  const handleRegionAdd = (_, destination) => {
    navigate(`new/Region/${destination.id}`);
  };

  const handleAreaAdd = (_, location, destination) => {
    navigate(`new/Area/${destination.id}/${location?.id || ''}`);
  };

  const handleLocationAdd = (_, location, destination) => {
    navigate(`new/Location/${destination.id}/${location?.id || ''}`);
  };

  const handleDestinationClick = async (_, destination) => {
    navigate(`view/${destination.id}`);
  };

  const handleRegionClick = (_, region) => {
    navigate(`view/${region.destinationId}/${region.id}`);
  };

  const handleAreaClick = (_, area) => {
    const region = destinationsState.dictionaryRegionData.find((region) =>
      region.children.find((item) => item.id === area.id)
    );
    navigate(`view/${area.destinationId}/${region?.id}/${area.id}`);
  };

  const handleLocationClick = (_, location) => {
    const locationArea = destinationsState.dictionaryAreaData.find((area) =>
      area.children.find((item) => item.id === location.id)
    );

    const locationRegion = destinationsState.dictionaryRegionData.find(
      (region) => region.children.find((item) => item.id === locationArea?.id)
    );

    navigate(
      `view/${location.destinationId}/${locationRegion?.id}/${locationArea?.id}/${location.id}`
    );
  };

  if (predefinedState.isLoadingPredefinedData) {
    return (
      <Box sx={{ position: 'absolute', top: '50%', right: '50%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.leftBox}>
        <div className={cl.addButton}>
          <Button
            variant="contained"
            onClick={handleDestinationAdd}
            sx={{ color: '#fff', width: '100%', height: '100%' }}
          >
            {t('settings_page.destination.add_button')}
          </Button>
        </div>
        {!destinationsState.destinationData.length ? (
          <div className={cl.absent}>
            {t('settings_page.destination.no_destination')}
          </div>
        ) : (
          <LocationTreeView
            locationData={locationData}
            onDestinationClick={handleDestinationClick}
            onRegionClick={handleRegionClick}
            onAreaClick={handleAreaClick}
            onLocationClick={handleLocationClick}
            onAddRegion={handleRegionAdd}
            onAddArea={handleAreaAdd}
            onAddLocation={handleLocationAdd}
            classes={classes}
          />
        )}
      </div>
      <div className={cl.rightBox}>
        <Outlet />
      </div>
    </div>
  );
};

export default Destination;
