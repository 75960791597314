import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getAttractionsListThunk,
  getAttractionSearchThunk,
  getAttractionThunk,
  setAddElement,
} from '../../redux/attractionsReducers/attractions/attractionsActions';

import { getCategoryThunk } from '../../redux/settingsReducers/categoryReducer';

import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';

import { getFacilitiesThunk } from '../../redux/settingsReducers/facilitiesReducer';

import { getTagsThunk } from '../../redux/settingsReducers/tagsReducer';

import { getCompaniesListThunk } from '../../redux/usersReducers/company/companyActions';

import {
  setTargetLocation,
  setShowUnsavedChangesAlert,
} from '../../redux/routeChangeReducer/routeChangeActions';

import Attraction from './Attraction';

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    attractionsState: state.attractionsReducer,
    settingsState: state.settingsReducer,
    routeChangeReducer: state.routeChangeReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default compose(
  connect(mapStateToProps, {
    setAddElement,
    getAttractionsListThunk,
    getAttractionSearchThunk,
    getAttractionThunk,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getFacilitiesThunk,
    getTagsThunk,
    getCompaniesListThunk,
    setTargetRouteUrl: setTargetLocation,
    setShowUnsavedChangesAlert,
  })
)(Attraction);
