import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  scrollwheel: true,
  disableDoubleClickZoom: false,
  fullscreenControl: true,
};

const Map = ({ handleUpdateCoordinates, coordinates }) => {
  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 50.073988727436216, lng: 14.436026387041693 }}
      onClick={(e) => handleUpdateCoordinates(e)}
      defaultOptions={defaultOptions}
    >
      <Marker position={coordinates} />
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default WrappedMap;
