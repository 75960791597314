import * as axios from 'axios';
import authService from "../auth/AuthorizeService";
import { BASE_URL } from '../config.js'

// Global settings
export const getGlobalSettingsApi = async () => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/settings/global`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
} 
// Destinations
export const getDestinationsApi = async () => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/Destinations?isLocationInclude=true`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
}

export const getDestinationByIdApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/Destinations/${id}/?isLocationInclude=true`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
}

export const addDestinationApi = async (data) => {
    const token = await authService.getAccessToken();
    return axios.post(`${BASE_URL}/api/Destinations`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const editDestinationApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return axios.put(`${BASE_URL}/api/Destinations/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const deleteDestinationApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.delete(`${BASE_URL}/api/Destinations/${id}`, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

//Location

export const addLocationApi = async (destinationId, data) => {
    const token = await authService.getAccessToken();
    return axios.post(`${BASE_URL}/api/destinations/${destinationId}/locations`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch( error => {
        return error.response
    })
}

export const editLocationApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return axios.put(`${BASE_URL}/api/destinations/location/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch( error => {
        return error.response
    })
}

export const deleteLocationApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.delete(`${BASE_URL}/api/destinations/location/${id}`, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch( error => {
        return error.response
    })
}


// Facilities
export const getFacilitiesApi = async (selectedDestinationId) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/AttractionFacilities/destinations/${selectedDestinationId}`, {
        headers: { 
            Authorization: `Bearer ${token}` 
        }
    }).catch( error => {
        return error.response
    })
}

export const addFacilitiesApi = async (data) => {
    const token = await authService.getAccessToken();
    return axios.post(`${BASE_URL}/api/dictionaries/AttractionFacilities`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}

export const deleteFacilitiesApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.delete(`${BASE_URL}/api/dictionaries/AttractionFacilities/${id}`, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}

export const editFacilitiesApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return axios.put(`${BASE_URL}/api/dictionaries/AttractionFacilities/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}

//Predefined Settings
export const getPredefinedSettingsApi = async () => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/settings/attractions`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
} 

// Tags
export const getTagsApi = async (selectedDestinationId) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/AttractionTags/destinations/${selectedDestinationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>  {
        return error.response
    })
}

export const getSearchTagsApi = async (name, selectedDestinationId) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/AttractionTags/destinations/${selectedDestinationId}?filter=${name}`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
}

export const addTagsApi = async (data) => {
    const token = await authService.getAccessToken();
    return axios.post(`${BASE_URL}/api/dictionaries/AttractionTags`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}
export const deleteTagsApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.delete(`${BASE_URL}/api/dictionaries/AttractionTags/${id}`, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}
export const editTagsApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return axios.put(`${BASE_URL}/api/dictionaries/AttractionTags/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}

// Seasons
export const getSettingsSeasonsApi = async (destinationId) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/AttractionSeasons/destinations/${destinationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>  {
        return error.response
    })
}

export const getSettingsSearchSeasonsApi = async (destinationId, name) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/AttractionSeasons/destinations/${destinationId}?filter=${name}`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
}

export const addSettingsSeasonsApi = async (data) => {
    const token = await authService.getAccessToken();
    return axios.post(`${BASE_URL}/api/dictionaries/AttractionSeasons`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}
export const deleteSettingsSeasonsApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.delete(`${BASE_URL}/api/dictionaries/AttractionSeasons/${id}`, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}
export const editSettingsSeasonsApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return axios.put(`${BASE_URL}/api/dictionaries/AttractionSeasons/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}

// Category
export const getCategoriesApi = async (selectedDestinationId) => {
    const token = await authService.getAccessToken();
    return axios.get(`${BASE_URL}/api/dictionaries/AttractionCategories/destinations/${selectedDestinationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
}

export const getSearchCategoryApi = async (status, destinationId) => {
    const token = await authService.getAccessToken();
    let url = `${BASE_URL}/api/dictionaries/AttractionCategories/destinations/${destinationId}?`;
    if(status) {
        url+=`&status=${status}`
    }
    return await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error =>{
        return error.response
    })
}

export const addCategoriesApi = async (data) => {
    const token = await authService.getAccessToken();
    return axios.post(`${BASE_URL}/api/dictionaries/AttractionCategories`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const deleteCategoryApi = async (id) => {
    const token = await authService.getAccessToken();
    return axios.delete(`${BASE_URL}/api/dictionaries/AttractionCategories/${id}`, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const editCategoryApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return axios.put(`${BASE_URL}/api/dictionaries/AttractionCategories/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

// image (file)

export const sendIconApi = async (icon) => {
    const token = await authService.getAccessToken();
        const formData = new FormData();
        formData.append('files', icon);
        return await axios.post(`${BASE_URL}/api/files/images/upload`, formData, {
            headers: { 
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            }
        }).catch(error =>{
            return error.response
        })
}