import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Typography } from '@mui/material';

import AccountAccessForm from './forms/AccountAccessForm';
import CompanyForm from '../../users/companiesTab/forms/CompanyForm';
import ManagedAttractionsForm from '../../users/companiesTab/forms/ManagedAttractionsForm';

import { LoaderWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import {
  COMPANY_FORM,
  MANAGED_ATTRACTIONS_FORM,
} from '../../users/companiesTab/constants';
import {
  removeEmptyObjects,
  mapAttractionsToAttractionContacts,
  excludeAttractionNames,
} from '../../users/companiesTab/utils';
import { getAllowedUserAccounts } from './utils';
import { useNavigationPrompt } from '../../../hooks/navigation';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { getAttractionThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';

const Company = (props) => {
  const {
    statusOptions,
    selectedLanguage,
    attractionsData,
    editCompanyThunk,
    selectedDestinationId,
    getUserSettingsThunk,
    getUsersListThunk,
    userRoles,
    userList,
    currentAttraction,
    getCurrentCompanyThunk,
    getAttractionListOfCompaniesThunk,
    attractionListOfCompanies,
    companyData,
    isCompanyFormDirty,
    isManagedAttractionsFormDirty,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addError } = useAPIError();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isCompanyFormInEditMode, setIsCompanyFormInEditMode] = useState(false);
  const [
    isManagedAttractionsFormInEditMode,
    setIsManagedAttractionsFormInEditMode,
  ] = useState(false);

  const allowedUserAccounts = getAllowedUserAccounts(
    userList,
    currentAttraction
  );

  const companyAttractionsList = attractionListOfCompanies.find(
    (company) => currentAttraction.companyId === company.id
  );

  const resetForm = (formName) => {
    dispatch(reset(formName));
  };

  const handleCompanyFormSubmit = (formData) => {
    setIsCompanyFormInEditMode(false);
    setShowSaveAnimation(true);

    const updatedCompanyData = {
      id: formData.id,
      status: formData.status,
      name: formData.name,
      website: formData.website,
      streetAddress: formData.streetAddress,
      city: formData.city,
      zipCode: parseInt(formData.zipCode) || null,
      country: formData.country,
      registrationNumber: parseInt(formData.registrationNumber) || null,
      valueAddedTaxNumber: formData.valueAddedTaxNumber,
      personContacts: removeEmptyObjects(formData.personContacts),
      billingContacts: removeEmptyObjects(formData.billingContacts),
      bankAccount: formData.bankAccount,
      attractionContacts: companyData.attractionContacts,
    };

    editCompanyThunk(updatedCompanyData.id, updatedCompanyData)
      .then(() => {
        getCurrentCompanyThunk(currentAttraction?.companyId);
        setShowSaveAnimation(false);
        addError(`${t('companies.success_edit_company')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('companies.error_edit_company')}`, 'Error', error);
        setShowSaveAnimation(false);
        resetForm(COMPANY_FORM);
      });
  };

  const handleManagedAttractionsFormSubmit = (formData) => {
    setIsManagedAttractionsFormInEditMode(false);
    setShowSaveAnimation(true);

    const updatedCompanyData = {
      ...companyData,
      attractionContacts: excludeAttractionNames(formData.attractionContacts),
    };

    editCompanyThunk(updatedCompanyData.id, updatedCompanyData)
      .then(() => {
        getCurrentCompanyThunk(currentAttraction?.companyId);
        setShowSaveAnimation(false);
        addError(`${t('companies.success_edit_company')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('companies.error_edit_company')}`, 'Error', error);
        setShowSaveAnimation(false);
        resetForm(MANAGED_ATTRACTIONS_FORM);
      });
  };

  const handleCompanyFormEdit = () => {
    setIsCompanyFormInEditMode(true);
  };

  const handleCompanyFormCancel = () => {
    setIsCompanyFormInEditMode(false);
    resetForm(COMPANY_FORM);
  };

  const handleManagedAttractionsFormEdit = () => {
    setIsManagedAttractionsFormInEditMode(true);
  };

  const handleManagedAttractionsFormCancel = () => {
    setIsManagedAttractionsFormInEditMode(false);
    resetForm(MANAGED_ATTRACTIONS_FORM);
  };

  const mappedAttractionContacts = useMemo(
    () =>
      mapAttractionsToAttractionContacts(
        companyAttractionsList?.attractions,
        companyData?.attractionContacts
      ),
    [companyAttractionsList?.attractions, companyData?.attractionContacts]
  );

  useEffect(() => {
    if (currentAttraction?.companyId)
      getCurrentCompanyThunk(currentAttraction?.companyId);
    getUserSettingsThunk();
    getUsersListThunk({});
    getAttractionListOfCompaniesThunk(selectedDestinationId);
  }, [
    getUsersListThunk,
    getUserSettingsThunk,
    selectedDestinationId,
    getCurrentCompanyThunk,
    getAttractionListOfCompaniesThunk,
    currentAttraction?.companyId,
  ]);

  useNavigationPrompt(isCompanyFormDirty || isManagedAttractionsFormDirty);

  const appState = useSelector(
    (state) => state.appReducer
  );

  const settingsState = useSelector(
    (state) => state.settingsReducer
  );

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    var tasksCount = 10;

    if(currentAttraction.id) tasksCount++;
    if(selectedDestinationId) tasksCount++;
    if(currentAttraction?.companyId) tasksCount++;

    dispatch(setTasks(tasksCount));
  }, [])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (currentAttraction.id) {
          await dispatch(getAttractionThunk(currentAttraction.id));
          dispatch(incrementCompletedTasks());
        }
  
        if (selectedDestinationId){
          await getAttractionListOfCompaniesThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());
        }
  
        if (currentAttraction?.companyId){
          await getCurrentCompanyThunk(currentAttraction?.companyId);
          dispatch(incrementCompletedTasks());
        }
  
        await getUserSettingsThunk();
        dispatch(incrementCompletedTasks());

        await getUsersListThunk({});
        dispatch(incrementCompletedTasks());

        await dispatch(getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getPredefinedSettingsThunk());
        dispatch(incrementCompletedTasks());

        await dispatch(getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getTagsThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <>
      {currentAttraction?.companyId !== '' ? (
        <>
          {companyData && statusOptions ? (
            <>
              <CompanyForm
                item={companyData}
                onSubmit={handleCompanyFormSubmit}
                onFormCancel={handleCompanyFormCancel}
                onFormEdit={handleCompanyFormEdit}
                isInEditMode={isCompanyFormInEditMode}
                showSaveAnimation={showSaveAnimation}
                statusOptions={statusOptions}
                selectedLanguage={selectedLanguage}
                preventDelete
              />
              <ManagedAttractionsForm
                onSubmit={handleManagedAttractionsFormSubmit}
                onFormCancel={handleManagedAttractionsFormCancel}
                onFormEdit={handleManagedAttractionsFormEdit}
                isInEditMode={isManagedAttractionsFormInEditMode}
                companyData={companyData}
                attractionsData={attractionsData}
                showSaveAnimation={showSaveAnimation}
                mappedAttractionContacts={mappedAttractionContacts}
              />
              <AccountAccessForm
                userList={allowedUserAccounts}
                userRoles={userRoles}
              />
            </>
          ) : (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
        </>
      ) : (
        <Typography fontWeight={400} fontSize={20}>
          {t('companies.no_company_for_attraction')}
        </Typography>
      )}
    </>
  );
};

export default Company;
