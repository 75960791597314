import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import TagsForm from './TagsForm';

import { TagFormWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { CONTENT_LANGUAGES } from '../../../consts/consts';
import {
  getTagsThunk,
  addTagsThunk,
} from '../../../redux/settingsReducers/tagsReducer';
import { useNavigate } from 'react-router-dom';
import { TAGS_PAGE_ROUTE } from './constants';

const TagAddFormContainer = (props) => {
  const {
    tagsState,
    globalSettings,
    selectedLanguage,
    addTagsThunk,
    getTagsThunk,
    selectedDestinationId
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = (values) => {
    setShowSaveAnimation(true);

    const data = {
      destinationId: selectedDestinationId,
      name: values,
    };

    addTagsThunk(data)
      .then(() => {
        getTagsThunk(selectedDestinationId);
        setShowSaveAnimation(false);
        addError(`${t('settings_page.tags.error_add_text')}`, 'Success');
        navigate(TAGS_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.tags.error_not_add_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(TAGS_PAGE_ROUTE);
  };

  return (
    <TagFormWrapper>
      <TagsForm
        addTag
        onSubmit={handleFormSubmit}
        tagsState={tagsState}
        isEdit={false}
        updateTag={false}
        languages={globalSettings.filter(
          (item) => item.name.en === CONTENT_LANGUAGES
        )}
        selectedLanguage={selectedLanguage}
        isSave={showSaveAnimation}
        onCancel={handleFormCancel}
      />
    </TagFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    tagsState: state.tagsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default connect(mapStateToProps, {
  getTagsThunk,
  addTagsThunk,
})(TagAddFormContainer);
