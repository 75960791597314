import React from 'react';
import { change, Field } from 'redux-form';
import { Autocomplete, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

const RenderAutocomplete = ({
  input,
  meta: { touched, error },
  options,
  getOptionLabel,
  freeSolo,
  language,
  languages,
  ...rest
}) => {
  const dispatch = useDispatch();

  const handleAutocompleteChange = (dispatch, sectionName, languages, selectedOption) => {
    if (selectedOption) {
      const updatedValues = languages.reduce((acc, lang) => {
        acc[lang] = selectedOption.name?.[lang] || '';
        return acc;
      }, {});
  
      Object.entries(updatedValues).forEach(([lang, value]) => {
        dispatch(change('AddNewSeasonForm', `${sectionName}.${lang}`, value));
      });
    }
  };

  const filteredOptions = options.filter(option => option.name?.[language]);

  return (
    <Autocomplete
      {...rest}
      options={filteredOptions}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : getOptionLabel(option)
      }
      value={input.value || ''}
      onChange={(event, newValue) => {
        handleAutocompleteChange(dispatch, 'name', languages, newValue);
      }}
      onInputChange={(event, newValue) => {
        if (freeSolo) {
          input.onChange(newValue);
        }
      }}
      freeSolo={freeSolo}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={touched && error ? error : ''}
          variant="outlined"
        />
      )}
    />
  );
};

const AutocompleteField = ({
  name,
  options,
  getOptionLabel,
  freeSolo = false,
  ...rest
}) => (
  <Field
    name={name}
    component={RenderAutocomplete}
    options={options}
    getOptionLabel={getOptionLabel}
    freeSolo={freeSolo}
    {...rest}
  />
);

export default AutocompleteField;
