import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import CategoryForm from './CategoryForm';

import { CategoryFaqFormWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { getImageUrl } from '../../settingsPage/destination/utils';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';

import {
  getCategoryFaqListThunk,
  getSearchCategoryThunk,
  addCategoryFaqThunk,
  editCategoryFaqThunk,
  deleteCategoryFaqThunk,
  setCurrentCategory,
} from '../../../redux/faqReducers/categoryFaqReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';

import { CONTENT_LANGUAGES, FAQ_CATEGORY_STATUS } from '../../../consts/consts';
import { CATEGORY_FAQ_PAGE_ROUTE } from './constants';
import { reset } from 'redux-form';

const CategoryFaqAddFormContainer = (props) => {
  const {
    settingsState,
    appState,
    categoryState,
    predefinedState,
    getCategoryFaqListThunk,
    editCategoryFaqThunk,
    deleteCategoryFaqThunk,
    setCurrentCategory,
    selectedDestinationId
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);

  const onSubmitCategoryForm = async (values) => {
    const destinationArray = [];
    setShowSaveAnimation(true);

    settingsState.destinationData.map((item) => {
      Object.keys(values).map((value) => {
        if (value === item.name && values[value] === true) {
          destinationArray.push(item.id);
        }
      });
    });

    const iconUrl = await getImageUrl(values.icon, uploadImageFile, 'Icon');

    const updateData = {
      destinationId: selectedDestinationId,
      id: categoryState.currentCategory.id,
      status: values.status,
      order: values.order,
      name: values.name,
      icon: iconUrl,
      availableForDestination: destinationArray,
    };

    editCategoryFaqThunk(categoryState.currentCategory.id, updateData)
      .then(() => {
        setShowSaveAnimation(false);
        dispatch(reset('CategoryFaqFilterForm'));
        getCategoryFaqListThunk(selectedDestinationId);
        addError(`${t('faq_page.categories.error_edit_text')}`, 'Success');
        navigate(CATEGORY_FAQ_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('faq_page.categories.error_not_edit_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
  };

  const handleCloseSuccess = () => {
    setIsInEditMode(false);

    deleteCategoryFaqThunk(categoryState.currentCategory.id)
      .then(() => {
        setShowAlert(false);
        addError(`${t('faq_page.categories.error_deleted_text')}`, 'Success');
        dispatch(reset('CategoryFaqFilterForm'));
        getCategoryFaqListThunk(selectedDestinationId);
        navigate(CATEGORY_FAQ_PAGE_ROUTE);
      })
      .catch((error) =>
        addError(
          `${t('faq_page.categories.error_not_deleted_text')}`,
          'Error',
          error
        )
      );
  };

  useEffect(() => {
    if (id) {
      const currentCategory = categoryState.categoryData.find(
        (item) => item.id === id
      );

      setCurrentCategory(currentCategory);
    }
  }, [id, setCurrentCategory, categoryState.categoryData]);

  return (
    <CategoryFaqFormWrapper>
      {!!predefinedState.predefinedData.length &&
        categoryState.currentCategory.id && (
          <CategoryForm
            onSubmit={onSubmitCategoryForm}
            handleCloseSuccess={handleCloseSuccess}
            settingsState={settingsState}
            appState={appState}
            categoryState={categoryState}
            languages={appState.globalSettingsData.filter(
              (item) => item.name.en === CONTENT_LANGUAGES
            )}
            status={predefinedState.predefinedData.filter(
              (item) => item.name.en === FAQ_CATEGORY_STATUS
            )}
            isSave={showSaveAnimation}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            isEdit={isInEditMode}
            setIsEdit={setIsInEditMode}
            updateCategory={true}
            onCancel={handleFormCancel}
          />
        )}
    </CategoryFaqFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryState: state.categoryFaqReducer,
    settingsState: state.settingsReducer,
    appState: state.appReducer,
    predefinedState: state.predefinedReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default connect(mapStateToProps, {
  getCategoryFaqListThunk,
  getSearchCategoryThunk,
  addCategoryFaqThunk,
  editCategoryFaqThunk,
  deleteCategoryFaqThunk,
  getPredefinedSettingsThunk,
  setCurrentCategory,
})(CategoryFaqAddFormContainer);
