import { connect } from 'react-redux';
import { compose } from 'redux';

import Categories from './Categories';

import {
  getCategoryThunk,
  addCategoryThunk,
  deleteCategoryThunk,
  editCategoryThunk,
  getSearchCategoryThunk,
  setCurrentCategory,
} from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    categoryState: state.categoryReducer,
    stateDestinations: state.settingsReducer.destinationData,
    predefinedState: state.predefinedReducer.predefinedData,
    globalSettingsState: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

const CategoryContainer = compose(
  connect(mapStateToProps, {
    getCategoryThunk,
    addCategoryThunk,
    deleteCategoryThunk,
    editCategoryThunk,
    getSearchCategoryThunk,
    getPredefinedSettingsThunk,
    setCurrentCategory,
  })
)(Categories);

export default CategoryContainer;
