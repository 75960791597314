import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useSelector } from 'react-redux';

const NewYearGreeting = () => {
  const user = useSelector(state => state.authReducer);
  const [showGreeting, setShowGreeting] = useState(false);

  const handleCloseGreeting = () => {
    setShowGreeting(false);
  };

  useEffect(() => {
    const newYearCount = localStorage.getItem('newYearCount');
    const currentDate = new Date(); 
    const startDate = new Date('2024-01-01');
    const endDate = new Date('2025-01-05');

    if (
      (newYearCount < 1 || newYearCount === null) && 
      user.name === 'slavek@bookletia.net' &&
      currentDate >= startDate && 
      currentDate <= endDate
    ) {
      setShowGreeting(true);
      localStorage.setItem('newYearCount', 1);
    }
  }, [user])

  if(showGreeting)
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000000,
      }}
    >
      <Confetti width='2000px' height='1000px' />
      <div style={{ textAlign: 'center' }}>
        <h1>🎉 Šťastný Nový rok! 🎉</h1>
        <p>Přejeme vám štěstí, zdraví a úspěch v novém roce!</p>
        <button
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            background: 'linear-gradient(45deg, #ff0000, #ff7300, #fff200, #00ff00, #00d5ff, #9900ff)',
            backgroundSize: '300% 300%',
            color: '#fff',
            border: 'none',
            borderRadius: '10px',
            fontWeight: '700',
            cursor: 'pointer',
            textShadow: '0px 0px 5px #fff, 0px 0px 10px #ff0000',
            animation: 'glow 3s infinite linear, gradientShift 5s infinite linear',
            boxShadow: '0px 4px 15px rgba(255, 255, 255, 0.75)',
            transition: 'transform 0.2s',
          }}
          onClick={handleCloseGreeting}
          onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
          onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
        >
          🎄 Uzavřít! 🎄
        </button>

        <style>
          {`
            @keyframes glow {
              0% {
                text-shadow: 0px 0px 5px #fff, 0px 0px 10px #ff0000;
              }
              50% {
                text-shadow: 0px 0px 10px #fff, 0px 0px 20px #ff7300;
              }
              100% {
                text-shadow: 0px 0px 5px #fff, 0px 0px 10px #ff0000;
              }
            }
            @keyframes gradientShift {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          `}
        </style>

      </div>
    </div>
  );
};


export default NewYearGreeting