import { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import DidYouKnowTab from './DidYouKnowTab';

import { CONTENT_LANGUAGES } from '../../../consts/consts';
import { sortLanguageListInDestinationSettingsOrder } from '../../../helpers/sortLanguageListInDestinationSettingsOrder';
import { getFactsListThunk } from '../../../redux/publishingReducers/factsReducer/factsActions';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const DidYouKnowTabContainer = ({
  languageList,
  factsList,
  getFactsListThunk,
  isLoading,
  currentDestinationId,
}) => {
  const [activeRowId, setActiveRowId] = useState(-1);
  const dispatch = useDispatch();
  const handleRowExpand = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const { isBaseTasksLoaded, needToRefresh } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    dispatch(setTasks(4));
    return () => {
      dispatch(setTasks(3));
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh && currentDestinationId) {
        await getFactsListThunk(currentDestinationId, false); 
        dispatch(incrementCompletedTasks());
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh]);
  

  useEffect(() => {
    currentDestinationId && getFactsListThunk(currentDestinationId);
  }, [getFactsListThunk, currentDestinationId]);

  return (
    <DidYouKnowTab
      onRowExpand={handleRowExpand}
      activeRowId={activeRowId}
      setActiveRowId={setActiveRowId}
      languageList={languageList}
      factsList={factsList}
      isLoading={isLoading}
      resetActiveRow={resetActiveRow}
    />
  );
};

const mapStateToProps = (state) => {
  const languageList = state.appReducer.globalSettingsData.find(
    (item) => item.name.en === CONTENT_LANGUAGES
  );

  const selectedDestinationLanguages =
    state.appReducer.selectedDestination.languages;

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };

  const mappedDestinationgLanguages = destinationLanguageList.parameters.map(
    (lang) => lang.value
  );

  return {
    languageList: mappedDestinationgLanguages,
    factsList: state.factsReducer.factsList,
    isLoading: state.factsReducer.isLoading,
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  getFactsListThunk,
})(DidYouKnowTabContainer);
