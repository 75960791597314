import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HomePageTab from './HomePageTab';

import { getImageRecordListThunk } from '../../../redux/publishingReducers/mainImageReducer/mainImageActions';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const HomePageTabContainer = (props) => {
  const { imageRecordList, getImageRecordListThunk, currentDestinationId } =
    props;

  const dispatch = useDispatch();
  const [activeRowId, setActiveRowId] = useState(-1);
  const navigate = useNavigate();

  const handleExpandRow = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const handleAddButtonClick = () => {
    navigate('new-image');
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };
  const { isBaseTasksLoaded, needToRefresh } = useSelector(
    (state) => state.refreshDataState
  );


  useEffect(() => {
    dispatch(setTasks(4));
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh && currentDestinationId) {
        await getImageRecordListThunk(currentDestinationId, false);
        dispatch(incrementCompletedTasks());
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh]);
  

  useEffect(() => {
    if (currentDestinationId) getImageRecordListThunk(currentDestinationId);
  }, [getImageRecordListThunk, currentDestinationId]);

  return (
    <HomePageTab
      imageRecordList={imageRecordList}
      activeRowId={activeRowId}
      setActiveRowId={setActiveRowId}
      onExpandRow={handleExpandRow}
      onAddButtonClick={handleAddButtonClick}
      resetActiveRow={resetActiveRow}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    imageRecordList: state.mainImageReducer.mainImageRecordList,
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, { getImageRecordListThunk })(
  HomePageTabContainer
);
