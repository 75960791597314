import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';

import { Button, CircularProgress, Stack } from '@mui/material';

import NotificationsTable from './components/NotificationsTable';
import NotificationSearchForm from './forms/NotificationSearchForm';

import { setTitle } from '../../redux/appReducer';

import { NOTIFICATION_STATUS } from '../../consts/consts';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../redux/refreshDataStateReducer/refreshDataStateReducer';

function NotificationPage(props) {
  const {
    appReducer,
    notificationReducer,
    attractionsReducer,
    getFilteredDestinationNotificationsThunk,
    getDestinationNotificationsListThunk,
    getSmallAttractionsDataThunk,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShowNotificationAddForm] = useState(false);
  const [status, setStatus] = useState('');
  const [attraction, setAttraction] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isGeneralNotifications, setIsGeneralNotifications] = useState(false);
  const [filteredNotificationData, setFilteredNotificationData] = useState([]);

  const { isLoaded, notificationData } = notificationReducer;
  const { selectedLanguage } = appReducer;

  useEffect(() => {
    setFilteredNotificationData(notificationData);
  }, [notificationData])

  const selectedDestinationId = appReducer.selectedDestination.id;
  const destinationLanguageList = appReducer.selectedDestination.languages;

  const statusOptions = appReducer.globalSettingsData.filter(
    (item) => item.name.en === NOTIFICATION_STATUS
  );

  const availableAttractions = attractionsReducer.attractionsSmallData.sort(
    function (a, b) {
      var textA = a.name.toUpperCase().trim();
      var textB = b.name.toUpperCase().trim();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
  );

  useEffect(() => {
    if(selectedDestinationId){
      dispatch(setTasks(5))
    }
    else {
      dispatch(setTasks(4))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationId]);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if(selectedDestinationId)
        {
          await getSmallAttractionsDataThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());
        }
  
        const filter = {
          destinationId: selectedDestinationId,
          attractionId: attraction ? attraction.id : null,
          status: status ? status : null,
          startDate: startDate ? new Date(startDate).toISOString() : null,
          endDate: endDate ? new Date(endDate).toISOString() : null,
          isGeneralNotification: isGeneralNotifications ? isGeneralNotifications : null,
        };
  
        await getFilteredDestinationNotificationsThunk(filter, false);
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  const onAddNotification = () => {
    navigate('new-notification');
  };

  const onSubmitNotificationSearchForm = (formData) => {
    const filter = {
      destinationId: selectedDestinationId,
      attractionId: formData.attraction ? formData.attraction.id : null,
      status: formData.status ? formData.status : null,
      startDate: formData.startDate ? new Date(formData.startDate).toISOString() : null,
      endDate: formData.endDate ? new Date(formData.endDate).toISOString() : null,
      isGeneralNotification: formData.isGeneralNotifications ? formData.isGeneralNotifications : null,
    };
  
    if (formData?.attraction?.id === '0') { // Especially for *** COOLPASS *** because it is not stored in the database
      setFilteredNotificationData(notificationData.filter(notification => {
        const matchesStatus = filter.status ? notification.status === filter.status : true;
        const matchesStartDate = filter.startDate ? new Date(notification.startDate) >= new Date(filter.startDate) : true;
        const matchesEndDate = filter.endDate ? new Date(notification.endDate) <= new Date(filter.endDate) : true;
  
        return notification.attractions.length === 0 && matchesStatus && matchesStartDate && matchesEndDate;
      }));
    } else {
      getFilteredDestinationNotificationsThunk(filter);
    }
  };

  const handleResetFilters = () => {
    setStatus('');
    setStartDate(null);
    setEndDate(null);
    setAttraction('');

    const filter = {
      status: '',
      startDate: null,
      endDate: null,
      attraction: '',
      isVisibleGeneral: false,
    };

    dispatch(reset('NotificationSearchForm'));
    onSubmitNotificationSearchForm(filter);
  };

  useEffect(() => {
    getDestinationNotificationsListThunk(selectedDestinationId);
    getSmallAttractionsDataThunk(selectedDestinationId);
    dispatch((setTitle('top_bar.main_title_notifications')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationId]);

  return (
    <div style={{ marginTop: '100px' }}>
      {!isShowNotificationAddForm && (
        <Stack spacing={2}>
          <div
            style={{
              fontSize: '20px',
              fontWeight: '500',
              textTransform: 'uppercase',
            }}
          >
            {t('notifications.list_of_notifications')}
          </div>
          <div style={{ width: '20%' }}>
            <Button
              variant="contained"
              onClick={onAddNotification}
              sx={{ color: '#fff', width: '100%' }}
            >
              {t('notifications.add_new_notification')}
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NotificationSearchForm
              availableStatuses={statusOptions}
              availableAttractions={availableAttractions}
              onSubmit={onSubmitNotificationSearchForm}
              selectedLanguage={selectedLanguage}
              status={status}
              setStatus={setStatus}
              isGeneralNotifications = {isGeneralNotifications}
              setIsGeneralNotifications = {setIsGeneralNotifications}
              attraction={attraction}
              setAttraction={setAttraction}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              handleResetFilters={handleResetFilters}
            />
          </div>
          {isLoaded && selectedDestinationId ? (
            <NotificationsTable
              isAddingItem={isShowNotificationAddForm}
              notificationsData={filteredNotificationData}
              isItemsLoaded={isLoaded}
              languages={destinationLanguageList}
              statuses={statusOptions}
              availableAttractions={availableAttractions}
              selectedLanguage={selectedLanguage}
              selectedDestinationId={selectedDestinationId}
              handleResetFilters={handleResetFilters}
              status = {status}
              startDate = {startDate}
              endDate = {endDate}
              attraction = {attraction}
              isGeneralNotifications = {isGeneralNotifications}
            />
          ) : (
            <div
              style={{
                marginTop: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Stack>
      )}
    </div>
  );
}

export default NotificationPage;
