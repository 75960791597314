/* eslint-disable array-callback-return */
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Box, CircularProgress } from '@mui/material';

import cl from './Categories.module.css';

import CategoryList from './CategoryList';

import { FAQ_CATEGORY_STATUS } from '../../../consts/consts';
import CategoriesFilterForm from './filterForm/CategoriesFilterForm';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { useDispatch, useSelector } from 'react-redux';

const CategoriesFaq = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryState = props.categoryFaqReducer;
  const selectedDestinationId = props.selectedDestinationId;
  const settingsState = props.settingsReducer;
  const appState = props.appReducer;
  const predefinedState = props.predefinedReducer;

  const statusValue = useSelector(
    (state) => state.form.CategoryFaqFilterForm?.values?.status
  );

  const destinationValue = useSelector(
    (state) => state.form.CategoryFaqFilterForm?.values?.destination
  );

  useEffect(() => {
    if(selectedDestinationId) {
      props.getCategoryFaqListThunk(selectedDestinationId);
      props.getPredefinedSettingsThunk();

      dispatch(setTasks(5))
    }
    return () => props.getSearchCategoryThunk(statusValue, selectedDestinationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await props.getPredefinedSettingsThunk();
        dispatch(incrementCompletedTasks());

        await props.getSearchCategoryThunk(statusValue, selectedDestinationId);
        dispatch(incrementCompletedTasks());
      }
    }

    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  const onAddCategory = () => {
    navigate('new-category');
  };

  const handleListItemClick = (index, item) => {
    navigate(`view/${item.id}`);
  };

  useEffect(() => {
    selectedDestinationId && props.getSearchCategoryThunk(statusValue, selectedDestinationId);
  }, [destinationValue, statusValue, selectedDestinationId]);

  if (!categoryState.isLoadingData) {
    return (
      <Box sx={{ position: 'absolute', top: '50%', right: '50%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.leftBox}>
        <div className={cl.addButton}>
          <Button
            sx={{ color: '#fff', width: '100%', height: '100%' }}
            onClick={onAddCategory}
            variant="contained"
          >
            {t('faq_page.categories.add_button')}
          </Button>
        </div>
        <div className={cl.searchBox}>
          <CategoriesFilterForm
            selectedLanguage={appState.selectedLanguage}
            statusOptionsData={predefinedState.predefinedData.filter(
              (item) => item.name.en === FAQ_CATEGORY_STATUS
            )}
          />
        </div>
        <CategoryList
          categoryState={categoryState.categoryData}
          handleListItemClick={handleListItemClick}
          selectedLanguage={appState.selectedLanguage}
        />
      </div>
      <div className={cl.rightBox}>
        <Outlet />
      </div>
    </div>
  );
};

export default CategoriesFaq;
