import * as axios from 'axios';
import authService from "../auth/AuthorizeService";
import { BASE_URL } from '../config.js'

export const getAppVersionApi = async (currentDesintationId) => {
    const token = await authService.getAccessToken();
    return await axios.get(`${BASE_URL}/api/ApplicationInformation/destinations/${currentDesintationId}`, {
        headers: { Authorization: `Bearer ${token}` }
    }).catch(error => {
        return error.response
    })
}

export const addAppVersionApi = async (data) => {
    const token = await authService.getAccessToken();
    return await axios.post(`${BASE_URL}/api/ApplicationInformation`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}

export const editAppVersionApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return await axios.put(`${BASE_URL}/api/ApplicationInformation/${id}`, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error => {
        return error.response
    })
}