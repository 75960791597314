import React, {useState, useEffect} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import { setFormData } from '../../redux/attractionsReducers/attractions/attractionsActions';
 
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {DEFAULT_AUTOCOMPLETE_VALUE, REGIONAL_MANAGER} from "../../pages/attractions/constants"

import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import { CustomSelect } from './styled';

import {
  StyledFilterOption,
  StyledVisitsFilterAttractionsField,
} from './styled';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { TYPE_AREA, TYPE_REGION } from './constants';

export const SelectStatus = ({ data, ...props }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const handleChange = (selectedValue) => {
    dispatch(setFormData({ ...formData, statusValue: selectedValue }));
  };  

  return (
    <Controller
      control={control}
      name="statusValue"
      render={({ field: { onChange, value } }) => (
        <CustomSelect
          value={value}
          native
          size="small"
           onChange={(e) => {
            const selectedValue = e.target.value;
            onChange(selectedValue);
            handleChange(selectedValue);
          }}
        >
          <option value={''}>{t('faq_page.questions.all_status')}</option>
          {data[0]?.parameters.map((item, i) => (
            <option value={item.id} key={i}>
              {item.value[props.selectedLanguage] || item.value.en}
            </option>
          ))}
        </CustomSelect>
      )}
    />
  );
};

export const SearchField = () => {
  const { t } = useTranslation();
  const { control, resetField, setValue} = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const handleChange = (searchValue) => {
    dispatch(setFormData({ ...formData, searchValue }));
  };

  const handleClear = () => {
    resetField('searchValue');
    dispatch(setFormData({ ...formData, searchValue: '' }));
  };

  useEffect(() => {
    setValue('searchValue', formData.searchValue);
  }, [formData.searchValue, setValue]);

  return (
    <Controller
      control={control}
      name="searchValue"
      render={({ field: { onChange, value } }) => (
        <TextField
          variant="outlined"
          size="small"
          placeholder={t('attractions.attractions_search.attractions')}
          value={value}
          onChange={(e) => {
            const searchValue = validateTextFieldValue(e.target.value);
            onChange(searchValue);
            handleChange(searchValue);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value ? (
                  <IconButton
                    onClick={handleClear}
                    sx={{ padding: 0 }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <SearchIcon sx={{ color: '#cfcaca' }} />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const SelectCategory = ({
  data,
  selectedLanguage,
  categoryInputValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const [localCategoryValue, setLocalCategoryValue] = useState(categoryInputValue);

  const mappedCategories = data?.map((category) => {
    return {
      id: category.id,
      name: category.name[selectedLanguage] || category.name.en,
    };
  });

  const handleChangeField = (fieldName, value, name) => {
    const newCategoryValue = value ? { id: value, name } : { id: '', name: '' };
    setValue(fieldName, value);
    dispatch(setFormData({ ...formData, categoryValue: newCategoryValue}));
  };

  const handleOnBlur = () => {
    const findMatch = mappedCategories.find(
      (category) => category.name === localCategoryValue.name
    );

    if (!findMatch) {
      setLocalCategoryValue({ id: '', name: '' });
      dispatch(setFormData({ ...formData, categoryValue: { id: '', name: '' } }));
    }
  };

  useEffect(() => {
    setValue('categoryValue', categoryInputValue);
    setLocalCategoryValue(categoryInputValue)
  }, [formData.categoryValue, setValue]);

  return (
    <Controller
      control={control}
      name="categoryValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!localCategoryValue.name.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 200 }}
          options={mappedCategories}
          inputValue={localCategoryValue.name}
          value={localCategoryValue}
          getOptionLabel={(option) => option.name}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '', newValue?.name);
            setLocalCategoryValue(newValue || { id: '', name: '' });
          }}
          renderOption={(props, option) => (
            <StyledFilterOption
              component="li"
              {...props}
              value={option.id}
              label={option.name}
            >
              {option.name}
            </StyledFilterOption>
          )}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_category')}
              onChange={(e) => {
                const newValue = validateTextFieldValue(e.target.value);
                setLocalCategoryValue((prevState) => ({
                  ...prevState,
                  name: newValue,
                }));
              }}
            />
          )}
        />
      )}
    />
  );
};

export const SelectRegion = ({
  data,
  selectedLanguage,
  regionInputValue,
  resetIncludedLocationsValue,
  user,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const [localRegionValue, setLocalRegionValue] = useState(regionInputValue);

  const mappedRegions = data?.filter((region) => {
    if (user && user.role === REGIONAL_MANAGER && user.regions.length) {
      return user.regions.some((userRegion) => userRegion.idRegion === region.id);
    }
    return true;
  }).filter((region) => {
    return region.title[selectedLanguage] || region.title.en;
  }).map((region) => ({
    id: region.id,
    title: region.title[selectedLanguage] || region.title.en,
  }));

  const handleChangeField = (fieldName, value, title) => {
    const newRegionValue = value ? { id: value, title } : { id: '', title: '' };
    setValue(fieldName, newRegionValue);
    
    if(localRegionValue !== DEFAULT_AUTOCOMPLETE_VALUE || localRegionValue !== newRegionValue) {
      dispatch(setFormData({ ...formData, regionValue: newRegionValue, areaValue: DEFAULT_AUTOCOMPLETE_VALUE, locationValue: DEFAULT_AUTOCOMPLETE_VALUE }));
    } else if (!value) {
      dispatch(setFormData({ ...formData, regionValue: newRegionValue, areaValue: DEFAULT_AUTOCOMPLETE_VALUE, locationValue: DEFAULT_AUTOCOMPLETE_VALUE }));
      resetIncludedLocationsValue(TYPE_REGION);
    } else {
      dispatch(setFormData({ ...formData, regionValue: newRegionValue }));
    }
  };

  const handleOnBlur = () => {
    const findMatch = mappedRegions.find(
      (region) => region.title === localRegionValue.title
    );

    if (!findMatch) {
      setLocalRegionValue({ id: '', title: '' });
      dispatch(setFormData({ ...formData, regionValue: DEFAULT_AUTOCOMPLETE_VALUE }));
    }
  };

  useEffect(() => {
    setValue('regionValue', regionInputValue);
    setLocalRegionValue(regionInputValue);
  }, [formData.regionValue, setValue]);

  return (
    <Controller
      control={control}
      name="regionValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!localRegionValue.title.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 200 }}
          options={mappedRegions}
          inputValue={localRegionValue.title}
          value={localRegionValue}
          getOptionLabel={(option) => option.title}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '', newValue?.title || '');
            setLocalRegionValue(newValue || { id: '', title: '' });
          }}
          renderOption={(props, option) => (
            <StyledFilterOption
              component="li"
              {...props}
              value={option.id}
              label={option.title}
            >
              {option.title}
            </StyledFilterOption>
          )}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_regions')}
              onChange={(e) => {
                const newValue = validateTextFieldValue(e.target.value);
                setLocalRegionValue((prevState) => ({
                  ...prevState,
                  title: newValue,
                }));
              }}
            />
          )}
        />
      )}
    />
  );
};


export const SelectArea = ({
  data,
  selectedLanguage,
  areaInputValue,
  resetIncludedLocationsValue,
  user,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const [localAreaValue, setLocalAreaValue] = useState(areaInputValue);
  
  const mappedAreas = data?.filter((area) => {
    if (user && user.role === REGIONAL_MANAGER && user.areas.length) {
      return user.areas.some((userArea) => userArea.idArea === area.id);
    }
    return true;
  }).filter((area) => {
    return area.title[selectedLanguage] || area.title.en;
  }).map((area) => ({
    id: area.id,
    title: area.title[selectedLanguage] || area.title.en,
  }));

  const handleChangeField = (fieldName, value, title) => {
    const newAreaValue = value ? { id: value, title } : { id: '', title: '' };
    setValue(fieldName, newAreaValue);

    if(localAreaValue !== DEFAULT_AUTOCOMPLETE_VALUE || localAreaValue !== newAreaValue) {
      dispatch(setFormData({ ...formData, areaValue: newAreaValue, locationValue: DEFAULT_AUTOCOMPLETE_VALUE }));
    } else if (!value) {
      dispatch(setFormData({ ...formData, areaValue: newAreaValue, locationValue: DEFAULT_AUTOCOMPLETE_VALUE }));
      resetIncludedLocationsValue(TYPE_AREA);
    } else {
      dispatch(setFormData({ ...formData, areaValue: newAreaValue }));
    }
  };

  const handleOnBlur = () => {
    const findMatch = mappedAreas.find(
      (region) => region.title === localAreaValue.title
    );

    if (!findMatch) {
      setLocalAreaValue({ id: '', title: '' });
      dispatch(setFormData({ ...formData, areaValue: { id: '', title: '' } }));
    }
  };

  useEffect(() => {
    setValue('areaValue', areaInputValue);
    setLocalAreaValue(areaInputValue);
  }, [formData.areaValue, setValue]);


  return (
    <Controller
      control={control}
      name="areaValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!localAreaValue.title.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 180 }}
          options={mappedAreas}
          inputValue={localAreaValue.title}
          value={localAreaValue}
          getOptionLabel={(option) => option.title}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '', newValue?.title || '');
            setLocalAreaValue(newValue || { id: '', title: '' });
          }}
          renderOption={(props, option) => {
            return (
              <StyledFilterOption
                component="li"
                {...props}
                value={option.id}
                label={option.title}
              >
                {option.title}
              </StyledFilterOption>
            );
          }}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_areas')}
              onChange={(e) => {
                const newValue = validateTextFieldValue(e.target.value);
                setLocalAreaValue((prevState) => ({
                  ...prevState,
                  title: newValue,
                }));
              }}
            />
          )}
        />
      )}
    />
  );
};

export const SelectLocation = ({
  data,
  selectedLanguage,
  locationInputValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const [localLocationValue, setLocalLocationValue] = useState(locationInputValue);

  const mappedLocations = data?.filter((location) => {
    return location.title[selectedLanguage] || location.title.en;
  }).map((location) => {
    return {
      id: location.id,
      title: location.title[selectedLanguage] || location.title.en,
    };
  });

   const handleChangeField = (fieldName, value, title) => {
    const newLocationValue = value ? { id: value, title } : { id: '', title: '' };
    setValue(fieldName, newLocationValue);
    dispatch(setFormData({ ...formData, locationValue: newLocationValue }));
  };

  const handleOnBlur = () => {
    const findMatch = mappedLocations.find(
      (region) => region.title === localLocationValue.title
    );

    if (!findMatch) {
      setLocalLocationValue({ id: '', title: '' });
      dispatch(setFormData({ ...formData, locationValue: { id: '', title: '' } }));
    }
  };

  useEffect(() => {
    setValue('locationValue', locationInputValue);
    setLocalLocationValue(locationInputValue);
  }, [formData.locationValue, setValue]);


  return (
    <Controller
      control={control}
      name="locationValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!localLocationValue.title.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 200 }}
          options={mappedLocations}
          inputValue={localLocationValue.title}
          value={localLocationValue}
          getOptionLabel={(option) => option.title}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '', newValue?.title || '');
            setLocalLocationValue(newValue || { id: '', title: '' });
          }}
          renderOption={(props, option) => {
            return (
              <StyledFilterOption
                component="li"
                {...props}
                value={option.id}
                label={option.title}
              >
                {option.title}
              </StyledFilterOption>
            );
          }}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_locations')}
              onChange={(e) => {
                const newValue = validateTextFieldValue(e.target.value);
                setLocalLocationValue((prevState) => ({
                  ...prevState,
                  title: newValue,
                }));
              }}
            />
          )}
        />
      )}
    />
  );
};


export const SelectCompany = ({
  data,
  companyInputValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.attractionsReducer.formData);

  const [localCompanyValue, setLocalCompanyValue] = useState(companyInputValue);

  const mappedCompanies = data?.map((company) => {
    return {
      id: company.id,
      name: company.name,
    };
  });

  const handleChangeField = (fieldName, value, name) => {
    const newCompanyValue = value ? { id: value, name } : { id: '', name: '' };
    setValue(fieldName, value);
    dispatch(setFormData({ ...formData, companyValue: newCompanyValue}));
  };

  const handleOnBlur = () => {
    const findMatch = mappedCompanies.find(
      (company) => company.name === localCompanyValue.name
    );

    if (!findMatch) {
      setLocalCompanyValue({ id: '', name: '' });
      dispatch(setFormData({ ...formData, companyValue: { id: '', name: '' } }));
    }
  };

  useEffect(() => {
    setValue('companyValue', companyInputValue);
    setLocalCompanyValue(companyInputValue)
  }, [formData.companyValue, setValue]);

  return (
    <Controller
      control={control}
      name="companyValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!localCompanyValue.name.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 250 }}
          options={mappedCompanies}
          inputValue={localCompanyValue.name}
          value={localCompanyValue}
          getOptionLabel={(option) => option.name}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '', newValue?.name);
            setLocalCompanyValue(newValue || { id: '', name: '' });
          }}
          renderOption={(props, option) => (
            <StyledFilterOption
              component="li"
              {...props}
              value={option.id}
              label={option.name}
            >
              {option.name}
            </StyledFilterOption>
          )}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_companies')}
              onChange={(e) => {
                const newValue = validateTextFieldValue(e.target.value);
                setLocalCompanyValue((prevState) => ({
                  ...prevState,
                  name: newValue,
                }));
              }}
            />
          )}
        />
      )}
    />
  );
};
