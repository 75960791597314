export const settingsTabs = [
  {
    name: 'settings_page.destination_settings_tab',
    path: 'destination',
    matchUrls: ['destination'],
  },
  {
    name: 'settings_page.categories_settings_tab',
    path: 'category',
    matchUrls: ['category'],
  },
  {
    name: 'settings_page.facilities_settings_tab',
    path: 'facility',
    matchUrls: ['facility'],
  },
  {
    name: 'settings_page.seasons_settings_tab',
    path: 'seasons',
    matchUrls: ['seasons'],
  },
  {
    name: 'settings_page.tags_settings_tab',
    path: 'tags',
    matchUrls: ['tags'],
  },
  {
    name: 'settings_page.predefined_settings_tab',
    path: 'predefined',
    matchUrls: ['predefined'],
  },
];

const firstTabIndex = 0;

export const getOpenedTabIdByUrlParameter = (tabs, location) => {
  if (!location) return firstTabIndex;

  const splitedLocation = location.split('/');
  const tabName = splitedLocation[2];

  if (!tabName) return firstTabIndex;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return firstTabIndex;

  return tabId;
};
