import { isImage, isImageUrl } from '../../../publishing/HomePageTab/imageDrop/utils';
import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import { Fab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageViewListDragAndDrop = ({ disabled, imageList, onFileListChange, isCanceled, imagesWithId, setImagesWithId }) => {

  useEffect(() => {
    if(isCanceled) {
      const imagesWithIds = imageList.map((item, index) => {
        const url = isImageUrl(item) ? item : isImage(item.file) ? item.data : URL.createObjectURL(item.file);
        return { id: `${url}-${index}`, file: item.file, url };
      });
      setImagesWithId(imagesWithIds);
    }
  }, [isCanceled]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id || !active.data.current.sortable) return;

    const oldIndex = imagesWithId.findIndex((item) => item.id === active.id);
    const newIndex = imagesWithId.findIndex((item) => item.id === over.id);

    const newImages = arrayMove(imagesWithId, oldIndex, newIndex);
    setImagesWithId(newImages);
  };

  const handleFileDelete = (removedFileObjIdx) => {
    const remainingFileObjs = imagesWithId.filter((item) => item.id !== removedFileObjIdx)
    
    setImagesWithId(remainingFileObjs);
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '10px',
    padding: '10px',
  };

  const imageItemStyle = {
    position: 'relative',
    height: '150px',
    overflow: 'hidden',
    border: '1px solid #ddd',
    borderRadius: '8px',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const fabStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    pointerEvents: 'auto',
    width: 35,
    height: 30,
  };

  const SortableImage = ({disabled, id, url, index, onDelete }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 1 : 'auto', 
    };

    const handleDeleteClick = (e) => {
      e.preventDefault();
      e.stopPropagation(); 
      onDelete(index);  
    };

    return (
      <div ref={setNodeRef} style={{ ...imageItemStyle, ...style }} {...attributes} {...listeners}>
        <img src={url} alt="Image" style={imageStyle} loading="lazy" />
        <Fab 
          disabled = {disabled} 
          style={fabStyle}
          onMouseDown={handleDeleteClick}
        >  
          <CloseIcon fontSize="small" />
        </Fab>
      </div>
    );
  };

  return disabled ? (
    <div style={gridContainerStyle}>
      {imagesWithId.map((image, index) => (
        <SortableImage
          key={image.id}
          id={image.id}
          url={image.url}
          index={image.id}
          disabled={disabled}
          onDelete={handleFileDelete}
        />
      ))}
    </div>
  ) : (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={imagesWithId.map((item) => item.id)} strategy={rectSortingStrategy}>
        <div style={gridContainerStyle}>
          {imagesWithId.map((image, index) => (
            <SortableImage
              key={image.id}
              id={image.id}
              url={image.url}
              index={image.id}
              disabled={disabled}
              onDelete={handleFileDelete}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

ImageViewListDragAndDrop.propTypes = {
  imageList: PropTypes.array.isRequired,
  onFileListChange: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

export default ImageViewListDragAndDrop;
