import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { dateIsValid } from './validation';

import { DatePicker } from '../../../components/notificationComponents/DatePicker';
import AutocompleteInput from '../../../components/notificationComponents/AutocompleteInput';
import { SelectInput } from '../../../components/reduxFormComponents/reduxFormComponents';
import { UserInfoFormCheckbox } from '../../my-profile/styled';

const useStyles = makeStyles({
  form: {
    '& .MuiButton-root': {
      marginRight: '10px',
      color: '#fff',
      height: '35px',
    },
  },
  order: {
    width: '185px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
  status: {
    width: '185px',
    marginBottom: '30px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
});

let NotificationSearchForm = ({
  availableStatuses,
  availableAttractions,
  onSubmit,
  selectedLanguage,
  status,
  setStatus,
  isGeneralNotifications,
  setIsGeneralNotifications,
  attraction,
  setAttraction,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleResetFilters,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getFilteredNotifications = (
    newAttraction,
    newStatus,
    newStartDate,
    newEndDate,
    newIsVisibleGeneral,
  ) => {
    const filter = {
      status: newStatus,
      attraction: newAttraction,
      startDate: dateIsValid(newStartDate) ? newStartDate : null,
      endDate: dateIsValid(newEndDate) ? newEndDate : null,
      isGeneralNotifications: !!newIsVisibleGeneral
    };
    onSubmit(filter);
  };

  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <Stack direction="row" spacing={3} alignItems="center">
        <Box sx={{ minWidth: 120 }}>
          <Field
            name="status"
            component={SelectInput}
            onChange={(event, newStatus, prevValue, name) => {
              setStatus(newStatus);
              getFilteredNotifications(
                attraction,
                newStatus,
                startDate,
                endDate,
                isGeneralNotifications
              );
            }}
          >
            <option value={''}>{t('notifications.status')}</option>
            {[...availableStatuses][0].parameters.map((status) => (
              <option value={status.id}>
                {status.value[selectedLanguage] || status.value.en}
              </option>
            ))}
          </Field>
        </Box>
        <Box sx={{ minWidth: 300 }}>
          <Field
            component={AutocompleteInput}
            options={[{ id: '0', name: '*** COOLPASS ***' }, ...availableAttractions]}
            getOptionLabel={(option) => option.name}
            name="attraction"
            label={t('notifications.attraction')}
            onChange={(event, newAttraction, prevValue, name) => {
              setAttraction(newAttraction);
              getFilteredNotifications(
                newAttraction,
                status,
                startDate,
                endDate,
                isGeneralNotifications
              );
            }}
          ></Field>
        </Box>
        <Field
          component={DatePicker}
          name="startDate"
          onChange={(event, newStartDate, prevValue, name) => {
            setStartDate(newStartDate);
            getFilteredNotifications(attraction, status, newStartDate, endDate, isGeneralNotifications);
          }}
          maxDate={endDate}
          label={t('notifications.start_date')}
          clearable
          clearText={t('clear')}
        />
        <Field
          component={DatePicker}
          name="endDate"
          onChange={(event, newEndDate, prevValue, name) => {
            setEndDate(newEndDate);
            getFilteredNotifications(attraction, status, startDate, newEndDate, isGeneralNotifications);
          }}
          minDate={startDate}
          label={t('notifications.end_date')}
          clearable
          clearText={t('clear')}
        />
         <Field
          component={UserInfoFormCheckbox}
          name="isGeneralNotifications"
          label={t('notifications.visible_for_general_section')}
          onChange={(_, newGeneral) => {
            setIsGeneralNotifications(newGeneral);
              getFilteredNotifications(
                attraction,
                status,
                startDate,
                endDate,
                newGeneral
              );
          }}
        />
        <IconButton onClick={handleResetFilters}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  if (values.startDate && values.endDate) {
    if (values.startDate > values.endDate)
      errors.endDate = 'End Date must be greater than Start Date';
  }
  return errors;
};

export default NotificationSearchForm = reduxForm({
  form: 'NotificationSearchForm',
  validate,
})(NotificationSearchForm);
