import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import ContentForm from './ContentForm';
import useAPIError from '../../../apIErrorProvider/useAPIError';

import { LoaderWrapper } from './styled';

import {
  getUniqueImages,
  uploadImageFiles,
  getExistingImages,
} from './reduxImageDrop/utils';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';
import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../helpers/translationObjectUtils';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';

const Content = (props) => {
  const {
    appState,
    attractionsState,
    settingsState,
    editAttractionContentThunk,
    getAttractionsListThunk,
    getAttractionThunk,
    selectedLanguage,
  } = props;

  const attractionData = attractionsState.currentAttraction;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [imagesAppWithId, setImagesAppWithId] = useState([]);
  const [imagesWebWithId, setImagesWebWithId] = useState([]);

  const destination = settingsState.destinationData.filter(
    (item) => item.id === appState.selectedDestination.id
  );

  const resetFormValues = () => {
    dispatch(reset('Content'));
  };

  const onSubmitContentForm = async (formValues) => {
    setIsSave(true);
    setIsInEditMode(false);
  
    const newAppImages = imagesAppWithId.map((item) => ({
      file: item.file || null,
      data: item.url,
    }));
    const newWebImages = imagesWebWithId.map((item) => ({
      file: item.file || null,
      data: item.url,
    }));
  
    const uniqueAppImageFiles = getUniqueImages(newAppImages);
    const uniqueWebImageFiles = getUniqueImages(newWebImages);
  
    const existingAppImageFiles = getExistingImages(newAppImages);
    const existingWebImageFiles = getExistingImages(newWebImages);
  
    try {
      const [appImageResponse, webImageResponse] = await Promise.all([
        uploadImageFiles(uniqueAppImageFiles, uploadImageFile, 'App'),
        uploadImageFiles(uniqueWebImageFiles, uploadImageFile, 'Web'),
      ]);
      
      let appIndex = 0;
      const updatedAppImageUrlList = imagesAppWithId.map((item) => {
        if (item.file) {
          appIndex++;
          return appImageResponse.data[appIndex - 1] || item.url;
        }
        return item.url;
      });
  
      let webIndex = 0;
      const updatedWebImageUrlList = imagesWebWithId.map((item) => {
        if (item.file) {
          webIndex++;
          return webImageResponse.data[webIndex - 1] || item.url;
        }
        return item.url;
      });
  
      const updatedAttractionData = {
        id: attractionData.id,
        title: formValues.title,
        subtitle: formValues.subtitle,
        introduction: removeTextEditorValueKeysWithNoPlainText(formValues.introduction),
        description: removeTextEditorValueKeysWithNoPlainText(formValues.description),
        tips: removeKeysWithEmptyValueArrays(formValues.tips),
        highlights: removeKeysWithEmptyValueArrays(formValues.highlights),
        directions: removeKeysWithEmptyValueArrays(formValues.directions),
        appImages: updatedAppImageUrlList,
        webImages: updatedWebImageUrlList,
      };
  
      await editAttractionContentThunk(updatedAttractionData.id, updatedAttractionData);
      await getAttractionsListThunk({
        destinationId: appState.selectedDestination.id,
        pageNumber: attractionsState.pageNumber,
        pageSize: attractionsState.pageSize,
      });
      await getAttractionThunk(id);
      setIsSave(false);
      addError(`${t('attractions.content.error_edit_text')}`, 'Success');
    } catch (error) {
      addError(`${t('attractions.content.error_not_edit_text')}`, 'Error', error);
      resetFormValues();
      setIsSave(false);
    }
  };
  

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    if(id){
      dispatch(setTasks(9))
    }
    else {
      dispatch(setTasks(8))
    }
  }, [])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (id) {
          await getAttractionThunk(id);
          dispatch(incrementCompletedTasks());
        }
        await dispatch(getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getPredefinedSettingsThunk());
        dispatch(incrementCompletedTasks());

        await dispatch(getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getTagsThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <>
      {attractionData?.id ? (
        <ContentForm
          onSubmit={onSubmitContentForm}
          attractionData={attractionData}
          isInEditMode={isInEditMode}
          setIsEditContent={setIsInEditMode}
          languages={destination}
          isSave={isSave}
          selectedLanguage={selectedLanguage}
          imagesAppWithId = {imagesAppWithId}
          setImagesAppWithId = {setImagesAppWithId}
          imagesWebWithId = {imagesWebWithId}
          setImagesWebWithId = {setImagesWebWithId}
        />
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </>
  );
};

export default Content;
