import { connect } from 'react-redux';
import { compose } from 'redux';
import FaqPage from './FaqPage';

import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';
import { getCategoryFaqListThunk } from '../../redux/faqReducers/categoryFaqReducer';


const mapStateToProps = (state) => {
  return {
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default compose(
  connect(mapStateToProps, {
    getPredefinedSettingsThunk,
    getCategoryFaqListThunk,
  })
)(FaqPage);
