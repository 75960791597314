import { stopSubmit } from 'redux-form';
import {
  getSearchQuestionApi,
  addQuestionsFaqApi,
  editQuestionsFaqApi,
  deleteQuestionsFaqApi,
} from '../../api/faqApi';
const SET_QUESTIONS_DATA = 'SET_QUESTIONS_DATA';
const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
const SET_IS_LOADING_QUESTIONS_LIST_DATA = 'SET_IS_LOADING_QUESTIONS_LIST_DATA';

const initialState = {
  questionsData: [],
  currentQuestion: {},
  isLoadingData: false,
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONS_DATA:
      return {
        ...state,
        questionsData: [...action.payload.data],
      };
    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: { ...action.payload.data },
      };
    case SET_IS_LOADING_QUESTIONS_LIST_DATA:
      return {
        ...state,
        isLoadingData: action.isLoading,
      };
    default:
      return state;
  }
};

let setQuestionsList = (data) => {
  return { type: SET_QUESTIONS_DATA, payload: { data } };
};

export let setCurrentQuestion = (data) => {
  return { type: SET_CURRENT_QUESTION, payload: { data } };
};

let setIsLoadingQuestionsListData = (isLoading) => {
  return { type: SET_IS_LOADING_QUESTIONS_LIST_DATA, isLoading };
};

export let getSearchQuestionThunk =
  (status, categoryId, selectedDestinationId) => async (dispatch) => {
    let response = await getSearchQuestionApi(status, categoryId, selectedDestinationId);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setQuestionsList(response.data));
      dispatch(setIsLoadingQuestionsListData(true));
    }
  };

export let addQuestionsThunk = (data) => async (dispatch) => {
  let response = await addQuestionsFaqApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('QuestionsForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let editQuestionThunk = (id, data) => async (dispatch) => {
  let response = await editQuestionsFaqApi(id, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('QuestionsForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let deleteQuestionThunk = (id) => async () => {
  let response = await deleteQuestionsFaqApi(id);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export default faqReducer;
