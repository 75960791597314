import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionsForm from './QuestionsForm';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { addQuestionsThunk } from '../../../redux/faqReducers/faqQuestionReducer';

import { FAQ_QUESTION_STATUS } from '../../../consts/consts';
import { reset } from 'redux-form';

const QuestionAddFormContainer = (props) => {
  const { appState, questionFaqState, categoryFaqState, addQuestionsThunk,  selectedDestinationId } =
    props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormCancel = () => {
    dispatch(reset('QuestionsForm'));
    navigate(-1);
  };

  const handleFormSubmit = (values) => {
    setShowSaveAnimation(true);

    const data = {
      destinationId: selectedDestinationId,
      order: values.order,
      categoryId: values.categoryId,
      status: values.status ? values.status : 'Active',
      title: values.title,
      text: values.text,
    };

    addQuestionsThunk(data)
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('faq_page.questions.error_add_text')}`, 'Success');
        dispatch(reset('QuestionsForm'));
        navigate(-1);
      })
      .catch((error) => {
        addError(
          `${t('faq_page.questions.error_not_add_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  return (
    <QuestionsForm
      addQuestion
      onSubmit={handleFormSubmit}
      onCancel={handleFormCancel}
      appState={appState}
      questionFaqState={questionFaqState}
      predefinedState={appState.globalSettingsData.filter(
        (item) => item.name.en === FAQ_QUESTION_STATUS
      )}
      isSave={showSaveAnimation}
      isEdit={false}
      categoryFaqState={categoryFaqState}
      selectedLanguage={appState.selectedLanguage}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    questionFaqState: state.faqQuestionReducer,
    categoryFaqState: state.categoryFaqReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, { addQuestionsThunk })(
  QuestionAddFormContainer
);
