import { stopSubmit } from 'redux-form';
import {
    getSettingsSeasonsApi,
    getSettingsSearchSeasonsApi,
    addSettingsSeasonsApi,
    deleteSettingsSeasonsApi,
    editSettingsSeasonsApi,
} from '../../api/settingsApi.js';
const SET_SETTINGS_SEASONS = 'SET_SETTINGS_SEASONS';
const SET_SETTINGS_CURRENT_SEASON = 'SET_SETTINGS_CURRENT_SEASON';
const IS_SETTINGS_LOADING_SEASONS_DATA = 'IS_SETTINGS_LOADING_SEASONS_DATA';

const initialState = {
    settingsSeasonsData: [],
    currentSeason: {},
    isLoadingSeasonsData: false,
};

const seasonsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS_SEASONS:
            return {
                ...state,
                settingsSeasonsData: [...action.payload.data]
            };
        case SET_SETTINGS_CURRENT_SEASON:
            return {
                ...state,
                currentSeason: {...action.payload.data}
            };
        case IS_SETTINGS_LOADING_SEASONS_DATA:
            return {
                ...state,
                isLoadingSeasonsData: action.isLoadingSeasonsData
            };
        default:
            return state;
    }
};


export let setSettingsSeasons = (data) => {
    return { type: SET_SETTINGS_SEASONS, payload: { data } };
}

export let setCurrentSeason = (data) => {
    return { type: SET_SETTINGS_CURRENT_SEASON, payload: { data } };
}

let setIsLoadingSeasonsData = (isLoadingSeasonsData) => {
    return { type: IS_SETTINGS_LOADING_SEASONS_DATA, isLoadingSeasonsData };
}


export let getSettingsSeasonsThunk = (destinationId) => async (dispatch) => {
    let response = await getSettingsSeasonsApi(destinationId);
    if (response.status >= 200 && response.status < 300) {
        dispatch(setSettingsSeasons(response.data));
        dispatch(setIsLoadingSeasonsData(true));
    } else {
        dispatch(setIsLoadingSeasonsData(false));
    }
}

export let getSettingsSearchSeasonsThunk = (destinationId, name) => async (dispatch) => {
    let response = await getSettingsSearchSeasonsApi(destinationId, name);
    if (response.status >= 200 && response.status < 300) {
        dispatch(setSettingsSeasons(response.data));
    }
}

export let addSettingsSeasonsThunk = (data) => async (dispatch) => { 
    let response = await addSettingsSeasonsApi(data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Seasons", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let editSettingsSeasonsThunk = (id, data) => async (dispatch) => {
    let response = await editSettingsSeasonsApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Seasons", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let deleteSettingsSeasonsThunk = (id) => async () => {
    let response = await deleteSettingsSeasonsApi(id);
    if(response.status < 200 || response.status >= 300) {
        return Promise.reject(response.data.errors)
    }
}

export default seasonsReducer;