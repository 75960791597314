import { useState, useEffect } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { a11yProps } from '../../components/tabPanel/TabPanel';

import { faqTabs, getOpenedTabIdByUrlParameter } from './faqTabs';

import { setTitle } from '../../redux/appReducer';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
  },
});

const FaqPage = (props) => {
  const {selectedDestinationId} = props
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedTabId, setSelectedTabId] = useState(
    getOpenedTabIdByUrlParameter(faqTabs, location.pathname)
  );

  const handleChangeTab = (newValue) => {
    setSelectedTabId(newValue);
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_faq')));
    props.getPredefinedSettingsThunk();
    if(selectedDestinationId) {
      props.getCategoryFaqListThunk(selectedDestinationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationId]);

  useEffect(() => {
    handleChangeTab(getOpenedTabIdByUrlParameter(faqTabs, location.pathname));
  }, [location.pathname]);

  return (
    <Box sx={{ width: '1300px', m: '95px auto' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTabId}
          className={classes.tabs}
          textColor="inherit"
        >
          {faqTabs.map((tab, index) => (
            <Tab
              label={t(tab.name)}
              sx={{ width: '10%' }}
              component={Link}
              to={tab.path}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default FaqPage;
