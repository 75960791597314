import { connect } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CategoryForm from './CategoryForm';

import {
  getCategoryThunk,
  addCategoryThunk,
} from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import useAPIError from '../../../apIErrorProvider/useAPIError';
import { getImageUrl } from './utils';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';
import {
  ATTRACTION_CATEGORY_STATUS,
  CONTENT_LANGUAGES,
} from '../../../consts/consts';
import { CATEGORY_PAGE_ROUTE } from './constants';

const CategoryAddFormContainer = (props) => {
  const {
    predefinedState,
    globalSettingsState,
    selectedLanguage,
    stateDestinations,
    categoryState,
    addCategoryThunk,
    getCategoryThunk,
    selectedDestinationId
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = async (values) => {
    setShowSaveAnimation(true);

    const destinationArray = [];

    stateDestinations.map((item) => {
      Object.keys(values).map((value) => {
        if (value === item.name && values[value] === true) {
          destinationArray.push(item.id);
        }
      });
    });

    const imageUrl = await getImageUrl(
      values.imageList,
      uploadImageFile,
      'App'
    );
    const iconUrl = await getImageUrl(values.iconList, uploadImageFile, 'Icon');

    const data = {
      destinationId:selectedDestinationId,
      order: values.order,
      name: values.name,
      status: values.status,
      iconUrl: iconUrl,
      imageUrl: imageUrl,
      availableForDestination: destinationArray,
    };

    addCategoryThunk(data)
      .then(() => {
        getCategoryThunk(selectedDestinationId);
        setShowSaveAnimation(false);
        addError(`${t('settings_page.category.error_add_text')}`, 'Success');
        navigate(CATEGORY_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.category.error_not_add_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(CATEGORY_PAGE_ROUTE);
  };

  return (
    <>
      {!!predefinedState.length && !!globalSettingsState.length && (
        <CategoryForm
          onSubmit={handleFormSubmit}
          categoryState={categoryState}
          addCategory
          isEdit={false}
          updateCategory={false}
          stateDestinations={stateDestinations}
          predefinedState={predefinedState.filter(
            (item) => item.name.en === ATTRACTION_CATEGORY_STATUS
          )}
          languages={globalSettingsState.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          selectedLanguage={selectedLanguage}
          isSave={showSaveAnimation}
          onCancel={handleFormCancel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    predefinedState: state.predefinedReducer.predefinedData,
    globalSettingsState: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
    stateDestinations: state.settingsReducer.destinationData,
    categoryState: state.categoryReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  addCategoryThunk,
  getCategoryThunk,
  getPredefinedSettingsThunk,
})(CategoryAddFormContainer);
