import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Button, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import LanguageTabs from './LanguageTabs';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import { SeasonFormControlPanel } from './styled';

const useStyles = makeStyles({
  form: {
    width: '100%',
    '& .MuiTextField-root': {
      marginBottom: '0px',
    },
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
    },
    maxWidth: '800px',
  },
});

let SeasonsForm = (props) => {
  const { onCancel } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const onEditSeason = () => {
    props.setIsEdit(true);
  };

  const onDeleteSeason = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setIsEdit(false);
    props.setShowAlert(false);
  };

  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <Box
        margin="0 0 25px 0"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          fontWeight="500px"
          fontSize="20px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {props.updateSeason
            ? props.seasonsState.currentSeason.name[props.selectedLanguage]
            : `${t('settings_page.seasons.new_season')}`}
        </Typography>

        <SeasonFormControlPanel>
          <Button
            variant="outlined"
            onClick={onCancel}
            type="button"
            disabled={props.addSeason ? false : !props.isEdit}
          >
            {t('cancel')}
          </Button>
          {props.updateSeason && (
            <>
              <Button
                variant="outlined"
                onClick={onEditSeason}
                type="button"
                disabled={props.isEdit}
              >
                {t('settings_page.seasons.edit_button')}
              </Button>
              <Button
                variant="outlined"
                onClick={onDeleteSeason}
                type="button"
                disabled={props.isSave}
              >
                {t('settings_page.seasons.delete_button')}
              </Button>
            </>
          )}
          <LoadingButton
            loading={props.isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff' }}
            disabled={props.addSeason || props.isEdit ? false : true}
            type="submit"
          >
            {t('settings_page.seasons.save_button')}
          </LoadingButton>
        </SeasonFormControlPanel>
      </Box>
      <Box>
        <LanguageTabs
          seasonsState={props.seasonsState}
          addElement={props.addSeason}
          updateElement={props.updateSeason}
          isEdit={props.isEdit}
          placeholder={t('settings_page.seasons.new_season')}
          languages={props.languages}
          error={props.error}
        />
      </Box>

      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleCloseSuccess}
        text={t('settings_page.seasons.delete_season')}
      />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.isEdit) {
    return { initialValues: { ...state.seasonsReducer.currentSeason.name } };
  } else {
    return { initialValues: {} };
  }
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'Seasons', enableReinitialize: true })(SeasonsForm)
);
