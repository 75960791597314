import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { change, Field, FieldArray, FormSection, getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { editAttractionContentTranslateThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions.js';
import TextEditor from '../../../components/RichTextEditor/TextEditor';
import FieldList from './fieldList/FieldList';

import {
  ContentFormFieldTitle,
  StyledTabButton,
  ContentFormErrorContainer,
  FieldListContainer,
  ContentFormTextField,
  TabButtonsContainer,
  LanguageTabPanel,
  ContentFormFieldWrapper,
} from './styled';

import { noSpaceName, maxLength250 } from './validate.js';
import { TranslateButton } from '../../../components/buttons/Buttons.js';
import { useDispatch, useSelector } from 'react-redux';
import { removeKeysWithEmptyValueArrays, removeTextEditorValueKeysWithNoPlainText } from '../../../helpers/translationObjectUtils.js';

const useStyles = makeStyles({
  tabsWrapper: {
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap'
    },
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <LanguageTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </LanguageTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const AttractionLanguageTabs = (props) => {
  const { languages, isInEditMode, error, selectedLanguage } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);
  const [valueDirty, setValueDirty] = useState(false);

  const required = useCallback(value => (value ? undefined : t('attractions.content.error_required')), []);

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
    setValueDirty(true);
  };

  const dispatch = useDispatch();
  const formValues = useSelector(state => getFormValues('Content')(state));
  const [formValuesState, setFormValuesState] = useState(formValues);
  
  const attractionData = useSelector(state => state.attractionsReducer.currentAttraction);
  const defaultLanguage = useSelector(state => state.appReducer.selectedDestination.languages[0]);
  const {tips, highlights, directions} = formValues;
  
  useEffect(() => {
    setFormValuesState(formValues);
  }, [isInEditMode])

  const onTranslate = async(translationField,translationLanguages) => {
    const updatedAttractionData = {
      title: formValues.title,
      subtitle: formValues.subtitle,
      introduction: removeTextEditorValueKeysWithNoPlainText(
        formValues.introduction
      ),
      description: removeTextEditorValueKeysWithNoPlainText(
        formValues.description
      ),
      tips: removeKeysWithEmptyValueArrays(formValues.tips),
      highlights: removeKeysWithEmptyValueArrays(formValues.highlights),
      directions: removeKeysWithEmptyValueArrays(formValues.directions),
      translationField,
      translationLanguages,
    };
    return dispatch(editAttractionContentTranslateThunk(
      attractionData.id,
      updatedAttractionData
    )).then((response) => {
      Object.entries(response[translationField]).forEach(([lang, translatedValue]) => {
        dispatch(change('Content', `${translationField}.${lang}`, translatedValue));
      });
      dispatch(change('Content', 'notTranslatedFields', response.notTranslatedFields));

      // dispatch(setAttraction(newAtrraction));
      setFormValuesState(prev => {
        return {
          ...prev,
          [translationField]: formValues[translationField]
        }
      })
    }).catch(error => {
      
      throw error;
    });
    
  }

  const handleDeleteItem = (fieldName, index, language) => {
    const updatedValues = { ...formValues[fieldName] };
    console.log(language, languages[0]);
    
    if(language === languages[0]) {
      Object.keys(updatedValues).forEach((language) => {
        updatedValues[language] = updatedValues[language].filter((_, i) => i !== index);
      });
    } else {
      updatedValues[language] = updatedValues[language].filter((_, i) => i !== index);
    }
  
    dispatch(change('Content', fieldName, updatedValues));
  };

  return (
    <Box>
      <TabButtonsContainer>
        <Tabs
          value={tabsValue}
          indicatorColor="none"
          onChange={handleChangeTabs}
          className={classes.tabsWrapper}
        >
          {languages.map((tab, index) => (
            <StyledTabButton label={tab} {...a11yProps(index)} />
          ))}
        </Tabs>
      </TabButtonsContainer>
      {languages.map((tabPanel, index) => (
        <TabPanel value={tabsValue} index={index} key={index}>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.title')}:
            </ContentFormFieldTitle>
            <FormSection name="title">
              <div style={{display: 'flex',width:'100%', gap: '10px', alignItems: 'center'}}>
                <Field
                  name={tabPanel}
                  component={ContentFormTextField}
                  placeholder={t('attractions.content.title')}
                  validate={[required, noSpaceName, maxLength250]}
                  disabled={!isInEditMode}
                />
                <TranslateButton 
                  title={t('attractions.content.title')} 
                  tab={tabsValue} 
                  disabled={!isInEditMode}
                  field={'title'}
                  data = {attractionData && attractionData.title}
                  notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.title : attractionData.notTranslatedFields &&  attractionData.notTranslatedFields.title}
                  differentData = {formValuesState?.title[defaultLanguage] !== formValues?.title[defaultLanguage]}
                  handleSendTranslation={onTranslate}
                />
              </div>
              {error && error.Title && (
                <ContentFormErrorContainer variant="caption" component="span">
                  {`* ${error.Title[0]}`}
                </ContentFormErrorContainer>
              )}
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.subtitle')}:
            </ContentFormFieldTitle>
            <FormSection name="subtitle">
              <div style={{display: 'flex',width:'100%', gap: '10px', alignItems: 'center'}}>
                <Field
                  name={tabPanel}
                  component={ContentFormTextField}
                  placeholder={t('attractions.content.subtitle')}
                  validate={[noSpaceName, maxLength250]}
                  disabled={!isInEditMode}
                />
                <TranslateButton 
                  title={t('attractions.content.subtitle')} 
                  tab={tabsValue}
                  disabled={!isInEditMode && formValues && formValues.subtitle}
                  field={'subtitle'}
                  data = {attractionData && attractionData.subtitle}
                  notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.subtitle : attractionData.notTranslatedFields &&  attractionData.notTranslatedFields.subtitle}
                  differentData = {formValuesState?.subtitle[defaultLanguage] !== formValues?.subtitle[defaultLanguage]}
                  handleSendTranslation={onTranslate}
                />
              </div>
              {error && error.Subtitle && (
                <ContentFormErrorContainer variant="caption" component="span">
                  {`* ${error.Subtitle[0]}`}
                </ContentFormErrorContainer>
              )}
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.introduction')}:
            </ContentFormFieldTitle>
            <FormSection name="introduction">
              <div style={{display: 'flex',width:'100%', gap: '10px'}}>
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  isEdit={isInEditMode}
                  placeholder={t('attractions.content.introduction')}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
                <TranslateButton 
                  title={t('attractions.content.introduction')} 
                  tab={tabsValue}
                  disabled={!isInEditMode && formValues && formValues.introduction}
                  field={'introduction'}
                  data = {attractionData && attractionData.introduction}
                  notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.introduction : attractionData.notTranslatedFields &&  attractionData.notTranslatedFields.introduction}
                  differentData = {(formValuesState?.introduction[defaultLanguage] !== formValues?.introduction[defaultLanguage])}
                  handleSendTranslation={onTranslate}
                />
              </div>
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.description')}:
            </ContentFormFieldTitle>
            <FormSection name="description">
              <div style={{display: 'flex',width:'100%', gap: '10px'}}>
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  isEdit={isInEditMode}
                  placeholder={t('attractions.content.description')}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
                <TranslateButton 
                  title={t('attractions.content.description')} 
                  tab={tabsValue}
                  disabled={!isInEditMode && formValues && formValues.description}
                  field={'description'}
                  data = {attractionData && attractionData.description}
                  notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.description : attractionData.notTranslatedFields &&  attractionData.notTranslatedFields.description}
                  differentData = { formValuesState?.description[defaultLanguage] !== formValues?.description[defaultLanguage]}
                  handleSendTranslation={onTranslate}
                />
              </div>
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <FormSection name="tips">
              <FieldListContainer>
                <ContentFormFieldTitle>
                  {t('attractions.content.tips')}:
                </ContentFormFieldTitle>
                <FieldArray
                  name={tabPanel}
                  component={FieldList}
                  disabled={!isInEditMode}
                  sectionName="tips"
                  formName="Content"
                  selectedLanguage={selectedLanguage}
                  handleDeleteItem={(index) => handleDeleteItem('tips', index, tabPanel)}
                />
                {(tips[defaultLanguage] && tips[defaultLanguage].length > 0) &&
                    <div style={{margin:'5px 0 0 0'}}>
                    <TranslateButton 
                      title={t('attractions.content.tips')} 
                      tab={tabsValue}
                      disabled={!isInEditMode}
                      field={'tips'}
                      data={tips}
                      notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.tips : attractionData.notTranslatedFields &&  attractionData.notTranslatedFields.tips}
                      differentData = {JSON.stringify(formValuesState?.tips[defaultLanguage]) !== JSON.stringify(formValues?.tips[defaultLanguage]) && formValues?.tips[defaultLanguage][0]}
                      handleSendTranslation={onTranslate}
                    />
                  </div>
                }
              </FieldListContainer>
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <FormSection name="highlights">
              <FieldListContainer>
                <ContentFormFieldTitle>
                  {t('attractions.content.highlights')}:
                </ContentFormFieldTitle>
                <FieldArray
                  name={tabPanel}
                  component={FieldList}
                  disabled={!isInEditMode}
                  sectionName="highlights"
                  formName="Content"
                  selectedLanguage={selectedLanguage}
                  handleDeleteItem={(index) => handleDeleteItem('highlights', index, tabPanel)}
                />
                  {(highlights[defaultLanguage] && highlights[defaultLanguage].length > 0) &&
                  <div style={{margin:'5px 0 0 0'}}>
                    <TranslateButton 
                      title={t('attractions.content.highlights')} 
                      tab={tabsValue}
                      disabled={!isInEditMode}
                      field={'highlights'}
                      data={highlights}
                      notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.highlights : attractionData.notTranslatedFields && attractionData.notTranslatedFields.highlights}
                      differentData = {JSON.stringify(formValuesState?.highlights[defaultLanguage]) !== JSON.stringify(formValues?.highlights[defaultLanguage]) && formValues?.highlights[defaultLanguage][0] !== '' && formValues?.highlights[defaultLanguage][0]}
                      handleSendTranslation={onTranslate}
                    />
                  </div>
                  }
              </FieldListContainer>
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <FormSection name="directions">
              <FieldListContainer>
                <ContentFormFieldTitle>
                  {t('attractions.content.directions')}:
                </ContentFormFieldTitle>
                <FieldArray
                  name={tabPanel}
                  component={FieldList}
                  disabled={!isInEditMode}
                  sectionName="directions"
                  formName="Content"
                  selectedLanguage={selectedLanguage}
                  handleDeleteItem={(index) => handleDeleteItem('directions', index, tabPanel)}
                />
                 {(directions[defaultLanguage] && directions[defaultLanguage].length > 0) &&
                    <div style={{margin:'5px 0 0 0'}}>
                    <TranslateButton 
                      title={t('attractions.content.directions')} 
                      tab={tabsValue}
                      disabled={!isInEditMode}
                      field={'directions'}
                      data={directions}
                      notTranslated={formValues.notTranslatedFields ? formValues.notTranslatedFields.directions : attractionData.notTranslatedFields && attractionData.notTranslatedFields.directions}
                      differentData = {JSON.stringify(formValuesState?.directions[defaultLanguage]) !== JSON.stringify(formValues?.directions[defaultLanguage]) && formValues?.directions[defaultLanguage][0] !== '' && formValues?.directions[defaultLanguage][0]}
                      handleSendTranslation={onTranslate}
                    />
                  </div>
                  }
              </FieldListContainer>
            </FormSection>
          </ContentFormFieldWrapper>
        </TabPanel>
      ))}
    </Box>
  );
};

export default AttractionLanguageTabs;
