import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { reset } from 'redux-form';
import { useNavigate, useParams } from 'react-router-dom';

import SeasonsForm from './SeasonsForm';

import { SeasonFormWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { CONTENT_LANGUAGES } from '../../../consts/consts';
import {
  getSettingsSeasonsThunk,
  getSettingsSearchSeasonsThunk,
  deleteSettingsSeasonsThunk,
  editSettingsSeasonsThunk,
  setCurrentSeason,
} from '../../../redux/settingsReducers/seasonsReducer';
import { SEASONS_PAGE_ROUTE } from './constants';

const SeasonEditFormContainer = (props) => {
  const {
    appReducer,
    seasonsState,
    globalSettings,
    selectedLanguage,
    editSettingsSeasonsThunk,
    getSettingsSeasonsThunk,
    setCurrentSeason,
    deleteSettingsSeasonsThunk,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [showAlert, setShowAlert] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = (values) => {
    setShowSaveAnimation(true);
    setIsInEditMode(false);
  
    const previousSeason = { ...seasonsState.currentSeason };
  
    const updatedSeason = {
      ...seasonsState.currentSeason,
      name: values,
    };
    setCurrentSeason(updatedSeason);
  
    editSettingsSeasonsThunk(seasonsState.currentSeason.id, updatedSeason)
      .then(() => {
        getSettingsSeasonsThunk(appReducer.selectedDestination.id);
        setShowSaveAnimation(false);
        addError(`${t('settings_page.seasons.error_edit_text')}`, 'Success');
        navigate(SEASONS_PAGE_ROUTE);
      })
      .catch((error) => {
        setCurrentSeason(previousSeason);
        addError(
          `${t('settings_page.seasons.error_not_edit_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
    dispatch(reset('Seasons'));
  };

  const handleCloseSuccess = () => {
    setIsInEditMode(false);
    setShowSaveAnimation(true);

    deleteSettingsSeasonsThunk(seasonsState.currentSeason.id)
      .then(() => {
        setShowAlert(false);
        getSettingsSeasonsThunk(appReducer.selectedDestination.id);
        addError(`${t('settings_page.seasons.error_deleted_text')}`, 'Success');
        navigate(SEASONS_PAGE_ROUTE);
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        setShowSaveAnimation(false);
        addError(
          `${t('settings_page.seasons.error_not_deleted_text')}`,
          'Error',
          error
        );
      });
  };

  useEffect(() => {
    if (id) {
      const currentSeason = seasonsState.settingsSeasonsData.find((season) => season.id === id);
      setCurrentSeason(currentSeason);
    }

    return () => setIsInEditMode(false);
  }, [id, setCurrentSeason, seasonsState.settingsSeasonsData]);

  return (
    <SeasonFormWrapper>
      {seasonsState.currentSeason?.id && (
        <SeasonsForm
          onSubmit={handleFormSubmit}
          seasonsState={seasonsState}
          isEdit={isInEditMode}
          setIsEdit={setIsInEditMode}
          updateSeason={true}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleCloseSuccess={handleCloseSuccess}
          languages={globalSettings.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          selectedLanguage={selectedLanguage}
          isSave={showSaveAnimation}
          onCancel={handleFormCancel}
        />
      )}
    </SeasonFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    seasonsState: state.seasonsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  getSettingsSeasonsThunk,
  getSettingsSearchSeasonsThunk,
  deleteSettingsSeasonsThunk,
  editSettingsSeasonsThunk,
  setCurrentSeason,
})(SeasonEditFormContainer);
