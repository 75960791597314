import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FacilitiesForm from './FacilitiesForm';

import useAPIError from '../../../apIErrorProvider/useAPIError';

import { CONTENT_LANGUAGES } from '../../../consts/consts';
import { getImageUrl } from './utils';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';
import {
  getFacilitiesThunk,
  deleteFacilitiesThunk,
  editFacilitiesThunk,
  setCurrentFacility,
} from '../../../redux/settingsReducers/facilitiesReducer';
import { FACILITY_PAGE_ROUTE } from './constants';

const FacilityFormAddContainer = (props) => {
  const {
    facilitiesState,
    globalSettingsState,
    selectedLanguage,
    editFacilitiesThunk,
    getFacilitiesThunk,
    deleteFacilitiesThunk,
    setCurrentFacility,
    selectedDestinationId
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);

  const handleFormCancel = () => {
    setIsInEditMode(false);
  };

  const handleFormSubmit = async (values) => {
    setShowSaveAnimation(true);

    const iconUrl = await getImageUrl(values.iconList, uploadImageFile, 'Icon');

    const updateData = {
      destinationId: selectedDestinationId,
      id: facilitiesState.currentFacility.id,
      name: values.name,
      iconUrl: iconUrl,
    };

    editFacilitiesThunk(facilitiesState.currentFacility.id, updateData)
      .then(() => {
        getFacilitiesThunk(selectedDestinationId);
        setShowSaveAnimation(false);
        setIsInEditMode(false);
        setShowAlert(false);
        addError(`${t('settings_page.facility.error_edit_text')}`, 'Success');
        navigate(FACILITY_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.facility.error_not_edit_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleCloseSuccess = () => {
    setIsInEditMode(false);
    deleteFacilitiesThunk(facilitiesState.currentFacility.id)
      .then(() => {
        getFacilitiesThunk(selectedDestinationId);
        setShowAlert(false);
        addError(
          `${t('settings_page.facility.error_deleted_text')}`,
          'Success'
        );
        navigate(FACILITY_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.facility.error_not_deleted_text')}`,
          'Error',
          error
        );
      });
  };

  useEffect(() => {
    if (id) {
      const currentFacility = facilitiesState.facilitiesData.find(
        (item) => item.id === id
      );

      setCurrentFacility(currentFacility);

      return () => {
        setIsInEditMode(false);
      };
    }
  }, [id, setCurrentFacility, facilitiesState.facilitiesData]);

  return (
    <>
      {facilitiesState.currentFacility.id && (
        <FacilitiesForm
          facilitiesState={facilitiesState}
          onSubmit={handleFormSubmit}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          updateFacility={true}
          isEdit={isInEditMode}
          setIsEdit={setIsInEditMode}
          handleCloseSuccess={handleCloseSuccess}
          languages={globalSettingsState.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          selectedLanguage={selectedLanguage}
          isSave={showSaveAnimation}
          onCancel={handleFormCancel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalSettingsState: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
    facilitiesState: state.facilitiesReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default connect(mapStateToProps, {
  getFacilitiesThunk,
  deleteFacilitiesThunk,
  editFacilitiesThunk,
  setCurrentFacility,
})(FacilityFormAddContainer);
