import { connect } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FacilitiesForm from './FacilitiesForm';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import {
  getFacilitiesThunk,
  addFacilitiesThunk,
} from '../../../redux/settingsReducers/facilitiesReducer';
import { CONTENT_LANGUAGES } from '../../../consts/consts';
import { getImageUrl } from './utils';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';
import { FACILITY_PAGE_ROUTE } from './constants';

const FacilityFormAddContainer = (props) => {
  const {
    facilitiesState,
    globalSettingsState,
    selectedLanguage,
    addFacilitiesThunk,
    getFacilitiesThunk,
    selectedDestinationId
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = async (values) => {
    setShowSaveAnimation(true);

    const iconUrl = await getImageUrl(values.iconList, uploadImageFile, 'Icon');

    const data = {
      destinationId: selectedDestinationId,
      name: values.name,
      iconUrl: iconUrl,
    };

    addFacilitiesThunk(data)
      .then(() => {
        setShowSaveAnimation(false);
        getFacilitiesThunk(selectedDestinationId);
        addError(`${t('settings_page.facility.error_add_text')}`, 'Success');
        navigate(FACILITY_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.facility.error_not_add_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(FACILITY_PAGE_ROUTE);
  };

  return (
    <FacilitiesForm
      addFacility
      facilitiesState={facilitiesState}
      onSubmit={handleFormSubmit}
      updateFacility={false}
      isEdit={false}
      languages={globalSettingsState.filter(
        (item) => item.name.en === CONTENT_LANGUAGES
      )}
      selectedLanguage={selectedLanguage}
      isSave={showSaveAnimation}
      onCancel={handleFormCancel}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    globalSettingsState: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
    facilitiesState: state.facilitiesReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default connect(mapStateToProps, {
  addFacilitiesThunk,
  getFacilitiesThunk,
})(FacilityFormAddContainer);
