import * as React from 'react';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Typography,
  TableBody,
  Box,
  Table,
  TablePagination,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import RedeemCodePopupContainer from './redeemCodePopup/RedeemCodePopupContainer';
import RedeemCodePopup from './redeemCodePopup/RedeemCodePopup';
import AttractionVisitsTableHeader from './visitsTable/AttractionVisitsTableHeader';
import AttractionVisitTableDataRow from './visitsTable/AttractionVisitTableDataRow';
import AttractionVisitsFilterForm from './visitsFilterForm/AttractionVisitsFilterForm';

import { VisitAddButton, VisitsPageContainer } from './styled';

import {
  DATE_SORT_ASC_DIRECTION,
  DATE_SORT_DESC_DIRECTION,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from '../../visits/constants';

import { validateTextFieldValue } from '../../../helpers/validateTextFieldValue';
import { setPaginationSettings } from '../../../redux/visits/visitsActions';
import { TABLE_PAGINATION_OPTIONS } from './constants';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { getAttractionThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';

const AttractionVisitsList = ({
  isDataLoading,
  visitsList,
  paginationSettings,
  getVisitsListThunk,
  getPredefinedSettingsThunk,
  benefitOptions,
  selectedLanguage,
  getAttractionVisitsThunk,
  languages,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState('');

  const handleChangeSortDirection = () =>
    setSortDirection(
      sortDirection === DATE_SORT_ASC_DIRECTION
        ? DATE_SORT_DESC_DIRECTION
        : DATE_SORT_ASC_DIRECTION
    );

  const initialFormState = {
    dateAndTime: '',
    searchQuery: '',
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { watch, setValue, reset } = formMethods;

  const searchQueryValue = watch('searchQuery');
  const dateAndTimeValue = watch('dateAndTime');

  const handleResetFilters = () => {
    reset(initialFormState);
  };

  const formattedDateTime =
    dateAndTimeValue && new Date(dateAndTimeValue).toISOString();

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: 1000 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const handleResetDateSortDirection = () => setSortDirection('');

  const handleChangeSearchValue = (e) => {
    setValue('searchQuery', validateTextFieldValue(e.target.value));
    handlePaginationSettings();
  };

  const handlePaginationSettings = (
    pageSize = DEFAULT_PAGE_SIZE,
    pageNumber = DEFAULT_PAGE_NUMBER
  ) => {
    dispatch(
      setPaginationSettings({
        ...paginationSettings,
        pageSize,
        pageNumber,
      })
    );
    handleResetDateSortDirection();
  };

  const onChangeRowsPerPage = (e) =>
    handlePaginationSettings(parseInt(e.target.value, 10), 0);

  const onChangePage = (_, newPageNumber) =>
    handlePaginationSettings(paginationSettings.pageSize, newPageNumber);

  useEffect(() => {
    getPredefinedSettingsThunk();

    return () => handlePaginationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPredefinedSettingsThunk]);

  useEffect(() => {
    id &&
      getAttractionVisitsThunk(
        id,
        paginationSettings.pageNumber,
        paginationSettings.pageSize,
        searchQueryValue,
        formattedDateTime,
        sortDirection
      );
  }, [
    id,
    formattedDateTime,
    getVisitsListThunk,
    paginationSettings.pageNumber,
    paginationSettings.pageSize,
    searchQueryValue,
    sortDirection,
    getAttractionVisitsThunk,
  ]);

  const appState = useSelector(
    (state) => state.appReducer
  );

  const settingsState = useSelector(
    (state) => state.settingsReducer
  );

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    if(id) {
      dispatch(setTasks(10))
    } else {
      dispatch(setTasks(8))
    }
  }, [])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (id) {
          await dispatch(getAttractionThunk(id));
          dispatch(incrementCompletedTasks());

          await getAttractionVisitsThunk(
            id,
            paginationSettings.pageNumber,
            paginationSettings.pageSize,
            searchQueryValue,
            formattedDateTime,
            sortDirection
          );
          dispatch(incrementCompletedTasks());
        }
  
        await getPredefinedSettingsThunk();
        dispatch(incrementCompletedTasks());

        await dispatch(getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getTagsThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <VisitsPageContainer>
      <Box mb="10px">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('visitsPage.headline')}
        </Typography>
      </Box>

      <Box>
        <VisitAddButton
          variant="contained"
          onClick={() => setIsPopupOpen(true)}
        >
          {t('visitsPage.add_new_visit')}
        </VisitAddButton>
      </Box>

      <FormProvider {...formMethods}>
        <AttractionVisitsFilterForm
          handlePaginationSettings={handlePaginationSettings}
          handleChangeSearchValue={handleChangeSearchValue}
          handleResetFilters={handleResetFilters}
        />
      </FormProvider>

      {isDataLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            rowsPerPage={paginationSettings.pageSize}
            page={paginationSettings.pageNumber}
            component="div"
            count={paginationSettings.totalCount}
            onRowsPerPageChange={onChangeRowsPerPage}
            onPageChange={onChangePage}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} ${t('attractions.deals.of')} ${
                count !== -1 ? count : `more than ${to}`
              }`
            }
          />
          <Table>
            <AttractionVisitsTableHeader
              handleChangeSortDirection={handleChangeSortDirection}
              sortDirection={sortDirection}
              handleResetDateSortDirection={handleResetDateSortDirection}
            />
            <TableBody>
              {visitsList &&
                visitsList.map((visit, idx) => (
                  <AttractionVisitTableDataRow
                    key={idx}
                    visitData={visit}
                    benefitOptions={benefitOptions}
                    languages={languages}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            rowsPerPage={paginationSettings.pageSize}
            page={paginationSettings.pageNumber}
            component="div"
            count={paginationSettings.totalCount}
            onRowsPerPageChange={onChangeRowsPerPage}
            onPageChange={onChangePage}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} ${t('attractions.deals.of')} ${
                count !== -1 ? count : `more than ${to}`
              }`
            }
          />
        </TableContainer>
      )}

      <RedeemCodePopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        title={t('visitsPage.add_new_visit')}
      >
        <RedeemCodePopupContainer
          benefitOptions={benefitOptions}
          selectedLanguage={selectedLanguage}
          handleClosePopup={() => setIsPopupOpen(false)}
          requestVisitsData={{
            id,
            pageNumber: DEFAULT_PAGE_NUMBER,
            pageSize: DEFAULT_PAGE_SIZE,
            searchQueryValue: '',
            formattedDateTime: null,
            sortDirection: '',
          }}
        />
      </RedeemCodePopup>
    </VisitsPageContainer>
  );
};

export default AttractionVisitsList;
