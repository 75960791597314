import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TablePagination, TableContainer, Table, Box, CircularProgress } from '@mui/material';

import AttractionTableDataRow from './attractionsTable/AttractionTableDataRow';
import AttractionTableHeader from './attractionsTable/AttractionTableHeader';
import { IconButton } from '@mui/material';
import { FirstPage, LastPage, ChevronLeft, ChevronRight } from '@mui/icons-material';

import {
  setPageNumber,
  setPageSize,
  LoadAttractionsListThunk,
  setPageNumberLazy
} from '../../redux/attractionsReducers/attractions/attractionsActions';
import {
  TABLE_PAGINATION_OPTIONS,
  TYPE_AREA,
  TYPE_LOCATION,
  TYPE_REGION,
} from './constants';
import {useTranslation} from "react-i18next";

const AttractionsList = (props) => {
  const {
    appState,
    categoryState,
    settingsState,
    attractionsState,
    companyState,
    pageNumber,
    pageSize,
    sortBy,
    sortField,
    sortStatus,
    handleAttractionClick,
    isLoading,
    setIsLoading,
    formData,
    isAllPagination,
    setIsAllPagination
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [createRows, setCreateRows] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const localpageNumber = useSelector(state => state.attractionsReducer.pageNumberLazy);

  const destinationDefaultLanguage = appState.selectedDestination.languages[0];

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: 50 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '40', value: 40 },
  ];

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  })

  
  useEffect(() => {
    if(isFetching) {
      const timer = setTimeout(() => {
        dispatch(LoadAttractionsListThunk({
          destinationId: appState.selectedDestination.id,
          pageNumber: localpageNumber,
          pageSize: attractionsState.pageSize,
          status: formData.statusValue,
          search: formData.searchValue,
          category: formData.categoryValue.id,
          region: formData.regionValue.id,
          area: formData.areaValue.id,
          location: formData.locationValue.id,
          sort: formData.sortField && formData.sortStatus ? `${formData.sortField}${formData.sortStatus}` : '',
        })).then(() => {
          dispatch(setPageNumberLazy(localpageNumber + 1))
          setIsFetching(false);
        });
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [isFetching, isAllPagination])

  const scrollHandler = (e) => {
    const [ fullHeigth, heigthFromTop, windowHeight ] =  [
      e.target.documentElement.scrollHeight, 
      e.target.documentElement.scrollTop, 
      window.innerHeight
    ]
    if(
      fullHeigth - (heigthFromTop + windowHeight) < 800 && 
       attractionsState.pageSize == 50 && 
       attractionsState.attractionsListData.length < attractionsState.totalCount &&
       isAllPagination
      ) {
      setIsFetching(true);
    }
  }

  const isRegionEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_REGION);
  const isAreaEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_AREA);
  const isLocationEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_LOCATION);

  const dictionaryCategoryData = Object.assign(
    {},
    ...categoryState.categoryData.map((item) => ({
      [item.id]: item.name[destinationDefaultLanguage],
    }))
  );

  const dictionaryRegionData = Object.assign(
    {},
    ...settingsState.dictionaryRegionData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryAreaData = Object.assign(
    {},
    ...settingsState.dictionaryAreaData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryLocationData = Object.assign(
    {},
    ...settingsState.dictionaryLocationData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryCompanyData = Object.assign(
    {},
    ...companyState.companiesData.map((item) => ({
      [item.id]: item.name,
    }))
  );

  const createData = (
    status,
    name,
    title,
    categories,
    region,
    regionOrder,
    area,
    areaOrder,
    location,
    locationOrder,
    company,
    id
  ) => {
    return {
      status,
      name,
      title,
      categories,
      region,
      regionOrder,
      area,
      areaOrder,
      location,
      locationOrder,
      company,
      id,
    };
  };

  useEffect(() => {
    setIsLoading(true);
    const loadedRows = attractionsState.attractionsListData.map((item) =>
    createData(
      item.status,
      item.name,
      item.title[destinationDefaultLanguage] || 'No title',
      dictionaryCategoryData[item.category] || 'No category',
      dictionaryRegionData[item.regionId] || 'No region',
      item.regionOrder || 0,
      dictionaryAreaData[item.areaId] || 'No area',
      item.areaOrder || 0,
      dictionaryLocationData[item.locationId] || 'No location',
      item.locationOrder || 0,
      dictionaryCompanyData[item.companyId] || 'No company',
      item.id
    ));

    setCreateRows(loadedRows);
    setIsLoading(false);

  }, [attractionsState.attractionsListData]);

  const handleChangePageNumber = (_, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    if(newSize == 50) {
      dispatch(setPageSize(50));
      dispatch(setPageNumber(0))
      setIsAllPagination(true);
    }
    else {
      dispatch(setPageSize(newSize));
      setIsAllPagination(false);
      dispatch(setPageNumberLazy(1));
    }

    setIsFetching(false);

  };

  return (
    <Box sx={{ width: '100%' }}>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={handleChangePageSize}
        component="div"
        count={attractionsState.totalCount}
        page={pageNumber}
        onPageChange={handleChangePageNumber}
        rowsPerPage={pageSize}
        labelDisplayedRows={({ from, to, count }) => 
        isAllPagination 
          ? `${attractionsState.totalCount}-${attractionsState.totalCount} of ${attractionsState.totalCount}`
          : `${from}-${to} of ${count}`}
        labelRowsPerPage={t('attractions.deals.rows_per_page')}
        nextIconButtonProps={{
          style: isAllPagination ? { display: 'none' } : {}
        }}
        backIconButtonProps={{
          style: isAllPagination ? { display: 'none' } : {}
        }}
      />
      {
        isLoading
        ? <Box
            component="div"
            sx={{ width: '100%', position: 'relative'}}
          >
            <CircularProgress
              sx={{ position: 'absolute', right: '50%', top: '200px' }}
            />
          </Box>
        : 
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <AttractionTableHeader
                onSort={sortBy}
                value={sortField}
                status={sortStatus}
                showRegionColumn={isRegionEnabledInDestination}
                showAreaColumn={isAreaEnabledInDestination}
                showLocationColumn={isLocationEnabledInDestination}
              />
              {createRows?.map((item, index) => (
                <AttractionTableDataRow
                  key={index}
                  onClickRow={handleAttractionClick}
                  attractionRow={item}
                  showRegionColumn={isRegionEnabledInDestination}
                  showAreaColumn={isAreaEnabledInDestination}
                  showLocationColumn={isLocationEnabledInDestination}
                />
              ))}
            </Table>
          </TableContainer>
            {isFetching && 
              <Box display="flex" marginTop="50px" justifyContent="center">
                <CircularProgress />
              </Box>
            }
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            onRowsPerPageChange={handleChangePageSize}
            component="div"
            count={attractionsState.totalCount}
            page={pageNumber}
            onPageChange={handleChangePageNumber}
            rowsPerPage={pageSize}
            labelDisplayedRows={({ from, to, count }) => 
            isAllPagination 
              ? `${attractionsState.totalCount}-${attractionsState.totalCount} of ${attractionsState.totalCount}`
              : `${from}-${to} of ${count}`}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            nextIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
            backIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
          />
        </>
    }
    </Box>
  );
};

export default AttractionsList;

