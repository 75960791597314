import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, reset } from 'redux-form';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Typography, Box } from '@mui/material';

import NotificationSearchForm from './forms/NotificationSearchForm';
import { NotificationsTable, NotificationRow } from './notificationTable';
import useAPIError from '../../../apIErrorProvider/useAPIError';

import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../helpers/translationObjectUtils';

import { NOTIFICATION_STATUS } from '../../../consts/consts';
import { getAttractionNotificationsListAfterTranslateThunk } from '../../../redux/attractionsReducers/notificationReducer/notificationActions';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { getAttractionThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';

const NotificationTab = (props) => {
  const {
    getAttractionNotificationsListThunk,
    appReducer,
    notificationReducer,
    currentAttraction,
    availableAttractions,
    editAttractionNotificationThunk,
    getFilteredAttractionNotificationsThunk,
    deleteAttractionNotificationThunk,
  } = props;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [activeRowId, setActiveRowId] = useState(-1);

  const currentAttractionId = currentAttraction.id;
  const notificationsState = notificationReducer.notificationData;
  const globalSettings = appReducer.globalSettingsData;
  const selectedDestinationId = appReducer.selectedDestination.id;
  const isItemsLoaded = notificationReducer.isLoaded;
  const selectedLanguage = appReducer.selectedLanguage;

  const destinationLanguageList = appReducer.selectedDestination.languages;

  const mappedDestinationLanguages = destinationLanguageList?.map((lang) => {
    return {
      id: lang,
      value: lang,
    };
  });

  const statusOptions = globalSettings.filter(
    (item) => item.name.en === NOTIFICATION_STATUS
  );

  const shortAvailableAttractionsData = availableAttractions.map(
    (attraction) => {
      return { id: attraction.id, name: attraction.name };
    }
  );

  useEffect(() => {
    if (activeRowId === -1) {
      const filter = {
        attractionId: currentAttraction.id,
        status: status ? status : null,
        startDate: startDate
          ? new Date(startDate).toISOString()
          : null,
        endDate: endDate
          ? new Date(endDate).toISOString()
          : null,
      }; 
      getFilteredAttractionNotificationsThunk(filter);
    }
  }, [activeRowId]);

  const handleExpandTableRow = (panelId) => {
    return () => {
      setActiveRowId((currentValue) =>
        currentValue !== panelId ? panelId : -1
      );
    };
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const handleAddNotification = () => {
    navigate('new-notification');
  };

  const handleSubmitNotificationForm = (values) => {
    setShowSaveAnimation(true);

    let startDate, endDate, startDateEvent,endDateEvent;

    if (typeof values.startDate.getMonth === 'function') {
      startDate = new Date(values.startDate).toISOString();
      startDateEvent = new Date(values.startDateEvent).toISOString();
    } else {
      startDate = values.startDate;
      startDateEvent = values.startDateEvent;
    }

    if (typeof values.endDate.getMonth === 'function') {
      endDate = new Date(values.endDate).toISOString();
      endDateEvent = new Date(values.endDateEvent).toISOString();
    } else {
      endDate = values.endDate;
      endDateEvent = values.endDateEvent;
    }

    const updatedNotification = {
      destinationId: selectedDestinationId,
      attractionId: currentAttractionId,
      notificationId: values.id,
      title: removeKeysWithEmptyValueArrays(values.title),
      text: removeTextEditorValueKeysWithNoPlainText(values.text),
      status: values.status,
      startDate: startDate,
      endDate: endDate,
      startDateEvent: startDateEvent ? startDateEvent : null,
      endDateEvent: endDateEvent ? endDateEvent : null,
      attractions: [...values.attractions],
      isGeneralNotification: values.isGeneralNotification,
      isVisibleForGeneralSection: values.isVisibleForGeneralSection,
    };

    editAttractionNotificationThunk(updatedNotification)
      .then(() => {
        getAttractionNotificationsListThunk(currentAttractionId);
        setShowSaveAnimation(false);
        handleResetFilters();
        resetActiveRow();
        addError(`${t('notifications.success_edit_notification')}`, 'Success');
      })
      .catch((error) => {
        addError(
          `${t('notifications.error_edit_notification')}`,
          'Error',
          error
        );

        setShowSaveAnimation(false);
      });
  };

  const handleSubmitNotificationSearchForm = (formData) => {
    const filter = {
      attractionId: currentAttraction.id,
      status: formData.status ? formData.status : null,
      startDate: formData.startDate
        ? new Date(formData.startDate).toISOString()
        : null,
      endDate: formData.endDate
        ? new Date(formData.endDate).toISOString()
        : null,
    };
    getFilteredAttractionNotificationsThunk(filter);
  };

  const handleDeleteNotification = (notificationId) => {
    setShowSaveAnimation(true);
    deleteAttractionNotificationThunk(currentAttractionId, notificationId)
      .then(() => {
        handleResetFilters();
        getAttractionNotificationsListThunk(currentAttractionId);
        setShowSaveAnimation(false);
        addError(
          `${t('notifications.success_delete_notification')}`,
          'Success'
        );
        resetActiveRow();
      })
      .catch((error) => {
        addError(
          `${t('notifications.error_delete_notification')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleResetFilters = () => {
    setStatus('');
    setStartDate(null);
    setEndDate(null);

    const filter = {
      status: '',
      startDate: null,
      endDate: null,
    };

    dispatch(reset('NotificationSearchForm'));
    handleSubmitNotificationSearchForm(filter);
  };

  useEffect(() => {
    if (currentAttractionId)
      Promise.all([getAttractionNotificationsListThunk(currentAttractionId)]);
  }, [currentAttractionId, getAttractionNotificationsListThunk]);

  const appState = useSelector(
    (state) => state.appReducer
  );

  const settingsState = useSelector(
    (state) => state.settingsReducer
  );

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    if(currentAttractionId) {
      dispatch(setTasks(10))
    } else {
      dispatch(setTasks(9))
    }
  }, [currentAttractionId])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (currentAttractionId) {
          await dispatch(getAttractionThunk(currentAttractionId));
          dispatch(incrementCompletedTasks());
        }
  
        const filter = {
          attractionId: currentAttraction.id,
          status: status ? status : null,
          startDate: startDate
            ? new Date(startDate).toISOString()
            : null,
          endDate: endDate
            ? new Date(endDate).toISOString()
            : null,
        }; 
        await getFilteredAttractionNotificationsThunk(filter, false);
        dispatch(incrementCompletedTasks());
  
        await dispatch(getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getPredefinedSettingsThunk());
        dispatch(incrementCompletedTasks());

        await dispatch(getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getTagsThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <Box>
      <Box>
        <Box sx={{ marginBottom: '30px' }}>
          <Box padding="20px 0px 0px 0px" marginBottom="16px">
            <Typography
              fontWeight={500}
              fontSize="20px"
              textTransform="uppercase"
            >
              {t('notifications.list_of_notifications')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleAddNotification}
            sx={{ color: '#fff', width: '250px' }}
          >
            {t('notifications.add_new_notification')}
          </Button>
        </Box>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '70%',
            marginBottom: '30px',
          }}
        >
          <NotificationSearchForm
            availableStatuses={statusOptions}
            onSubmit={handleSubmitNotificationSearchForm}
            selectedLanguage={selectedLanguage}
            handleResetFilters={handleResetFilters}
            status={status}
            setStatus={setStatus}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        {isItemsLoaded ? (
          <NotificationsTable>
            {notificationsState.map((notificationRecord, idx) => (
              <NotificationRow
                item={notificationRecord}
                languages={mappedDestinationLanguages}
                onClick={handleExpandTableRow(idx)}
                currentAttraction={currentAttraction}
                isExpanded={activeRowId === idx}
                key={idx}
                statuses={statusOptions[0]}
                availableAttractions={shortAvailableAttractionsData}
                onSubmitNotificationForm={handleSubmitNotificationForm}
                onDeleteNotification={handleDeleteNotification}
                showSaveAnimation={showSaveAnimation}
                selectedLanguage={selectedLanguage}
              />
            ))}
          </NotificationsTable>
        ) : (
          <div
            style={{
              marginTop: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default NotificationTab;
