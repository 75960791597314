import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Box } from '@mui/material';

import PriceList from './PriceList';
import { PriceEditFormContainer } from './forms';

import {
  LoaderWrapper,
  PricesTabContainer,
  PriceAddButton,
  PricesTabHeader,
  PricesTabTitleWrapper,
} from './styled';
import { useNavigate } from 'react-router-dom';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';
import { getAttractionThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';

const Prices = (props) => {
  const {
    appState,
    priceState,
    settingsState,
    attractionsState,
    getPricesListThunk,
    getCurrentPriceThunk,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEditPriceForm, setShowEditPriceForm] = useState(false);

  const handlePriceAdd = () => {
    navigate('new-price');
  };

  useEffect(() => {
    getPricesListThunk(attractionsState.currentAttraction.id);
  }, [attractionsState.currentAttraction.id, getPricesListThunk]);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    var tasksCount = 8;

    if(attractionsState.currentAttraction.id) tasksCount = tasksCount + 2;
    if(attractionsState.currentAttraction.id && priceState.currentPrice.id) tasksCount++;
    
    dispatch(setTasks(tasksCount))
  }, [])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (attractionsState.currentAttraction.id) {
          await dispatch(getAttractionThunk(attractionsState.currentAttraction.id));
          dispatch(incrementCompletedTasks());

          await getPricesListThunk(attractionsState.currentAttraction.id);
          dispatch(incrementCompletedTasks());
        }
        await dispatch(getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getCategoryThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getPredefinedSettingsThunk());
        dispatch(incrementCompletedTasks());

        await dispatch(getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getTagsThunk(appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id));
        dispatch(incrementCompletedTasks());
  
        if(attractionsState.currentAttraction.id && priceState.currentPrice.id){
          await getCurrentPriceThunk(attractionsState.currentAttraction.id, priceState.currentPrice.id);
          dispatch(incrementCompletedTasks());
        }
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  if (!priceState.isLoadingList) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  return (
    <PricesTabContainer>
      {!showEditPriceForm && (
        <>
          <PricesTabHeader>
            <PricesTabTitleWrapper>
              {attractionsState.currentAttraction.name}
            </PricesTabTitleWrapper>
            <Box mt="30px" mb="10px">
              <PriceAddButton onClick={handlePriceAdd} variant="contained">
                {t('attractions.price.add_button')}
              </PriceAddButton>
            </Box>
          </PricesTabHeader>
          <PriceList
            priceState={priceState}
            setShowPriceField={setShowEditPriceForm}
            getCurrentPriceThunk={getCurrentPriceThunk}
          />
        </>
      )}
      {showEditPriceForm && (
        <PriceEditFormContainer
          attractionData={attractionsState.currentAttraction}
          currentVat={settingsState.destinationData.filter(
            (item) => item.id === appState.selectedDestination.id
          )}
          setShowPriceForm={setShowEditPriceForm}
        />
      )}
    </PricesTabContainer>
  );
};

export default Prices;
