import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import ContentForm from './ContentForm';
import useAPIError from '../../../apIErrorProvider/useAPIError';

import { LoaderWrapper } from './styled';

import {
  getUniqueImages,
  uploadImageFiles,
  getExistingImages,
} from './reduxImageDrop/utils';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';
import { removeKeysWithEmptyValueArrays } from '../../../helpers/translationObjectUtils';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';

const Content = (props) => {
  const {
    appState,
    settingsState,
    updateSalesPointThunk,
    getSalesPointThunk,
    salesPointsReducer,
  } = props;

  const { currentPoint } = salesPointsReducer;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const destination = settingsState.destinationData.filter(
    (item) => item.id === appState.selectedDestination.id
  );

  const resetFormValues = () => {
    dispatch(reset('Content'));
  };

  const onSubmitContentForm = async (formValues) => {
    setIsSave(true);
    setIsInEditMode(false);

    const uniqueAppImageFiles = getUniqueImages(formValues.appImages);
    const uniqueWebImageFiles = getUniqueImages(formValues.webImages);

    const existingAppImageFiles = getExistingImages(formValues.appImages);
    const existingWebImageFiles = getExistingImages(formValues.webImages);

    Promise.all([
      uploadImageFiles(uniqueAppImageFiles, uploadImageFile, 'App'),
      uploadImageFiles(uniqueWebImageFiles, uploadImageFile, 'Web'),
    ])
      .then((responses) => {
        const updatedAppImageUrlList = [
          ...existingAppImageFiles,
          ...responses[0].data,
        ];

        const updatedWebImageUrlList = [
          ...existingWebImageFiles,
          ...responses[1].data,
        ];

        const updatedPointData = {
          ...currentPoint,
          mapCoordinate: currentPoint.geoCoordinate,
          title: formValues.title,
          subtitle: formValues.subtitle,
          introduction: formValues.introduction,
          description: formValues.description,
          tips: removeKeysWithEmptyValueArrays(formValues.tips),
          directions: removeKeysWithEmptyValueArrays(formValues.directions),
          appImages: updatedAppImageUrlList,
          webImages: updatedWebImageUrlList,
        };

        return updateSalesPointThunk(updatedPointData.id, updatedPointData);
      })
      .then(() => {
        getSalesPointThunk(id);
        setIsSave(false);
        addError(`${t('attractions.content.error_edit_text')}`, 'Success');
      })
      .catch((e) => {
        addError(`${t('attractions.content.error_not_edit_text')}`, 'Error', e);
        resetFormValues();
        setIsSave(false);
      });
  };

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    if(id){
      dispatch(setTasks(9));
    }
    else {
      dispatch(setTasks(8));
    }
  }, [])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3));
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (id) {
          await getSalesPointThunk(id);
          dispatch(incrementCompletedTasks());
        }

        await getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        );
        dispatch(incrementCompletedTasks());

        await getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        );
        dispatch(incrementCompletedTasks());

        await getPredefinedSettingsThunk();
        dispatch(incrementCompletedTasks());

        await getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        );
        dispatch(incrementCompletedTasks());

        await getTagsThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        );
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <>
      {currentPoint?.id ? (
        <ContentForm
          onSubmit={onSubmitContentForm}
          currentPointData={currentPoint}
          isInEditMode={isInEditMode}
          setIsEditContent={setIsInEditMode}
          languages={destination}
          isSave={isSave}
        />
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </>
  );
};

export default Content;
