import {
  createAdvertContent,
  getAdvertContentList,
  updateAdvertContent,
  deleteAdvertContent,
} from '../../../api/publishing/advertContentApi';

export const SET_ADVERT_CONTENT_LIST = 'advertContent/setAdvertContentList';
export const SET_IS_LOADING = 'advertContent/setIsLoading';

export const setAdvertContentList = (data) => {
  return {
    type: SET_ADVERT_CONTENT_LIST,
    payload: data,
  };
};

export const setIsImageListLoading = (data) => {
  return {
    type: SET_IS_LOADING,
    payload: data,
  };
};

export const createAdvertContentThunk = (data) => async () => {
  const response = await createAdvertContent(data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const getAdvertContentListThunk =
  (destinationId, isLoading = true) => async (dispatch) => {
    isLoading && dispatch(setIsImageListLoading(true));

    const response = await getAdvertContentList(destinationId);

    if (response && response.status === 200) {
      dispatch(setAdvertContentList(response.data));
      isLoading && dispatch(setIsImageListLoading(false));
    }
  };

export const updateAdvertContentThunk = (itemId, data) => async () => {
  const response = await updateAdvertContent(itemId, data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const deleteAdvertContentThunk = (itemId) => async () => {
  const response = await deleteAdvertContent(itemId);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
