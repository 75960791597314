import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { AdvertContentAddButton, AdvertisementTabWrapper } from './styled';

import {
  AdvertContentTable,
  AdvertContentTableRow,
} from './advertContentTable';

import { getAdvertContentListThunk } from '../../../redux/publishingReducers/advertContentReducer/advertContentActions';
import { getCurrentDestinationThunk } from "../../../redux/settingsReducers/settingsReducer";

import { CONTENT_LANGUAGES } from '../../../consts/consts';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';


const AdvertisementTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeRowId, setActiveRowId] = useState(-1);

  const { contentList, isLoading } = useSelector(
    (state) => state.advertContentReducer
  );
  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );
  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );
  
  const languageList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === CONTENT_LANGUAGES
    )
  );

  const handleAddButtonClick = () => {
    navigate('new-advertisement');
  };

  const handleRowExpand = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    dispatch(setTasks(4))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh && selectedDestinationId) {
        await dispatch(getAdvertContentListThunk(selectedDestinationId, false));
        dispatch(incrementCompletedTasks());
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh]);
  

  useEffect(() => {
    if(!!selectedDestinationId)
      dispatch(getAdvertContentListThunk(selectedDestinationId))
  }, [selectedDestinationId]);

  return (
    <AdvertisementTabWrapper>
      <Box mt="10px" mb="10px">
        <AdvertContentAddButton
          variant="contained"
          onClick={handleAddButtonClick}
        >
          {t('advertWindow.add_new_topic')}
        </AdvertContentAddButton>
      </Box>
      {!isLoading && (
        <AdvertContentTable>
          {contentList.map((advertContentItem, i) => (
            <AdvertContentTableRow
              isExpanded={i === activeRowId}
              onExpand={() => handleRowExpand(i)}
              languageList={languageList}
              selectedDestinationId={selectedDestinationId}
              item={advertContentItem}
              resetActiveRow={resetActiveRow}
              selectedLanguage={selectedLanguage}
              key={i}
            />
          ))}
        </AdvertContentTable>
      )}
    </AdvertisementTabWrapper>
  );
};

export default AdvertisementTab;
