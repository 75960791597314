import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import SeasonsForm from './SeasonsForm';

import { SeasonFormWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { CONTENT_LANGUAGES } from '../../../consts/consts';
import {
  getSettingsSeasonsThunk,
  addSettingsSeasonsThunk,
} from '../../../redux/settingsReducers/seasonsReducer';
import { useNavigate } from 'react-router-dom';
import { SEASONS_PAGE_ROUTE } from './constants';

const SeasonAddFormContainer = (props) => {
  const {
    appReducer,
    seasonsState,
    globalSettings,
    selectedLanguage,
    addSettingsSeasonsThunk,
    getSettingsSeasonsThunk,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = (values) => {
    setShowSaveAnimation(true);

    const data = {
      destinationId: appReducer.selectedDestination.id,
      name: values,
    };

    addSettingsSeasonsThunk(data)
      .then(() => {
        getSettingsSeasonsThunk(appReducer.selectedDestination.id);
        setShowSaveAnimation(false);
        addError(`${t('settings_page.seasons.error_add_text')}`, 'Success');
        navigate(SEASONS_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.seasons.error_not_add_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(SEASONS_PAGE_ROUTE);
  };

  return (
    <SeasonFormWrapper>
      <SeasonsForm
        addSeason
        onSubmit={handleFormSubmit}
        seasonsState={seasonsState}
        isEdit={false}
        updateSeason={false}
        languages={globalSettings.filter(
          (item) => item.name.en === CONTENT_LANGUAGES
        )}
        selectedLanguage={selectedLanguage}
        isSave={showSaveAnimation}
        onCancel={handleFormCancel}
      />
    </SeasonFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    seasonsState: state.seasonsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  getSettingsSeasonsThunk,
  addSettingsSeasonsThunk,
})(SeasonAddFormContainer);
