import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemText, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { FacilityAddButton } from './styled';
import cl from './Facilities.module.css';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const useStyles = makeStyles({
  list: {
    width: '400px',
    '& .MuiListItem-root': {
      width: '400px',
      padding: '3px 2px',
      '&:hover': {
        padding: '3px 2px',
        borderRadius: '5px',
      },
      '&.Mui-selected': {
        borderRadius: '5px',
      },
    },
  },
});

const Facilities = (props) => {
  const { facilitiesState, selectedLanguage, getFacilitiesThunk, selectedDestinationId } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    selectedDestinationId && getFacilitiesThunk(selectedDestinationId);
  }, [getFacilitiesThunk, selectedDestinationId]);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    dispatch(setTasks(4))
  }, []);
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await getFacilitiesThunk(selectedDestinationId);
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  const onAddFacilities = () => {
    navigate('new-facility');
  };

  const handleListItemClick = (item, index) => {
    navigate(`view/${item.id}`);
  };

  if (!facilitiesState.isLoadingFacilitiesData) {
    return (
      <Box
        component="div"
        sx={{ position: 'absolute', top: '50%', right: '50%' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.leftBox}>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('settings_page.facility.headline')}
        </Typography>
        <Box mt="10px" mb="10px">
          <FacilityAddButton variant="contained" onClick={onAddFacilities}>
            {t('settings_page.facility.add_button')}
          </FacilityAddButton>
        </Box>
        <Box component="div" className={classes.list}>
          {!facilitiesState.facilitiesData.length ? (
            <div className={cl.absent}>
              {t('settings_page.facility.no_facility')}
            </div>
          ) : (
            <List>
              {facilitiesState.facilitiesData.map((item, index) => (
                <ListItem
                  button
                  key={item.id}
                  selected={facilitiesState.currentFacility.id === item.id}
                  onClick={() => handleListItemClick(item, index)}
                  sx={{
                    paddingLeft: '2px',
                    width: '100%',
                    borderBottom: '1px solid rgba(0,0,0, 0.1)',
                  }}
                >
                  <ListItemText
                    sx={{ wordWrap: 'break-word' }}
                    primary={
                      item.name[selectedLanguage] ||
                      item.name.en ||
                      'No en translation'
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </div>
      <div className={cl.rightBox}>
        <Outlet />
      </div>
    </div>
  );
};

export default Facilities;
