import { connect } from 'react-redux';
import { compose } from 'redux';
import Seasons from './Seasons';

import {
    getSettingsSeasonsThunk,
    getSettingsSearchSeasonsThunk,
    addSettingsSeasonsThunk,
    deleteSettingsSeasonsThunk,
    editSettingsSeasonsThunk,
    setCurrentSeason
} from '../../../redux/settingsReducers/seasonsReducer';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    seasonsReducer: state.seasonsReducer
  }
};

const SeasonsContainer = compose(
  connect(mapStateToProps,
  { 
    getSettingsSeasonsThunk,
    getSettingsSearchSeasonsThunk,
    addSettingsSeasonsThunk,
    deleteSettingsSeasonsThunk,
    editSettingsSeasonsThunk,
    setCurrentSeason,
  }),
)(Seasons);

export default SeasonsContainer;