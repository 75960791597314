import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircularProgress, Box, Typography } from '@mui/material';

import ReviewSearchForm from './forms/ReviewSearchForm';
import { ReviewRow, ReviewsTable } from './reviewsTable';

import {
  ReviewsTabWrapper,
  ReviewsFilterWrapper,
  LoaderWrapper,
  ReviewAddButton,
  ReviewStatisticWrapper,
} from './styled';

import { getAverageReviewRating } from './utils';
import { REVIEW_STATUS } from '../../../consts/consts';
import { REVIEW_RATING } from './constants';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { getAttractionThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';
import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';
import { useDispatch, useSelector } from 'react-redux';

const ReviewsTab = (props) => {
  const {
    getFilteredAttractionReviewsThunk,
    getAttractionReivewsListThunk,
    currentAttraction,
    reviewList,
    selectedLanguage,
    globalSettings,
    attractionList,
    getSmallAttractionsDataThunk,
    selectedDestinationId,
    isLoading,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [status, setStatus] = useState('');
  const [rating, setRating] = useState('');
  const [date, setDate] = useState(null);
  const [activeRowId, setActiveRowId] = useState(-1);

  const averageRating = getAverageReviewRating(reviewList).toFixed(1);

  const handleReviewAdd = () => {
    navigate('new-review');
  };

  const handleExpandTableRow = (panelId) => {
    return () => {
      setActiveRowId((currentValue) =>
        currentValue !== panelId ? panelId : -1
      );
    };
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const updateReviewList = () => {
    const filter = {
      attractionId: currentAttraction.id,
      status: status ? status : null,
      rating: rating ? parseInt(rating) : null,
      date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
    };
    getFilteredAttractionReviewsThunk(filter, selectedDestinationId);
  };

  const handleFilterReset = () => {
    setStatus('');
    setRating('');
    setDate(null);
  };

  useEffect(() => {
    const filter = {
      attractionId: currentAttraction.id,
      status: status ? status : null,
      rating: rating ? parseInt(rating) : null,
      date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
    };
    if (currentAttraction.id) getFilteredAttractionReviewsThunk(filter, selectedDestinationId);
  }, [
    status,
    rating,
    date,
    currentAttraction.id,
    getFilteredAttractionReviewsThunk,
  ]);

  useEffect(() => {
    getSmallAttractionsDataThunk(selectedDestinationId);
  }, [getSmallAttractionsDataThunk, selectedDestinationId]);

  const appState = useSelector(
    (state) => state.appReducer
  );

  const settingsState = useSelector(
    (state) => state.settingsReducer
  );

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    var tasksCount = 8;

    if(currentAttraction.id) tasksCount = tasksCount + 2;
    if(selectedDestinationId) tasksCount++;

    dispatch(setTasks(tasksCount));
  }, [])
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        if (currentAttraction.id) {
          await dispatch(getAttractionThunk(currentAttraction.id));
          dispatch(incrementCompletedTasks());
  
          const filter = {
            attractionId: currentAttraction.id,
            status: status ? status : null,
            rating: rating ? parseInt(rating) : null,
            date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
          };
  
          await getFilteredAttractionReviewsThunk(filter, selectedDestinationId, false);
          dispatch(incrementCompletedTasks());
        }
  
        if(selectedDestinationId){
          await getSmallAttractionsDataThunk(selectedDestinationId);
          dispatch(incrementCompletedTasks());
        }
  
        await dispatch(getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getPredefinedSettingsThunk());
        dispatch(incrementCompletedTasks());

        await dispatch(getFacilitiesThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());

        await dispatch(getTagsThunk(appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        ));
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <ReviewsTabWrapper>
      <Box>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('reviews.list_of_reviews')}
        </Typography>
        <ReviewStatisticWrapper>
          <Box>
            {t('reviews.average_rating')}: {averageRating}
          </Box>
          <Box>
            {t('reviews.number_of_reviews')}: {reviewList.length}
          </Box>
        </ReviewStatisticWrapper>
        <Box mt="10px" mb="10px">
          <ReviewAddButton variant="contained" onClick={handleReviewAdd}>
            {t('reviews.add_new_review')}
          </ReviewAddButton>
        </Box>
        <ReviewsFilterWrapper>
          <ReviewSearchForm
            status={status}
            setStatus={setStatus}
            rating={rating}
            setRating={setRating}
            date={date}
            setDate={setDate}
            availableStatuses={globalSettings.find(
              (item) => item.name.en === REVIEW_STATUS
            )}
            availableRating={REVIEW_RATING}
            selectedLanguage={selectedLanguage}
            onFilterReset={handleFilterReset}
          />
        </ReviewsFilterWrapper>
        {!isLoading ? (
          <ReviewsTable>
            {reviewList.map((reviewPost, idx) => (
              <ReviewRow
                item={reviewPost}
                key={idx}
                onClick={handleExpandTableRow(idx)}
                isExpanded={activeRowId === idx}
                resetActiveRow={resetActiveRow}
                attractionList={attractionList}
                updateReviewList={updateReviewList}
              />
            ))}
          </ReviewsTable>
        ) : (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </Box>
    </ReviewsTabWrapper>
  );
};

export default ReviewsTab;
