import { stopSubmit } from 'redux-form';
import {
    getCategoriesApi,
    getSearchCategoryApi,
    addCategoriesApi,
    deleteCategoryApi,
    editCategoryApi,
} from '../../api/settingsApi.js';
const SET_CATEGORY = 'SET_CATEGORY';
const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
const IS_LOADING_CATEGORY_DATA = 'IS_LOADING_CATEGORY_DATA';

const initialState = {
    categoryData: [],
    currentCategory: {},
    isLoadingCategoryData: false,
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY:
            return {
                ...state,
                categoryData: [...action.payload.data]
            };
        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: {...action.payload.data}
            };
        case IS_LOADING_CATEGORY_DATA:
            return {
                ...state,
                isLoadingCategoryData: action.isLoading
            };
        default:
            return state;
    }
};

let setCategory = (data) => {
    return { type: SET_CATEGORY, payload: { data } };
}

export let setCurrentCategory = (data) => {
    return { type: SET_CURRENT_CATEGORY, payload: { data } };
}

export let setLoadingCategoryData = (isLoading) => {
    return { type: IS_LOADING_CATEGORY_DATA, isLoading };
}


export let getCategoryThunk = (selectedDestinationId) => async (dispatch) => {
    let response = await getCategoriesApi(selectedDestinationId);
    if (response.status >= 200 && response.status < 300) {
        dispatch(setCategory(response.data));
        dispatch(setLoadingCategoryData(true));
    }
}

export let getSearchCategoryThunk = (status, destinationId) => async (dispatch) => {
    let response = await getSearchCategoryApi(status, destinationId);
    if (response.status >= 200 && response.status < 300) {
        dispatch(setCategory(response.data));
    }
}

export let addCategoryThunk = (data) => async (dispatch) => {
    const response =  await addCategoriesApi(data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Category", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let editCategoryThunk = (id, data) => async (dispatch) => {
    const response = await editCategoryApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Category", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let deleteCategoryThunk = (id) => async () => {
    let response = await deleteCategoryApi(id);
    if(response.status < 200 || response.status >= 300) {
        return Promise.reject(response.data.errors)
    }
}

export default categoryReducer;