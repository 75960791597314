import { connect } from 'react-redux';
import { compose } from 'redux';
import Tags from './Tags';

import {
    getTagsThunk,
    getSearchTagsThunk,
    addTagsThunk,
    deleteTagsThunk,
    editTagsThunk,
    setCurrentTag
} from '../../../redux/settingsReducers/tagsReducer';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    tagsReducer: state.tagsReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  }
};

const TagsContainer = compose(
  connect(mapStateToProps,
  { 
    getTagsThunk,
    getSearchTagsThunk,
    addTagsThunk,
    deleteTagsThunk,
    editTagsThunk,
    setCurrentTag,
  }),
)(Tags);

export default TagsContainer;