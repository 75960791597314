import { connect } from 'react-redux';
import { compose } from 'redux';
import Destination from './Destination';
import {
  getDestinationThunk,
  addDestinationThunk,
  deleteDestinationThunk,
  editDestinationThunk,
  addLocationThunk,
  editLocationThunk,
  deleteLocationThunk,
  setCurrentLocation,
  setCurrentDestination,
  setParentId,
} from '../../../redux/settingsReducers/settingsReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';

const mapStateToProps = (state) => {
  return {
    settingsReducer: state.settingsReducer,
    appReducer: state.appReducer,
    predefinedReducer: state.predefinedReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

const DestinationContainer = compose(
  connect(mapStateToProps, {
    getDestinationThunk,
    addDestinationThunk,
    deleteDestinationThunk,
    editDestinationThunk,
    addLocationThunk,
    editLocationThunk,
    deleteLocationThunk,
    getPredefinedSettingsThunk,
    setCurrentLocation,
    setCurrentDestination,
    setParentId,
  })
)(Destination);

export default DestinationContainer;
