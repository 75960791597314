import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { HelpTopicTabWrapper, LoaderWrapper } from './styled';

import { HelpTopicsTable, HelpTopicsTableRow } from './helpTopicTable';

import { getHelpTopicsListThunk } from '../../../redux/publishingReducers/helpTopicsReducer/helpTopicActions';

import { CONTENT_LANGUAGES } from '../../../consts/consts';
import {getCurrentDestinationThunk} from "../../../redux/settingsReducers/settingsReducer";
import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const HelpTopicsTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeRowId, setActiveRowId] = useState(-1);

  const { topicList, isLoading } = useSelector(
    (state) => state.helpTopicsReducer
  );
  
  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );


  const languageList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === CONTENT_LANGUAGES
    )
  );

  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const handleAddTopic = () => {
    navigate('new-topic');
  };

  const handleRowExpand = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const { isBaseTasksLoaded, needToRefresh } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    dispatch(setTasks(4))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh && selectedDestinationId) {
        await dispatch(getHelpTopicsListThunk(selectedDestinationId, false));
        dispatch(incrementCompletedTasks());
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh, selectedDestinationId]);
  

  useEffect(() => {
    if(!!selectedDestinationId) 
      dispatch(getHelpTopicsListThunk(selectedDestinationId));
  }, [selectedDestinationId]);

  return (
    <HelpTopicTabWrapper>
      <Box mb="10px">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('helpTopics.headline')}
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{
            color: '#ffffff',
            maxWidth: 'fit-content',
            marginBottom: '10px',
          }}
          onClick={handleAddTopic}
          variant="contained"
          disableRipple
        >
          {t('helpTopics.add_new_topic')}
        </Button>
      </Box>
      {!isLoading && (
        <HelpTopicsTable>
          {topicList.map((article, i) => (
            <HelpTopicsTableRow
              isExpanded={i === activeRowId}
              onClick={() => handleRowExpand(i)}
              languageList={languageList}
              item={article}
              resetActiveRow={resetActiveRow}
              selectedLanguage={selectedLanguage}
              key={i}
            />
          ))}
        </HelpTopicsTable>
      )}

      {isLoading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </HelpTopicTabWrapper>
  );
};

export default HelpTopicsTab;
