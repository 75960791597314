import * as axios from 'axios';
import authService from '../auth/AuthorizeService';
import { BASE_URL } from '../config.js';

// Questions FAQ

export const getQuestionsFaqListApi = async () => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/FaqQuestions`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSearchQuestionApi = async (status, categoryId, selectedDestinationId) => {
  const token = await authService.getAccessToken();

  let url = `${BASE_URL}/api/FaqQuestions/destinations/${selectedDestinationId}?`;
  if (status) {
    url += `status=${status}`;
  }
  if (categoryId) {
    url += `&category=${categoryId}`;
  }
  return await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addQuestionsFaqApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/api/FaqQuestions`, {
      destinationId: data.destinationId,
      ...data,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editQuestionsFaqApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/FaqQuestions/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteQuestionsFaqApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/FaqQuestions/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

// Category FAQ

export const getCategoryFaqListApi = async (selectedDestinationId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/FaqCategory/destinations/${selectedDestinationId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSearchCategoryApi = async (status, destinationId) => {
  const token = await authService.getAccessToken();
  let url = `${BASE_URL}/api/FaqCategory/destinations/${destinationId}?`;
  if (status) {
    url += `status=${status}`;
  }
  return await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addCategoryFaqApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/api/FaqCategory`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editCategoryFaqApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/FaqCategory/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteCategoryFaqApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/FaqCategory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

// icon (file)

export const sendIconApi = async (icon) => {
  const token = await authService.getAccessToken();
  const formData = new FormData();
  formData.append('files', icon);
  return await axios
    .post(`${BASE_URL}/api/files/images/upload?type=Icon`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
