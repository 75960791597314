import { connect } from 'react-redux';
import { compose } from 'redux';

import Facilities from './Facilities';

import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.appReducer.selectedLanguage,
    facilitiesState: state.facilitiesReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

const FacilitiesContainer = compose(
  connect(mapStateToProps, {
    getFacilitiesThunk,
  })
)(Facilities);

export default FacilitiesContainer;
