import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import { LogsPageContentContainer } from './styled';

import LogsFilterForm from './forms/LogsFilterForm';
import LogsTable from './table/LogsTable';

import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';
import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';
import { getUsersListThunk } from '../../redux/usersReducers/users/userActions';
import {
  getLogListThunk,
  setLogList,
} from '../../redux/logsReducer/logsActions';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../redux/refreshDataStateReducer/refreshDataStateReducer';
import { ATTRACTION_LOG_SECTIONS, ATTRACTION_LOG_TYPES } from './constants';
import { setTitle } from '../../redux/appReducer';

const LogsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { paginationSettings } = useSelector((state) => state.logsReducer);
  const { pageNumber, pageSize } = paginationSettings;

  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );
  const attractionOptionList = useSelector(
    (state) => state.attractionsReducer.attractionsSmallData
  );
  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );
  const userOptionList = useSelector((state) => state.userReducer.usersData);
  const { predefinedData } = useSelector((state) => state.predefinedReducer);

  const attractionLogSections = predefinedData.find(
    (item) => item.name.en === ATTRACTION_LOG_SECTIONS
  );

  const attractionLogTypes = predefinedData.find(
    (item) => item.name.en === ATTRACTION_LOG_TYPES
  );

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      from: '',
      to: '',
      attraction: null,
      section: '',
      type: '',
      user: null,
    },
  });

  const { watch } = formMethods;
  const fromValue = watch('from');
  const toValue = watch('to');
  const attractionValue = watch('attraction');
  const sectionValue = watch('section');
  const typeValue = watch('type');
  const userValue = watch('user');

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_logs')));

    if (selectedDestinationId && attractionValue) {
      dispatch(setTasks(7))
    } else if(selectedDestinationId || attractionValue ) {
      dispatch(setTasks(6))
    } else {
      dispatch(setTasks(5))
    }
  }, [attractionValue, selectedDestinationId])

  const { isBaseTasksLoaded, needToRefresh } = useSelector(
    (state) => state.refreshDataState
  );
  
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await dispatch(getUsersListThunk({})); 
        dispatch(incrementCompletedTasks());
  
        await dispatch(getPredefinedSettingsThunk()); 
        dispatch(incrementCompletedTasks());
  
        if (attractionValue) {
          fromValue && fromValue.setSeconds(0);
          toValue && toValue.setSeconds(59);
  
          const filterData = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            attractionId: attractionValue.id,
            from: fromValue && fromValue.toISOString(),
            to: toValue && toValue.toISOString(),
            section: sectionValue,
            type: typeValue,
            user: userValue?.email,
          };
  
          await dispatch(getLogListThunk(filterData)); 
          dispatch(incrementCompletedTasks());
        }
  
        if (selectedDestinationId) {
          await dispatch(getSmallAttractionsDataThunk(selectedDestinationId));
          dispatch(incrementCompletedTasks());
        }
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh]);
  

  useEffect(() => {
    if (selectedDestinationId)
      dispatch(getSmallAttractionsDataThunk(selectedDestinationId));
    dispatch(getUsersListThunk({}));
    dispatch(getPredefinedSettingsThunk());
  }, [selectedDestinationId, dispatch]);

  useEffect(() => {
    if (attractionValue) {
      fromValue && fromValue.setSeconds(0);
      toValue && toValue.setSeconds(59);

      const filterData = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        attractionId: attractionValue.id,
        from: fromValue && fromValue.toISOString(),
        to: toValue && toValue.toISOString(),
        section: sectionValue,
        type: typeValue,
        user: userValue?.email,
      };

      dispatch(getLogListThunk(filterData));
    }
  }, [
    dispatch,
    pageNumber,
    pageSize,
    attractionValue,
    fromValue,
    toValue,
    sectionValue,
    typeValue,
    userValue,
  ]);

  useEffect(() => {
    if (!attractionValue) dispatch(setLogList([]));
  }, [attractionValue, dispatch]);

  return (
    <LogsPageContentContainer>
      <Box mb="10px">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('logs.headline')}
        </Typography>
      </Box>
      <LogsFilterForm
        formMethods={formMethods}
        attractionOptions={attractionOptionList}
        userOptions={userOptionList}
        selectedLanguage={selectedLanguage}
        logSectionOptions={attractionLogSections}
        logTypeOptions={attractionLogTypes}
      />
      <LogsTable />
    </LogsPageContentContainer>
  );
};

export default LogsPage;
