import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getSearchQuestionThunk,
  addQuestionsThunk,
  editQuestionThunk,
  deleteQuestionThunk,
  setCurrentQuestion,
} from '../../../redux/faqReducers/faqQuestionReducer';

import QuestionsFaq from './QuestionsFaq';

const mapStateToProps = (state) => {
  return {
    faqQuestionReducer: state.faqQuestionReducer,
    appReducer: state.appReducer,
    categoryFaqReducer: state.categoryFaqReducer,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default compose(
  connect(mapStateToProps, {
    getSearchQuestionThunk,
    addQuestionsThunk,
    editQuestionThunk,
    deleteQuestionThunk,
    setCurrentQuestion,
  })
)(QuestionsFaq);
