import * as axios from 'axios';
import authService from '../auth/AuthorizeService';
import { BASE_URL } from '../config.js';

// Setup
export const getAttractionsListApi = async ({
  destinationId,
  pageNumber,
  pageSize,
  search,
  status,
  category,
  region,
  area,
  location,
  company,
  sort,
}) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(
      `${BASE_URL}/api/Attraction?destinationId=${destinationId}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&status=${status}&category=${category}&region=${region}&area=${area}&location=${location}&company=${company}&sort=${sort}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const getAttractionsApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/Attraction/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSmallAttractionsApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/Attraction/Search?destinationId=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getAttractionsSearchApi = async (
  destinationId,
  pageNumber,
  pageSize,
  attractions,
  status,
  category,
  region,
  area,
  location,
  company,
  sort
) => {
  const token = await authService.getAccessToken();
  let url = `${BASE_URL}/api/Attraction?destinationId=${destinationId}&pageNumber=${pageNumber}&pageSize=${pageSize}${
    attractions && `&search=${attractions}`
  }${status && `&status=${status}`}${category && `&category=${category}`}${
    region && `&region=${region}`
  }${area && `&area=${area}`}${location && `&location=${location}`}${
    sort && `&sort=${sort}`
  }${company && `&company=${company}`}
  }
    `;
  return await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addAttractionsApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/api/Attraction`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editAttractionSetupApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/Attraction/${id}/setup`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editAttractionContentApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/Attraction/${id}/content`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
export const editAttractionContentTranslateApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/Attraction/${id}/content-translation`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteAttractionApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/Attraction/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addAttractionPublicationApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/api/Attraction/${id}/request-publication`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

// Prices

export const getPricesListApi = async (attractionId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/attractions/${attractionId}/prices`;

  return await axios
    .get(targetUrl, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getCurrentPriceApi = async (attractionId, pricingId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/attractions/${attractionId}/prices/${pricingId}`;

  return await axios
    .get(targetUrl, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addPricesApi = async (attractionId, data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/attractions/${attractionId}/prices`;

  return await axios
    .post(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editPriceApi = async (attractionId, pricingId, data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/attractions/${attractionId}/prices/${pricingId}`;

  return await axios
    .put(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deletePriceApi = async (attractionId, pricingId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/attractions/${attractionId}/prices/${pricingId}`;

  return await axios
    .delete(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

//Notifications
export const getAttractionNotificationsListApi = async (attractionId) => {
  const token = await authService.getAccessToken();
  const url = `${BASE_URL}/${attractionId}/notifications`;
  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addAttractionNotificationApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/${data.attractionId}/notifications`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getFilteredAttractionNotificationsApi = async (filter) => {
  const token = await authService.getAccessToken();

  const targetUrl = new URL(`${BASE_URL}/${filter.attractionId}/notifications`);

  filter.status && targetUrl.searchParams.append('status', filter.status);
  filter.startDate &&
    targetUrl.searchParams.append('startDate', filter.startDate);
  filter.endDate && targetUrl.searchParams.append('endDate', filter.endDate);

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editAttractionNotificationApi = async (data) => {
  const token = await authService.getAccessToken();

  return await axios
    .put(
      `${BASE_URL}/${data.attractionId}/notifications/${data.notificationId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const editAttractionNotificationTranslationApi = async (data) => {
  const token = await authService.getAccessToken();

  return await axios
    .put(
      `${BASE_URL}/${data.attractionId}/notifications-translation/${data.notificationId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const deleteAttractionNotificationApi = async (
  attractionId,
  notificationId
) => {
  const token = await authService.getAccessToken();

  return await axios
    .delete(`${BASE_URL}/${attractionId}/notifications/${notificationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
// Availability

export const getSeasonsListApi = async (attractionId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/attractions/${attractionId}/seasons`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getCurrentSeasonApi = async (attractionId, pricingId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/attractions/${attractionId}/pricings/${pricingId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addSeasonApi = async (attractionId, data) => {
  const token = await authService.getAccessToken();

  return await axios
    .post(`${BASE_URL}/api/attractions/${attractionId}/seasons`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editSeasonApi = async (seasonId, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/seasons/${seasonId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteSeasonApi = async (seasonId) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/seasons/${seasonId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

// image (file)

export const sendIconApi = async (data, type) => {
  const token = await authService.getAccessToken();

  let url = `${BASE_URL}/api/files/images/upload`;
  if (type === 'App') {
    url += `?type=${type}`;
  }
  if (type === 'Web') {
    url += `?type=${type}`;
  }

  return await axios
    .post(url, data, {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

//Reviews

export const getAttractionReivewsListApi = async (attractionId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/Reviews?attractionId=${attractionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getFilteredAttractionReviewsApi = async (filter, selectedDestinationId) => {
  const token = await authService.getAccessToken();
  let url = `${BASE_URL}/api/Reviews/destinations/${selectedDestinationId}`;
  let queryParameters = [];

  if (filter.attractionId) {
    queryParameters.push(`attractionId=${filter.attractionId}`);
  }
  if (filter.status) {
    queryParameters.push(`status=${filter.status}`);
  }
  if (filter.date) {
    queryParameters.push(`date=${filter.date}`);
  }
  if (filter.rating) {
    queryParameters.push(`rating=${filter.rating}`);
  }
  if (filter.length !== 0) url += '?' + queryParameters.join('&');

  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addAttractionReviewApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/api/Reviews`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const editAttractionReviewApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/Reviews/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteAttractionReviewApi = async (reviewId) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/Reviews/${reviewId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
