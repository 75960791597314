import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, FormSection, change, reduxForm, reset } from 'redux-form';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import { AddBox, Clear, HighlightOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  IconButton,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import cl from './AvailabilityForm.module.css';

import {
  CheckboxInput,
  DatePickerInput,
  Input,
  Select,
} from './FormComponents';
import SeasonNameLanguageTabs from './SeasonNameLanguageTabs';
import useAPIError from '../../../apIErrorProvider/useAPIError';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';

import { validateTextFieldValue } from '../../../helpers/validateTextFieldValue';
import { deleteSeasonThunk } from '../../../redux/attractionsReducers/availabilityReducer';
import { useNavigationPrompt } from '../../../hooks/navigation';
import { onlyNumber } from './validate';

import {
  ALL_WEEK,
  CLOSED_ON_WEEK_DAYS_OPTIONS,
  TYPE_ALL_WEEK,
  TYPE_OPENING_HOURS,
  TYPE_OPTION_LIST,
  TYPE_STARTING_TIMES,
  WEEK_DAYS,
  MANAGER_ROLE_LIST,
} from './constants';

const useStyles = makeStyles({
  form: {
    width: '100%',
    marginTop: '30px',
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      borderRadius: '4.5px',
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '4.5px',
      '&::placeholder': {
        textFillColor: 'rgba(0, 0, 0, 0.9)',
      },
      '&.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .MuiInput-input': {
      '&::placeholder': {
        textFillColor: 'rgba(0, 0, 0, 0.9)',
      },
      '&.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      marginLeft: '0px',
    },
  },
  formSection: {
    display: 'flex',
  },
  nameInput: {
    '& .MuiOutlinedInput-root': {
      width: '500px',
    },
  },
});

const SeasonForm = (props) => {
  const {
    dirty,
    submitSucceeded,
    setSelectValue,
    setTimeArray,
    timeArray,
    selectValue,
    handleCurrentSeasonTimeArray,
    currentSeason,
    initialValues,
    languages,
    handleSubmit,
    isSave,
    handleOpenModal,
    handleOpenHoursModal,
    handleSpecialDateOpenModal,
    allWeekValue,
    setAllWeekValue,
    destinationId
  } = props;

  const { t } = useTranslation();
  const { id, seasonId } = useParams();
  const { addError } = useAPIError();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const blockNavigation = dirty && !submitSucceeded;
  useNavigationPrompt(blockNavigation);

  const [showLastEntryField, setShowLastEntryField] = useState(false);
  const [showTemporaryField, setTemporaryField] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const required = useCallback(values => !values ? t('attractions.season.error_required') : undefined, []);

  const attractionName = useSelector(
    (state) => state.attractionsReducer.currentAttraction.name
  );

  const typeValue = TYPE_OPTION_LIST.find(
    (option) => option.id === selectValue
  );

  const handleCloseConfirmationModal = () => {
    setShowAlert(false);
  };

  const onSelectedValue = (event) => {
    setSelectValue(event);
    setTimeArray({
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      allWeeks: [],
      isClosedOn: '',
      startingTimesSpecialDates: [],
      openingHoursSpecialDates: [],
    });
    setAllWeekValue(null);
  };

  const onChangeEntryValue = () => {
    setShowLastEntryField(!showLastEntryField);
    dispatch(change('AddNewSeasonForm', 'isLastEntry', null));
  };

  const onChangeTemporaryValue = () => {
    setTemporaryField(!showTemporaryField);
    dispatch(change('AddNewSeasonForm', 'isTemporaryClosed.validFrom', null));
    dispatch(change('AddNewSeasonForm', 'isTemporaryClosed.validTo', null));
  };

  const handleClosed = (day) => {
    if (WEEK_DAYS.includes(day)) {
      setTimeArray({ ...timeArray, [day]: [] });
    }

    if (day === ALL_WEEK) {
      setTimeArray({
        ...timeArray,
        allWeeks: [],
        isClosedOn: '',
      });
    }
  };

  const handleCopy = (day) => {
    const selectedDayData = timeArray[day];

    const newTimeArray = WEEK_DAYS.reduce((acc, weekDay) => {
      acc[weekDay] = [...selectedDayData];

      return acc;
    }, {});

    setTimeArray({
      ...timeArray,
      ...newTimeArray,
    });
  };

  const onDeleteTime = (index, day) => {
    const updatedWeekDay = timeArray[day]
      .slice(0, index)
      .concat(timeArray[day].slice(index + 1, timeArray[day].length));
    setTimeArray({ ...timeArray, [day]: updatedWeekDay });
  };

  const handleDeleteSpecialDate = (index) => {
    const isOpeningHours = selectValue === TYPE_OPENING_HOURS || selectValue === TYPE_ALL_WEEK;
  
    if (isOpeningHours) {
      const sortDate = timeArray.openingHoursSpecialDates.filter((_, i) => i !== index);
      setTimeArray({
        ...timeArray,
        openingHoursSpecialDates: sortDate,
      });
    } else {
      const sortDate = timeArray.startingTimesSpecialDates.filter((_, i) => i !== index);
      setTimeArray({
        ...timeArray,
        startingTimesSpecialDates: sortDate,
      });
    }
  };

  const onCancel = () => {
    if (seasonId) {
      setIsEdit(false);
      dispatch(reset('AddNewSeasonForm'));
      handleCurrentSeasonTimeArray();
      setTemporaryField(
        currentSeason.isTemporaryClosed !== null ? true : false
      );
      setShowLastEntryField(currentSeason.isLastEntry !== 0 ? true : false);
    } else {
      navigate(isManagerRoleSelected ? `/atractions/${id}/availability` : `/attractions/${id}/availability`);
    }
  };

  const handleDeleteSeason = () => {
    dispatch(deleteSeasonThunk(seasonId))
      .then(() => {
        addError(`${t('attractions.season.error_deleted_text')}`, 'Success');
        navigate(isManagerRoleSelected ? `/atractions/${id}/availability` : `/attractions/${id}/availability`);
      })
      .catch((error) => {
        addError(
          `${t('attractions.season.error_not_deleted_text')}`,
          'Error',
          error
        );
        setShowAlert(false);
      });
  };

  const isInEditMode = seasonId ? isEdit : true;

  const allWeekOptionClosedOnValueTranslation =
    selectValue === TYPE_ALL_WEEK &&
    CLOSED_ON_WEEK_DAYS_OPTIONS.find(
      (day) => day.value === allWeekValue?.closedOn
    );

  useEffect(() => {
    setTemporaryField(initialValues.isTemporaryClosedCheckbox);
    setShowLastEntryField(initialValues.isLastEntry !== 0 ? true : false);
  }, [initialValues.isLastEntry, initialValues.isTemporaryClosedCheckbox]);

  return (
    <>
      {languages ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={cl.wrapper}>
            <Box
              sx={{
                fontSize: '20px',
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '800px',
              }}
            >
              {attractionName}
            </Box>
            <div className={cl.buttons}>
              <Button
                variant="outlined"
                onClick={onCancel}
                type="button"
                disabled={!isInEditMode}
              >
                {t('attractions.season.cancel_button')}
              </Button>

              {seasonId && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => setIsEdit(true)}
                    type="button"
                    disabled={isInEditMode}
                  >
                    {t('attractions.season.edit_button')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setShowAlert(true)}
                    type="button"
                  >
                    {t('attractions.season.delete_button')}
                  </Button>
                </>
              )}

              <LoadingButton
                loading={isSave}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{ color: '#fff' }}
                type="submit"
                disabled={!isInEditMode}
              >
                {t('attractions.season.save_button')}
              </LoadingButton>
            </div>
          </div>
          <div className={cl.subtitle}>{t('attractions.season.seasons')}</div>
          <div className={cl.box}>
            <SeasonNameLanguageTabs
              isEdit={isInEditMode}
              languages={languages}
              currentItem={currentSeason}
              destinationId={destinationId}
            />
          </div>
          <div className={cl.box}>
            <div className={cl.description}>
              {t('attractions.season.valid')}:
            </div>
            <FormSection name="period">
              <div className={cl.valid}>
                <Field
                  name="validFrom"
                  component={DatePickerInput}
                  periodViews={['day']}
                  formatViews="dd/MM/yyyy"
                  validate={[required]}
                  disabled={!isInEditMode}
                />
              </div>
              <div className={cl.valid}>
                <Field
                  name="validTo"
                  component={DatePickerInput}
                  periodViews={['day']}
                  formatViews="dd/MM/yyyy"
                  validate={[required]}
                  disabled={!isInEditMode}
                />
              </div>
            </FormSection>
          </div>
          <div className={cl.box}>
            <div className={cl.description}>
              {t('attractions.season.type')}:
            </div>
            <div className={cl.typeBox}>
              <Field
                name="type"
                component={Select}
                data={TYPE_OPTION_LIST}
                onChange={(event) => onSelectedValue(event)}
                disabled={!isInEditMode}
              />
            </div>
          </div>
          <div className={cl.subtitle}>{t(typeValue?.name)}</div>

          {selectValue === TYPE_OPENING_HOURS ||
          selectValue === TYPE_STARTING_TIMES ? (
            <div className={cl.timeContainer}>
              {WEEK_DAYS.map((weekDay) => (
                <div className={cl.row} key={weekDay}>
                  <div className={cl.day}>
                    {t(`attractions.season.${weekDay}`)}
                  </div>
                  <div className={cl.closeButtons}>
                    <IconButton
                      size="normal"
                      sx={{ marginRight: '5px' }}
                      onClick={() => {
                        if (selectValue === TYPE_STARTING_TIMES) {
                          handleOpenModal(weekDay);
                        }
                        if (selectValue === TYPE_OPENING_HOURS) {
                          handleOpenHoursModal(weekDay);
                        }
                      }}
                      disabled={!isInEditMode}
                    >
                      <AddBox fontSize="inherit" />
                    </IconButton>
                    {!!timeArray[weekDay].length && (
                      <Box sx={{ display: 'flex' }}>
                        <IconButton
                          size="normal"
                          sx={{ marginRight: '10px' }}
                          onClick={() => handleClosed(weekDay)}
                          disabled={!isInEditMode}
                        >
                          <HighlightOff fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="normal"
                          sx={{ marginRight: '10px' }}
                          onClick={() => handleCopy(weekDay)}
                          disabled={!isInEditMode}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                  {!timeArray[weekDay].length ? (
                    <div
                      className={
                        seasonId
                          ? isEdit
                            ? cl.closedBox
                            : cl.closedBoxDisabled
                          : cl.closedBox
                      }
                    >
                      {t('attractions.season.closed')}
                    </div>
                  ) : (
                    timeArray[weekDay].map((item, index) => (
                      <div
                        key={item.validFrom}
                        className={
                          seasonId
                            ? isEdit
                              ? cl.timeBox
                              : cl.timeBoxDisabled
                            : cl.timeBox
                        }
                        onClick={() => {
                          if (selectValue === TYPE_STARTING_TIMES) {
                            handleOpenModal(weekDay, item, index);
                          }
                          if (selectValue === TYPE_OPENING_HOURS) {
                            handleOpenHoursModal(weekDay, item, index);
                          }
                        }}
                      >
                        {selectValue === TYPE_OPENING_HOURS ? (
                          <>
                            <div
                              className={
                                seasonId
                                  ? isEdit
                                    ? cl.timeItem
                                    : cl.timeItemDisabled
                                  : cl.timeItem
                              }
                            >
                              {item.validFrom}
                            </div>
                            <span className={cl.dash}>-</span>
                            <div
                              className={
                                seasonId
                                  ? isEdit
                                    ? cl.timeItem
                                    : cl.timeItemDisabled
                                  : cl.timeItem
                              }
                            >
                              {item.validTo}
                            </div>
                            <Clear
                              className={cl.clearIcon}
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeleteTime(index, weekDay);
                              }}
                              sx={{ width: '15px', height: '15px' }}
                            />
                          </>
                        ) : (
                          <>
                            <div
                              className={
                                seasonId
                                  ? isEdit
                                    ? cl.timeItem
                                    : cl.timeItemDisabled
                                  : cl.timeItem
                              }
                            >
                              {item}
                            </div>
                            <Clear
                              className={cl.clearIcon}
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeleteTime(index, weekDay);
                              }}
                              sx={{ width: '15px', height: '15px' }}
                            />
                          </>
                        )}
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
          ) : null}
          {selectValue === TYPE_ALL_WEEK && (
            <div className={cl.timeContainer}>
              <div className={cl.row}>
                <div className={cl.day}>{t('attractions.season.all_Week')}</div>
                <div className={cl.closeButtonsWithoutCopy}>
                  <IconButton
                    size="normal"
                    sx={{ marginRight: '5px' }}
                    onClick={() => handleOpenModal(ALL_WEEK)}
                    disabled={isInEditMode ? !!allWeekValue : true}
                  >
                    <AddBox fontSize="inherit" />
                  </IconButton>
                  {allWeekValue && (
                    <IconButton
                      size="normal"
                      sx={{ marginRight: '10px' }}
                      onClick={() => setAllWeekValue(null)}
                      disabled={!isInEditMode}
                    >
                      <HighlightOff fontSize="inherit" />
                    </IconButton>
                  )}
                </div>
                {allWeekValue ? (
                  <div
                    className={cl.timeBox}
                    onClick={() => {
                      if ((seasonId && isEdit) || (!seasonId && !isEdit))
                        handleOpenModal(ALL_WEEK);
                    }}
                  >
                    <div
                      className={
                        isInEditMode ? cl.timeItem : cl.timeItemDisabled
                      }
                    >
                      {allWeekValue.timeInterval.validFrom}
                    </div>
                    <span className={cl.dash}>-</span>
                    <div
                      className={
                        isInEditMode ? cl.timeItem : cl.timeItemDisabled
                      }
                    >
                      {allWeekValue.timeInterval.validTo}
                    </div>
                    <div className={cl.closedOn}>
                      {t('attractions.season.closed_on')}
                    </div>
                    <div className={cl.closedBox}>
                      {t(allWeekOptionClosedOnValueTranslation?.label) ||
                        t('attractions.season.none')}
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      isInEditMode ? cl.closedBox : cl.closedBoxDisabled
                    }
                  >
                    {t('attractions.season.closed')}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={cl.subtitle}>{t('attractions.season.options')}</div>
          <div className={cl.optionsContainer}>
            <FormGroup sx={{ display: 'flex' }}>
              {(selectValue === TYPE_OPENING_HOURS || selectValue === TYPE_ALL_WEEK) && (
                <div className={cl.groupBox}>
                  <Field
                    name="isLastEntryCheckbox"
                    component={CheckboxInput}
                    value="last_entry"
                    label={t('attractions.season.last_entry')}
                    onChange={onChangeEntryValue}
                    disabled={!isInEditMode}
                    validate={[onlyNumber]}
                  />
                  {showLastEntryField && (
                    <div className={cl.lastEntryBox}>
                      <Field
                        name="isLastEntry"
                        component={Input}
                        text="min"
                        validate={[onlyNumber]}
                        parse={(value) => validateTextFieldValue(value)}
                        disabled={!isInEditMode}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className={cl.groupBox}>
                <FormGroup row>
                  <Field
                    name="isTemporaryClosedCheckbox"
                    component={CheckboxInput}
                    value="temporary_closed"
                    label={t('attractions.season.temporary_closed')}
                    onChange={onChangeTemporaryValue}
                    disabled={!isInEditMode}
                  />
                </FormGroup>
                {showTemporaryField && (
                  <FormSection name="isTemporaryClosed">
                    <div className={cl.temporaryClosedBox}>
                      <div className={cl.temporaryClosed}>
                        <Field
                          name="validFrom"
                          component={DatePickerInput}
                          periodViews={['day']}
                          formatViews="dd/MM/yyyy"
                          required
                          validate={[required]}
                          disabled={!isInEditMode}
                        />
                      </div>
                      <div className={cl.temporaryClosed}>
                        <Field
                          name="validTo"
                          component={DatePickerInput}
                          periodViews={['day']}
                          formatViews="dd/MM/yyyy"
                          validate={[required]}
                          disabled={!isInEditMode}
                        />
                      </div>
                    </div>
                  </FormSection>
                )}
              </div>
            </FormGroup>
            
              <div className={cl.specialBox}>
                <div className={cl.description}>
                  {t('attractions.season.add_special_date')}
                </div>
                <IconButton
                  size="normal"
                  sx={{ margin: '0 15px 0 7px' }}
                  onClick={() => handleSpecialDateOpenModal()}
                  disabled={!isInEditMode}
                >
                  <AddBox fontSize="inherit" />
                </IconButton>
                {(selectValue === TYPE_OPENING_HOURS || selectValue === TYPE_ALL_WEEK) && (
                  <FormSection name="specialDates">
                    <div className={cl.optionsBox}>
                      {timeArray.openingHoursSpecialDates.map((item, index) => (
                        <div
                          className={cl.specialDateBox}
                          key={index}
                          onClick={() =>
                            handleSpecialDateOpenModal(item, index)
                          }
                        >
                          <IconButton
                            size="normal"
                            sx={{ marginRight: '10px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteSpecialDate(index);
                            }}
                            disabled={!isInEditMode}
                          >
                            <HighlightOff fontSize="inherit" />
                          </IconButton>
                          <TextField
                            size="small"
                            sx={{ width: '180px', marginRight: '10px' }}
                            placeholder={format(
                              new Date(Date.parse(item.date)),
                              'dd/MM/yyyy'
                            )}
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled={!isInEditMode}
                          />
                          {item.timeInterval === null ? (
                            <div className={cl.closedBox}>
                              {t('attractions.season.closed')}
                            </div>
                          ) : (
                            <>
                              <Box sx={{ marginRight: '5px' }}>From:</Box>
                              <TextField
                                size="small"
                                sx={{ width: '180px', marginRight: '10px' }}
                                placeholder={item.timeInterval.validFrom}
                                InputProps={{
                                  readOnly: true,
                                }}
                                disabled={!isInEditMode}
                              />
                              <Box sx={{ marginRight: '5px' }}>To:</Box>
                              <TextField
                                size="small"
                                sx={{ width: '180px', marginRight: '10px' }}
                                placeholder={item.timeInterval.validTo}
                                InputProps={{
                                  readOnly: true,
                                }}
                                disabled={!isInEditMode}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </FormSection>
                )}
                {selectValue === TYPE_STARTING_TIMES && (
                  <FormSection name="specialDates">
                    <div className={cl.optionsBox}>
                      {timeArray.startingTimesSpecialDates.map(
                        (item, index) => (
                          <div
                            className={cl.specialDateBox}
                            key={index}
                            onClick={() =>
                              handleSpecialDateOpenModal(item, index)
                            }
                          >
                            <IconButton
                              size="normal"
                              sx={{ marginRight: '10px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteSpecialDate(index);
                              }}
                              disabled={!isInEditMode}
                            >
                              <HighlightOff fontSize="inherit" />
                            </IconButton>
                            <TextField
                              size="small"
                              sx={{ width: '180px', marginRight: '10px' }}
                              disabled={!isInEditMode}
                              placeholder={format(
                                new Date(Date.parse(item.date)),
                                'dd/MM/yyyy'
                              )}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            {item.hours === null ? (
                              <div className={cl.closedBox}>
                                {t('attractions.season.closed')}
                              </div>
                            ) : (
                              <>
                                <Box sx={{ marginRight: '5px' }}>From:</Box>
                                <TextField
                                  size="small"
                                  disabled={!isInEditMode}
                                  sx={{ width: '180px', marginRight: '10px' }}
                                  placeholder={item.hours[0]}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </FormSection>
                )}
              </div>

            {/* {selectValue === TYPE_ALL_WEEK && (
              <div className={cl.timeContainer}>
                <div className={cl.row}>
                  <div className={cl.day}>
                    {t('attractions.season.all_Week')}
                  </div>
                  <div className={cl.closeButtons}>
                    <IconButton
                      size="normal"
                      sx={{ marginRight: '5px' }}
                      onClick={() => handleOpenModal(ALL_WEEK)}
                      disabled={
                        seasonId
                          ? isEdit
                            ? !!allWeekValue
                            : false
                          : !!allWeekValue
                      }
                    >
                      <AddBox fontSize="inherit" />
                    </IconButton>
                    {allWeekValue && (
                      <IconButton
                        size="normal"
                        sx={{ marginRight: '10px' }}
                        onClick={() => setAllWeekValue(null)}
                        disabled={seasonId ? !isEdit : false}
                      >
                        <HighlightOff fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>
                  {allWeekValue ? (
                    <div
                      //key={allWeekValue.timeInterval.validFrom}
                      className={cl.timeBox}
                    >
                      <div
                        className={
                          seasonId || isEdit ? cl.timeItem : cl.timeItemDisabled
                        }
                      >
                        {allWeekValue.timeInterval.validFrom}
                      </div>
                      <span className={cl.dash}>-</span>
                      <div
                        className={
                          seasonId || isEdit ? cl.timeItem : cl.timeItemDisabled
                        }
                      >
                        {allWeekValue.timeInterval.validTo}
                      </div>
                      <div className={cl.closedOn}>
                        {t('attractions.season.closed_on')}
                      </div>
                      <div className={cl.closedBox}>
                        {allWeekValue.closedOn}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        seasonId || isEdit ? cl.closedBox : cl.closedBoxDisabled
                      }
                    >
                      {t('attractions.season.closed')}
                    </div>
                  )}
                </div>
              </div>
            )} */}
          </div>
          <ConfirmationDialog
            showAlert={showAlert}
            handleClose={handleCloseConfirmationModal}
            handleCloseSuccess={handleDeleteSeason}
            text={t('attractions.season.delete_season')}
          />
        </form>
      ) : (
        <Box sx={{ position: 'absolute', top: '300px', right: '50%' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.currentSeason) {
    return {
      initialValues: {
        name: state.availabilityReducer.currentSeason.name
          ? state.availabilityReducer.currentSeason.name
          : null,
        period: {
          validFrom: state.availabilityReducer.currentSeason.period
            ? state.availabilityReducer.currentSeason.period.validFrom
            : null,
          validTo: state.availabilityReducer.currentSeason.period
            ? state.availabilityReducer.currentSeason.period.validTo
            : null,
        },
        isLastEntry: state.availabilityReducer.currentSeason.isLastEntry
          ? state.availabilityReducer.currentSeason.isLastEntry
          : 0,
        isLastEntryCheckbox:
          state.availabilityReducer.currentSeason.isLastEntry !== 0
            ? true
            : false,
        isTemporaryClosed: {
          validFrom: state.availabilityReducer.currentSeason.isTemporaryClosed
            ? state.availabilityReducer.currentSeason.isTemporaryClosed
                .validFrom
            : null,
          validTo: state.availabilityReducer.currentSeason.isTemporaryClosed
            ? state.availabilityReducer.currentSeason.isTemporaryClosed.validTo
            : null,
        },
        isTemporaryClosedCheckbox:
          state.availabilityReducer.currentSeason.isTemporaryClosed &&
          state.availabilityReducer.currentSeason.isTemporaryClosed !== null
            ? true
            : false,
        type: ownProps.currentSeason.type
          ? ownProps.currentSeason.type === TYPE_OPENING_HOURS &&
            ownProps.currentSeason.isAllWeek
            ? TYPE_ALL_WEEK
            : ownProps.currentSeason.type
          : null,
      },
    };
  } else {
    return {
      initialValues: {
        name: null,
        period: {
          validFrom: null,
          validTo: null,
        },
        isLastEntry: 0,
        isLastEntryCheckbox: false,
        isTemporaryClosed: {
          validFrom: null,
          validTo: null,
        },
        isTemporaryClosedCheckbox: false,
        type: null,
      },
    };
  }
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'AddNewSeasonForm',
    enableReinitialize: true,
  })(SeasonForm)
);
