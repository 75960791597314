import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
} from '@mui/material';
import {
    VisitorsDialogFormFieldTitle,
    VisitorsFormFieldWrapper
} from "../../styled";
import { connect, useDispatch, useSelector } from "react-redux";
import UserCreationDialogSelectField from "./UserCreationDialogSelectField";
import {
    USER_ROLE_SETTINGS_ENGLISH_NAME,
    USER_ADMIN_ROLE,
    USER_SYSTEM_MANAGER_ROLE,
    REGIONAL_MANAGER_ROLE,
    ACTIVE_COMPANY_STATUS
} from "../../constants";
import { UserFormAutocomplete, UserFormCheckbox, UserFormFieldTitle, UserFormFieldWrapper } from '../../../usersTab/forms/styled';
import { change, Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { getAreasIncludedInDestination, getRegionsIncludedInDestination } from '../../../usersTab/helpers';
import { getSelectedCompanyAttractions } from '../../../usersTab/forms/helpers';
import { REGIONAL_MANAGER } from './constants';

const UserCreationDialog = ({showAlert, handleClose, handleCloseSuccess, text, companyFieldValue, regionFieldValue, areaFieldValue, isAllAttractionsAndPoints, attractionFieldValue}) => {
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const setFormFieldValue = (fieldName, newValue) => {
        dispatch(change('userCreationForm', fieldName, newValue));
    };

    const selectedLanguage = useSelector(
        (state) => state.appReducer.selectedLanguage
    );    
    
    const { allCompaniesShortDataList: companyList } = useSelector(
        (state) => state.companyReducer
    );

    const regionList = useSelector(
        (state) => state.settingsReducer.dictionaryRegionData
    );

    const selectedDestinationId = useSelector(
        (state) => state.appReducer.selectedDestination.id
    );

    const attractionListOfCompanies = useSelector(
        (state) => state.companyReducer.attractionListOfCompanies
    );

    const attractionList = [
        ...useSelector((state) => state.attractionsReducer.attractionsSmallData),
      ];
    
    const sortedAttractions = attractionList.sort(function (a, b) {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    const pointList = useSelector((state) => [
        ...state.salesPointsReducer.pointShortDataList,
      ]);
    
      const sortedPoints = pointList.sort(function (a, b) {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

    const currentLanguage = useSelector(state => state.appReducer.selectedLanguage);
    const roles = useSelector(state => state.userReducer.userSettings.find(x => x.name.en === USER_ROLE_SETTINGS_ENGLISH_NAME));
    const companies = useSelector(state => state.companyReducer.companiesData.filter(x => x.status === ACTIVE_COMPANY_STATUS));
    const defaultCompany = useSelector(state => state.companyReducer.defaultCompany);
    
    const [role,setRole] = useState(roles.parameters[0].id);
    const [company,setCompany] = useState(companies[0]?.id);
    
    const regionOptionList = useMemo(
        () =>
          getRegionsIncludedInDestination(
            regionList,
            companies[0] ? companies[0].idDestination : null,
            defaultCompany?.idDestination,
            selectedDestinationId
          ) ,
        [
          companies[0] && companies[0].idDestination,
          regionList,
          defaultCompany?.idDestination,
          selectedDestinationId,
        ]
      );
    

    const areaOptionList = useMemo(
    () => 
        getAreasIncludedInDestination(
        regionOptionList,
        regionFieldValue || [],
        companies[0] ? companies[0].idDestination : null,
        ),
    [ 
        regionOptionList,
        companies[0] && companies[0].idDestination,
        defaultCompany?.idDestination,
        regionFieldValue,
    ]);

    const attractionsAndSalesPointsLinkedToCompanies = [
        ...attractionListOfCompanies,
        {
          id: defaultCompany.id,
          attractions: sortedAttractions,
          salesPoints: sortedPoints,
        },
      ];


    const attractionOptionList = useMemo(
        () =>
          getSelectedCompanyAttractions(
            companyFieldValue,
            attractionsAndSalesPointsLinkedToCompanies,
            role,
            areaFieldValue || [],
            regionFieldValue ||[]
          ),
        [attractionsAndSalesPointsLinkedToCompanies, areaFieldValue, regionFieldValue]
    );
    
    const handleChangeIsAllAttractionsAndPointsField = () => {
        setFormFieldValue('attractions', []);
        setFormFieldValue('salesPoints', []);
    };
    
    const handleDialogSubmit = () => {
        let userCompany = role === USER_ADMIN_ROLE || role === USER_SYSTEM_MANAGER_ROLE || role === REGIONAL_MANAGER_ROLE ? defaultCompany.id : company;
        handleCloseSuccess(userCompany, role, regionFieldValue, areaFieldValue, attractionFieldValue, isAllAttractionsAndPoints);
        setRole(roles.parameters[0].id);
        setCompany(companies[0].id);
    };

    const handleRoleValueChange = (value) => {
        setRole(value);
        setFormFieldValue('isAllAttractionsAndPoints', false);

        if(value === REGIONAL_MANAGER) {
            setFormFieldValue('company', defaultCompany)
        }
    };

    const handleCompanyValueChange = (value) => {
        setCompany(value);
    };

    return (
        <Dialog
            open={showAlert}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    width: role === REGIONAL_MANAGER ? '800px' : '500px', 
                },
            }}
        >
            <DialogContent sx={role === REGIONAL_MANAGER ? { padding: '25px 100px'} : { padding: '25px 70px'}}>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                    {t('visitors.formFieldLabels.role')}
                </VisitorsDialogFormFieldTitle>
                <UserCreationDialogSelectField
                    name="role"
                    options={roles?.parameters}
                    renderOptions={(options) => {
                        if (!options || !options.length) return null;

                        return options.map((option, index) => {
                            return (
                                <option value={option.id} key={index}>
                                    {option.value[currentLanguage]}
                                </option>
                            );
                        });
                    }}
                    onChange={handleRoleValueChange}
                    required
                />
            </VisitorsFormFieldWrapper>
            {(role === 'AttractionManager' || role === 'AttractionManagerPrimary') && (
            <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                    {t('visitors.formFieldLabels.company')}
                </VisitorsDialogFormFieldTitle>
                <UserCreationDialogSelectField
                    name="role"
                    options={companies}
                    renderOptions={(options) => {
                        if (!options || !options.length) return null;

                        return options.map((option, index) => {
                            return (
                                <option value={option.id} key={index}>
                                    {option.name}
                                </option>
                            );
                        });
                    }}
                    onChange={handleCompanyValueChange}
                    required
                />
            </VisitorsFormFieldWrapper>
            )}

            {role === REGIONAL_MANAGER && (
                <>
                <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                    {t('visitors.formFieldLabels.company')}
                </VisitorsDialogFormFieldTitle>
                <Field
                    name="company"
                    component={UserFormAutocomplete}
                    options={companyList}
                    getOptionLabel={(option) => {
                        if (!option) return '';

                        return option.name;
                    }}
                    disabled
                    isOptionEqualToValue={(option, value) => {
                        return value.id === option.id;
                    }}
                    disablePortal
                    getOptionDisabled={(option) => option.id === defaultCompany.id}
                />
                <div style={{width: '84px'}}></div>
                </VisitorsFormFieldWrapper>
                <VisitorsFormFieldWrapper>
                    <div style={{marginLeft: '50px'}}></div>
                    <Field
                        component={UserFormCheckbox}
                        name="isAllAttractionsAndPoints"
                        label={t('users.all_attractions_and_points')}
                        onChange={handleChangeIsAllAttractionsAndPointsField}
                    />
                </VisitorsFormFieldWrapper>
                <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>{t('users.link_region')}:</VisitorsDialogFormFieldTitle>
                <Field
                    name="regions"
                    component={UserFormAutocomplete}
                    options={regionOptionList}
                    label={t('users.regions')}
                    multiple
                    disablePortal
                    isOptionEqualToValue={(option, value) => {
                        return value.idRegion === option.id;
                    }}
                    getOptionLabelForOptionList={(option) => {
                        if (!option) return '';
                        return option.name[selectedLanguage] || option.name.en;
                    }}
                    getOptionLabel={(option) => {
                        const optionLabel = regionOptionList.find(
                            (region) => region.id === option.idRegion
                        );
                        if (!optionLabel) return '';
        
                        return (
                            optionLabel.name[selectedLanguage] ||
                            optionLabel.name.en ||
                            ''
                        );
                        }}
                    normalize={(regionList) => {
                        return regionList.map((region) => {
                        if (region.idRegion) return region;
                        return { idRegion: region.id };
                        });
                    }}
                />
                <div style={{width: '84px'}}></div>
            </VisitorsFormFieldWrapper>
            <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                        {t('users.link_area')}
                </VisitorsDialogFormFieldTitle>
                <Field
                    name="areas"
                    component={UserFormAutocomplete}
                    options={areaOptionList}
                    label={t('users.areas')}
                    multiple
                    disablePortal
                    validate={[]}
                    isOptionEqualToValue={(option, value) => {
                        return value.idArea === option.id;
                    }}
                    getOptionLabelForOptionList={(option) => {
                        if (!option) return '';
                        return option.name[selectedLanguage] || option.name.en || option.name.cs;
                    }}
                    getOptionLabel={(option) => {
                        const optionLabel = areaOptionList.find(
                        (area) => area.id === option.idArea
                        );
                        if (!optionLabel) return '';  

                        return (
                        optionLabel.name[selectedLanguage] ||
                        optionLabel.name.en ||
                        optionLabel.name.cs ||
                        ''
                        );
                    }}
                    normalize={(areaList) => {
                        return areaList.map((area) => {
                        if (area.idArea) {
                            return area;
                        }
                        return { idArea: area.id };
                        });
                    }}
                />
                <div style={{width: '84px'}}></div>
                </VisitorsFormFieldWrapper>
                <VisitorsFormFieldWrapper>
                <VisitorsDialogFormFieldTitle>
                        {t('users.link_attraction')}
                </VisitorsDialogFormFieldTitle>
                <Field
                    name="attractions"
                    component={UserFormAutocomplete}
                    options={attractionOptionList}
                    multiple
                    disablePortal
                    label={t('users.attractions')}
                    disabled={isAllAttractionsAndPoints}
                    getOptionLabel={(option) => {
                        const optionLabel = attractionOptionList.find(
                        (attr) => attr.id === option.idAttraction
                        );

                        return optionLabel?.name || '';
                    }}
                    getOptionLabelForOptionList={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) =>
                        value.idAttraction === option.id
                    }
                    normalize={(attractionList) => {
                        return attractionList.map((attraction) => {
                        if (attraction.idAttraction) return attraction;
                        return { idAttraction: attraction.id };
                        });
                    }}
                    />
                    <div style={{width: '84px'}}></div>
                </VisitorsFormFieldWrapper>
            </>
            )}
            <DialogActions sx={{justifyContent: 'center'}}>
                <Button onClick={handleClose}>{t('no')}</Button>
                <Button onClick={handleDialogSubmit}>{t('yes')}</Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const selector = formValueSelector('userCreationForm');
    const companyFieldValue = selector(state, 'company');
    const regionFieldValue = selector(state, 'regions');
    const areaFieldValue = selector(state, 'areas');
    const attractionFieldValue = selector(state, 'attractions');
    
    const isAllAttractionsAndPoints = selector(
        state,
        'isAllAttractionsAndPoints'
      );
    return {
        companyFieldValue,
        regionFieldValue,
        areaFieldValue,
        isAllAttractionsAndPoints,
        attractionFieldValue
    }
};

export default reduxForm({
    form: 'userCreationForm'  
})(connect(mapStateToProps)(UserCreationDialog));