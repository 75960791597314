import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  CircularProgress,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { salesPointTabs } from './salesPointTabs';
import SalesPointsTable from './SalesPointsTable';
import {
  SelectStatus,
  SearchField,
  SelectRegion,
  SelectArea,
  SelectLocation,
} from './Select';

import {
  AddButton,
  AttractionsPageContainer,
  Container,
  VisitsTableFilterFormWrapper,
} from './styled';

import { SALES_POINT_STATUS } from '../../consts/consts';
import {
  ATTRACTION_MANAGER_ROLE,
  DEFAULT_CATEGORY_INPUT_VALUE,
  DEFAULT_AUTOCOMPLETE_VALUE,
  SALES_POINTS_PAGE_ROUTE,
  TYPE_REGION,
  TYPE_AREA,
  TYPE_LOCATION,
} from './constants';
import { useMemo } from 'react';
import { getAreaOptionList, getLocationOptionList } from './utils';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../redux/refreshDataStateReducer/refreshDataStateReducer';

const useStyles = makeStyles({
  button: {
    '&.MuiButton-root': {
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&.Mui-selected': {
        borderRadius: '5px',
      },
    },
  },
  wrapper: {
    '& .MuiOutlinedInput-input': {
      color: '#000',
      '&::placeholder': {
        color: '#000',
      },
    },
  },
  clearButton: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#c5c5c7',
    },
    backgroundColor: '#a7a7a8',
    height: '36px',
    width: '36px',
  },
});

const SalesPointListPage = (props) => {
  const {
    authState,
    setAddElement,
    setTitle,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getCompaniesListThunk,
    appState,
    settingsState,
    categoryState,
    predefinedState,
    salesPointsReducer,
    getSalesPointListThunk,
    setCurrentPoint,
    setIsPointListLoading,
    setIsPointLoading,
  } = props;

  const {isPointListLoading} = props.salesPointsReducer
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [categoryInputValue, setCategoryInputValue] = useState(
    DEFAULT_CATEGORY_INPUT_VALUE
  );

  const [regionInputValue, setRegionInputValue] = useState(
    DEFAULT_AUTOCOMPLETE_VALUE
  );

  const [areaInputValue, setAreaInputValue] = useState(
    DEFAULT_AUTOCOMPLETE_VALUE
  );

  const [locationInputValue, setLocationInputValue] = useState(
    DEFAULT_AUTOCOMPLETE_VALUE
  );

  const hasAttractionManagerRole = Array.isArray(authState.role)
    ? authState.role.some((currentRole) =>
        currentRole.includes(ATTRACTION_MANAGER_ROLE)
      )
    : authState.role === ATTRACTION_MANAGER_ROLE;

  const currentDestinationData = useMemo(
    () =>
      settingsState.destinationData.find(
        (dest) => dest.id === appState.selectedDestination.id
      ),
    [appState.selectedDestination.id]
  );
  
  const isRegionEnabledInDestination =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_REGION);
  const isAreaEnabledInDestination =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_AREA);
  const isLocationEnabledInDestination =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_LOCATION);

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      statusValue: '',
      searchValue: '',
      categoryValue: '',
      regionValue: '',
      areaValue: '',
      locationValue: '',
      sortValue: '',
      sortStatus: '',
    },
  });

  const { watch, reset, resetField } = formMethods;

  const status = watch('statusValue');
  const search = watch('searchValue');
  const category = watch('categoryValue');
  const region = watch('regionValue');
  const area = watch('areaValue');
  const location = watch('locationValue');
  const sortValue = watch('sortValue');
  const sortStatus = watch('sortStatus');

  const handlePointAdd = () => {
    navigate(SALES_POINTS_PAGE_ROUTE + '/new');
    setAddElement(true);
    setCurrentPoint({});
  };

  const sortByField = (fieldName) => {
    if (formMethods.getValues('sortValue') === fieldName) {
      if (formMethods.getValues('sortStatus') === 'Asc') {
        formMethods.setValue('sortStatus', null);
        formMethods.setValue('sortValue', null);
      } else {
        formMethods.setValue('sortStatus', 'Asc');
      }
    } else {
      formMethods.setValue('sortValue', fieldName);
      formMethods.setValue('sortStatus', 'Desc');
    }
  };

  const handleClearButtonClick = () => {
    reset();
    setCategoryInputValue(DEFAULT_CATEGORY_INPUT_VALUE);
    setRegionInputValue(DEFAULT_AUTOCOMPLETE_VALUE);
    setAreaInputValue(DEFAULT_AUTOCOMPLETE_VALUE);
    setLocationInputValue(DEFAULT_AUTOCOMPLETE_VALUE);
  };

  const handleSalesPointDataRowClick = (id) => {
    navigate(id + '/' + salesPointTabs[0].path);
    setAddElement(false);
    setIsPointLoading(false);
  };

  const areaOptionList = useMemo(() => {
    return getAreaOptionList(currentDestinationData, regionInputValue);
  }, [currentDestinationData, regionInputValue]);

  const locationOptionList = useMemo(() => {
    return getLocationOptionList(currentDestinationData, region, area);
  }, [currentDestinationData, region, area]);

  const resetIncludedLocationsValue = (locationType) => {
    if (locationType === TYPE_REGION) {
      if (currentDestinationData.enabledLocationTypes.includes(TYPE_AREA)) {
        resetField('areaValue');
        setAreaInputValue({
          id: '',
          title: '',
        });
      }

      if (currentDestinationData.enabledLocationTypes.includes(TYPE_LOCATION)) {
        resetField('locationValue');
        setLocationInputValue({
          id: '',
          title: '',
        });
      }
    }

    if (locationType === TYPE_AREA) {
      if (currentDestinationData.enabledLocationTypes.includes(TYPE_LOCATION)) {
        resetField('locationValue');
        setLocationInputValue({
          id: '',
          title: '',
        });
      }
    }
  };

  useEffect(() => {
    setCurrentPoint({});
    getSalesPointListThunk({
      destinationId: appState.selectedDestination.id,
      pageNumber: salesPointsReducer.pageNumber,
      pageSize: salesPointsReducer.pageSize,
      status,
      search,
      category: categoryInputValue.id || '',
      region: regionInputValue.id || '',
      area: areaInputValue.id || '',
      location,
      sort: sortValue && sortStatus ? `${sortValue}${sortStatus}` : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appState.selectedDestination.id,
    area,
    salesPointsReducer.pageNumber,
    salesPointsReducer.pageSize,
    category,
    location,
    region,
    search,
    status,
    sortValue,
    sortStatus,
  ]);

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_points')));
    Promise.all([
      getCategoryThunk(
        appState.selectedDestination.id !== undefined
        ? appState.selectedDestination.id
        : settingsState.destinationData[0].id
      ), 
      getPredefinedSettingsThunk()
    ]);

    dispatch(setTasks(7));
  }, []);

  useEffect(() => {
    getCompaniesListThunk(
      appState.selectedDestination.id !== undefined
        ? appState.selectedDestination.id
        : settingsState.destinationData[0].id
    );
  }, [appState.selectedDestination]);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await getSalesPointListThunk({
          destinationId: appState.selectedDestination.id,
          pageNumber: salesPointsReducer.pageNumber,
          pageSize: salesPointsReducer.pageSize,
          status,
          search,
          category: categoryInputValue.id || '',
          region: regionInputValue.id || '',
          area: areaInputValue.id || '',
          location,
          sort: sortValue && sortStatus ? `${sortValue}${sortStatus}` : '',
        });
        dispatch(incrementCompletedTasks());

        await getCompaniesListThunk(
          appState.selectedDestination.id !== undefined
            ? appState.selectedDestination.id
            : settingsState.destinationData[0].id
        );
        dispatch(incrementCompletedTasks());

        await getCategoryThunk(
          appState.selectedDestination.id !== undefined
          ? appState.selectedDestination.id
          : settingsState.destinationData[0].id
        );
        dispatch(incrementCompletedTasks());

        await getPredefinedSettingsThunk();
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  return (
    <>
      {appState.selectedDestination.id ? (
        <AttractionsPageContainer>
          <Box mb="10px">
            <Typography
              fontSize="20px"
              fontWeight={500}
              textTransform="uppercase"
            >
              {t('attractions.points_headline')}
            </Typography>
          </Box>
          {!hasAttractionManagerRole && (
            <AddButton>
              <Button variant="contained" onClick={handlePointAdd}>
                {t('attractions.add_point_button')}
              </Button>
            </AddButton>
          )}
          <Container>
            <FormProvider {...formMethods}>
              <VisitsTableFilterFormWrapper>
                <SelectStatus
                  data={predefinedState.predefinedData.filter(
                    (item) => item.name.en === SALES_POINT_STATUS
                  )}
                  selectedLanguage={appState.selectedLanguage}
                />
                <SearchField />

                {isRegionEnabledInDestination && (
                  <SelectRegion
                    resetIncludedLocationsValue={resetIncludedLocationsValue}
                    data={currentDestinationData.locations}
                    selectedLanguage={appState.selectedLanguage}
                    regionInputValue={regionInputValue}
                    handleChangeRegionInputValue={setRegionInputValue}
                  />
                )}

                {isAreaEnabledInDestination && (
                  <SelectArea
                    resetIncludedLocationsValue={resetIncludedLocationsValue}
                    data={areaOptionList}
                    selectedLanguage={appState.selectedLanguage}
                    areaInputValue={areaInputValue}
                    handleChangeAreaInputValue={setAreaInputValue}
                  />
                )}

                {isLocationEnabledInDestination && (
                  <SelectLocation
                    resetIncludedLocationsValue={resetIncludedLocationsValue}
                    data={locationOptionList}
                    selectedLanguage={appState.selectedLanguage}
                    locationInputValue={locationInputValue}
                    handleChangeLocationInputValue={setLocationInputValue}
                  />
                )}
              </VisitsTableFilterFormWrapper>
            </FormProvider>
            <Box
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={false}
                className={clsx(classes.clearButton)}
                size="small"
                onClick={handleClearButtonClick}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Container>
          <SalesPointsTable
            appState={appState}
            pageNumber={salesPointsReducer.pageNumber}
            pageSize={salesPointsReducer.pageSize}
            totalCount={salesPointsReducer.totalCount}
            sortBy={sortByField}
            onSalesPointDataRowClick={handleSalesPointDataRowClick}
            sortField={sortValue}
            sortStatus={sortStatus}
            settingsState={settingsState}
            categoryState={categoryState}
            salesPointList={salesPointsReducer.pointList}
            setIsLoadingListAttractions={setIsPointListLoading}
            isPointListLoading = {isPointListLoading}
          />
        </AttractionsPageContainer>
      ) : (
        <Box
          component="div"
          sx={{ width: '100%', marginTop: '100px', position: 'relative' }}
        >
          <CircularProgress
            sx={{ position: 'absolute', right: '50%', top: '300px' }}
          />
        </Box>
      )}
    </>
  );
};

export default SalesPointListPage;
