import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '@mui/material';

import { SelectFilter } from './FormComponent';
import TranslationList from './TranslationList';

import {
  LoaderWrapper,
  TranslationPageButton,
  TranslationPageFilterWrapper,
  TranslationPageHeader,
  TranslationPageWrapper,
} from './styled';

import { CONTENT_LANGUAGES } from '../../consts/consts';

import { sortLanguageListInDestinationSettingsOrder } from '../../helpers/sortLanguageListInDestinationSettingsOrder';
import { setTitle } from '../../redux/appReducer';
import { incrementCompletedTasks, setRefresh, setTasks } from '../../redux/refreshDataStateReducer/refreshDataStateReducer';

const TranslationPage = (props) => {
  const {
    translationsReducer,
    appReducer,
    getCategoryDataThunk,
    getTranslationsSearchThunk,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLang, setSelectedLang] = useState('en');

  const destinationLanguageList = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );

  const contentLanguageList = appReducer.globalSettingsData.find(
    (item) => item.name.en === CONTENT_LANGUAGES
  );

  const sortedLanguageList = sortLanguageListInDestinationSettingsOrder(
    contentLanguageList.parameters,
    destinationLanguageList
  );

  const onAddKey = () => {
    navigate('new-translation');
  };

  const handleListItemClick = (item) => {
    navigate(item.id);
  };

  const handleFilterChange = (categoryKeyValue) => {
    const searchParams = new URLSearchParams();
    if (!categoryKeyValue) {
      searchParams.delete('category');
    } else {
      searchParams.set('category', categoryKeyValue);
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_translation')));
  }, [])

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );

  useEffect(() => {
    dispatch(setTasks(4));
    return () => {
      dispatch(setTasks(3));
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh && appReducer.selectedDestination.id) {
        await getTranslationsSearchThunk(
          appReducer.selectedDestination.id,
          searchParams.get('category')
        ); 
  
        dispatch(incrementCompletedTasks());
      }
    };
  
    fetchData(); 
  }, [isBaseTasksLoaded, needToRefresh]);
  

  useEffect(() => {
    if (appReducer.selectedDestination.id) {
      getTranslationsSearchThunk(
        appReducer.selectedDestination.id,
        searchParams.get('category')
      );
    }
  }, [
    searchParams,
    appReducer.selectedDestination.id,
    getTranslationsSearchThunk,
  ]);

  useEffect(() => {
    getCategoryDataThunk();
  }, [getCategoryDataThunk]);

  useEffect(() => {
    if (destinationLanguageList) setSelectedLang(destinationLanguageList[0]);
  }, [destinationLanguageList]);

  return (
    <>
      {translationsReducer.isLoadingData ? (
        <TranslationPageWrapper>
          <TranslationPageHeader>
            <TranslationPageButton
              variant="contained"
              onClick={onAddKey}
              disabled={false}
            >
              {t('settings_page.translation.add_button')}
            </TranslationPageButton>

            <TranslationPageFilterWrapper>
              <SelectFilter
                translationsState={translationsReducer.translationsCategoryData}
                onChange={(e) => handleFilterChange(e)}
                value={searchParams.get('category')}
              />
            </TranslationPageFilterWrapper>
          </TranslationPageHeader>
          <TranslationList
            translationsState={translationsReducer}
            languageList={sortedLanguageList}
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            handleListItemClick={handleListItemClick}
          />
        </TranslationPageWrapper>
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </>
  );
};

export default TranslationPage;
