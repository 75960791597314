import { connect } from 'react-redux';
import { compose } from 'redux';

import Setup from './Setup';

import {
  getAttractionsListThunk,
  addAttractionsThunk,
  editAttractionSetupThunk,
  deleteAttractionsThunk,
  addAttractionPublicationThunk,
  setAddElement,
  setAttraction,
  getAttractionListByRegionIdThunk,
  getAttractionThunk,
} from '../../../redux/attractionsReducers/attractions/attractionsActions';

import { getCompaniesListThunk } from '../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../redux/settingsReducers/categoryReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getFacilitiesThunk } from '../../../redux/settingsReducers/facilitiesReducer';
import { getTagsThunk } from '../../../redux/settingsReducers/tagsReducer';

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsReducer,
    appState: state.appReducer,
    attractionsState: state.attractionsReducer,
    categoryState: state.categoryReducer,
    facilityState: state.facilitiesReducer,
    tagsState: state.tagsReducer,
    predefinedState: state.predefinedReducer,
    companyState: state.companyReducer,
    authState: state.authReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    getAttractionsListThunk,
    addAttractionsThunk,
    editAttractionSetupThunk,
    deleteAttractionsThunk,
    addAttractionPublicationThunk,
    setAddElement,
    setAttraction,
    getAttractionListByRegionIdThunk,
    getAttractionThunk,
    getCompaniesListThunk,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getFacilitiesThunk,
    getTagsThunk,
  })
)(Setup);
