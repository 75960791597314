import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
  PredefinedContainer,
  PredefinedBox,
  PredefinedTitleWrapper,
  PredefinedTitleText,
  PredefinedFieldWrapper,
  PredefinedFieldText,
  LoaderWrapper,
  PredefinedWrapper,
} from './styled';

import { incrementCompletedTasks, setRefresh, setTasks } from '../../../redux/refreshDataStateReducer/refreshDataStateReducer';

const Predefined = (props) => {
  const {
    selectedLanguage,
    appReducer,
    getPredefinedSettingsThunk,
    predefinedReducer,
    userSettings,
    getUserSettingsThunk,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    getPredefinedSettingsThunk();
    getUserSettingsThunk();
  }, [getPredefinedSettingsThunk, getUserSettingsThunk]);

  useEffect(() => {
    dispatch(setTasks(5))
  }, []);

  const { isBaseTasksLoaded, needToRefresh, tasks, completedTask } = useSelector(
    (state) => state.refreshDataState
  );
    
  useEffect(() => {
    return () => {
      dispatch(setTasks(3))
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isBaseTasksLoaded && needToRefresh) {
        await getPredefinedSettingsThunk();
        dispatch(incrementCompletedTasks());

        await getUserSettingsThunk();
        dispatch(incrementCompletedTasks());
      }
    }
    
    fetchData();
  }, [isBaseTasksLoaded, needToRefresh]);

  const settingsList = [
    ...predefinedReducer.predefinedData,
    ...appReducer.globalSettingsData,
    ...userSettings,
  ];
  const isDataLoading = predefinedReducer.isLoadingPredefinedData;
  return (
    <PredefinedContainer>
      {isDataLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <PredefinedWrapper>
          {settingsList.map((elem, index) => (
            <PredefinedBox key={index}>
              <PredefinedTitleWrapper>
                <PredefinedTitleText>
                  {elem.name[selectedLanguage] || elem.name.en}
                </PredefinedTitleText>
              </PredefinedTitleWrapper>
              {elem.parameters.map((item) =>
                item.value.en ? (
                  <PredefinedFieldWrapper key={item.id}>
                    <PredefinedFieldText>
                      {item.value.en
                        ? item.value[selectedLanguage]
                        : item.value}
                    </PredefinedFieldText>
                  </PredefinedFieldWrapper>
                ) : item.value.displayName ? (
                  <PredefinedFieldWrapper key={item.id}>
                    <PredefinedFieldText>
                      {item.value.displayName}
                    </PredefinedFieldText>
                  </PredefinedFieldWrapper>
                ) : (
                  <PredefinedFieldWrapper key={item.id}>
                    <PredefinedFieldText>{item.value}</PredefinedFieldText>
                  </PredefinedFieldWrapper>
                )
              )}
            </PredefinedBox>
          ))}
        </PredefinedWrapper>
      )}
    </PredefinedContainer>
  );
};

export default Predefined;
