import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { reset } from 'redux-form';

import DestinationForm from './DestinationForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import {
  getDestinationThunk,
  editDestinationThunk,
  deleteDestinationThunk,
  setCurrentDestination,
} from '../../../../redux/settingsReducers/settingsReducer';

import { DESTINATION_PAGE_ROUTE } from '../constants';

const DestinationEditFormContainer = (props) => {
  const {
    settingsState,
    globalSettings,
    getDestinationThunk,
    editDestinationThunk,
    deleteDestinationThunk,
    setCurrentDestination,
    selectedDestinationId,
  } = props;

  const { destinationId } = useParams();

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [levelArray, setLevelArray] = useState([]);
  const [newDestinationLanguages, setNewDestinationLanguages] = useState([]);
  const [newDestinationActiveLanguages, setNewDestinationActiveLanguages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showNoLanguagesError, setShowNoLanguagesError] = useState(false);
  const [showNoActiveLanguagesError, setShowNoActiveLanguagesError] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);

  const handleDestinationFormSubmit = (values) => {
    if (!newDestinationLanguages.length) {
      setShowNoLanguagesError(true);
      addError(t('settings_page.destination.error_no_languages_selected'), 'Error');
    } else if (!newDestinationActiveLanguages.length) {
      setShowNoActiveLanguagesError(true);
      addError(t('settings_page.destination.error_no_languages_selected'), 'Error');
    } else {
      setShowNoLanguagesError(false);
      setShowNoActiveLanguagesError(false)
      setShowSaveAnimation(true);

      let enabledLocations = [];

      if (values.Region) {
        enabledLocations.push('Region');
      }
      if (values.Area) {
        enabledLocations.push('Area');
      }
      if (values.Location) {
        enabledLocations.push('Location');
      }

      const updateData = {
        id: destinationId,
        name: values.name,
        enabledLocationTypes: enabledLocations,
        valueAddedTaxes: levelArray,
        timeZoneId: values.timeZoneId,
        currency: values.currency,
        languages: newDestinationLanguages,
        activeLanguages: newDestinationActiveLanguages
      };

      editDestinationThunk(destinationId, updateData)
        .then(() => {
          getDestinationThunk();
          setShowSaveAnimation(false);
          addError(
            `${t('settings_page.destination.error_edit_text')}`,
            'Success'
          );
          navigate(DESTINATION_PAGE_ROUTE);
        })
        .catch((error) => {
          addError(
            `${t('settings_page.destination.error_not_edit_text')}`,
            'Error',
            error
          );
          setShowSaveAnimation(false);
        });
    }
  };

  const handleCloseSuccess = () => {
    setIsInEditMode(false);

    deleteDestinationThunk(destinationId)
      .then(() => {
        getDestinationThunk();
        setShowAlert(false);
        setIsInEditMode(false);
        addError(
          `${t('settings_page.destination.error_deleted_text')}`,
          'Success'
        );
        navigate(DESTINATION_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.destination.error_not_deleted_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
        setShowAlert(false);
      });
  };

  const handleDestinationFormCancel = () => {
    dispatch(reset('Destination'));
    setIsInEditMode(false);
  };

  useEffect(() => {
    if (destinationId) {
      const currentDestination = settingsState.destinationData.find(
        (destination) => destination.id === destinationId
      );
      setCurrentDestination(currentDestination);
    }
  }, [destinationId]);

  return (
    <>
      {settingsState.currentDestination?.id && (
        <DestinationForm
          destinationsState={settingsState}
          onSubmit={handleDestinationFormSubmit}
          updateDestination={true}
          isEdit={isInEditMode}
          setIsEdit={setIsInEditMode}
          levelArray={levelArray}
          setLevelArray={setLevelArray}
          languages={newDestinationLanguages}
          activeLanguages={newDestinationActiveLanguages}
          globalSettings={globalSettings}
          setLanguages={setNewDestinationLanguages}
          setActiveLanguages={setNewDestinationActiveLanguages}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleCloseSuccess={handleCloseSuccess}
          isSave={showSaveAnimation}
          showNoLanguagesError={showNoLanguagesError}
          setShowNoLanguagesError={setShowNoLanguagesError}
          showNoActiveLanguagesError={showNoActiveLanguagesError}
          setShowNoActiveLanguagesError={setShowNoActiveLanguagesError}
          onCancel={handleDestinationFormCancel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    selectedDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  getDestinationThunk,
  editDestinationThunk,
  deleteDestinationThunk,
  setCurrentDestination,
})(DestinationEditFormContainer);
