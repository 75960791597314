import { stopSubmit } from 'redux-form';
import {
  getAttractionsListApi,
  getAttractionsApi,
  addAttractionsApi,
  editAttractionSetupApi,
  editAttractionContentApi,
  deleteAttractionApi,
  addAttractionPublicationApi,
  getSmallAttractionsApi,
  getAttractionsSearchApi,
  editAttractionContentTranslateApi,
} from '../../../api/attractionsApi.js';
export const SET_ATTRACTIONS_LIST = 'SET_ATTRACTIONS_LIST';
export const SET_ATTRACTIONS_SETUP_LIST = 'SET_ATTRACTIONS_SETUP_LIST';
export const LOAD_ATTRACTIONS_LIST = 'LOAD_ATTRACTIONS_LIST';
export const SET_SMALL_ATTRACTIONS_DATA = 'SET_SMALL_ATTRACTIONS_DATA';
export const SET_CURRENT_ATTRACTION = 'SET_CURRENT_ATTRACTION';
export const SET_SELECTED_REGION = 'SET_SELECTED_REGION';
export const SET_SELECTED_AREA = 'SET_SELECTED_AREAS';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_PAGE_NUMBER_LAZY = 'SET_PAGE_NUMBER_LAZY';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const IS_LOADING_LIST_ATTRACTIONS = 'IS_LOADING_LIST_ATTRACTIONS';
export const IS_LOADING_ATTRACTION = 'IS_LOADING_ATTRACTION';
export const SET_ADD_ELEMENT = 'SET_ADD_ELEMENT';
export const SET_EXPAND_SEARCH_FIELDS = 'SET_EXPAND_SEARCH_FIELDS';

export const SET_STATUS_VALUE = 'SET_STATUS_VALUE';
export const SET_ATTRACTIONS_VALUE = 'SET_ATTRACTIONS_VALUE';
export const SET_CATEGORY_VALUE = 'SET_CATEGORY_VALUE';
export const SET_COMPANY_VALUE = 'SET_COMPANY_VALUE';
export const SET_REGION_VALUE = 'SET_REGION_VALUE';
export const SET_AREA_VALUE = 'SET_AREA_VALUE';
export const SET_LOCATION_VALUE = 'SET_LOCATION_VALUE';
export const SET_ATTRACTION_SEARCH_PARAMS = 'SET_ATTRACTION_SEARCH_PARAMS';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';

let setAttractionsList = (data) => {
  return { type: SET_ATTRACTIONS_LIST, payload: { data } };
};
let setAttractionsSetupList = (data) => {
  return { type: SET_ATTRACTIONS_SETUP_LIST, payload: { data } };
};
let loadAttractionsList = (data) => {
  return { type: LOAD_ATTRACTIONS_LIST, payload: { data } };
};
let setSmallAttractionsData = (data) => {
  return { type: SET_SMALL_ATTRACTIONS_DATA, payload: { data } };
};
export let setAttraction = (data) => {
  return { type: SET_CURRENT_ATTRACTION, payload: { data } };
};
export let setSelectedRegion = (selectedRegion) => {
  return { type: SET_SELECTED_REGION, selectedRegion };
};
export let setSelectedArea = (selectedArea) => {
  return { type: SET_SELECTED_AREA, selectedArea };
};
export let setPageSize = (pageSize) => {
  return { type: SET_PAGE_SIZE, pageSize };
};
export let setPageNumber = (pageNumber) => {
  return { type: SET_PAGE_NUMBER, pageNumber };
};
export let setPageNumberLazy = (pageNumberLazy) => {
  return { type: SET_PAGE_NUMBER_LAZY, pageNumberLazy };
};
export let setTotalCount = (totalCount) => {
  return { type: SET_TOTAL_COUNT, totalCount };
};
let setTotalPages = (totalPages) => {
  return { type: SET_TOTAL_PAGES, totalPages };
};
export let setIsLoadingListAttractions = (isLoadingListAttractions) => {
  return { type: IS_LOADING_LIST_ATTRACTIONS, isLoadingListAttractions };
};
export let setIsLoadingAttractions = (isLoadingAttraction) => {
  return { type: IS_LOADING_ATTRACTION, isLoadingAttraction };
};
export let setAddElement = (addElement) => {
  return { type: SET_ADD_ELEMENT, addElement };
};
export let setExpandSearchFields = (expandSearchFields) => {
  return { type: SET_EXPAND_SEARCH_FIELDS, expandSearchFields };
};

export let setStatusValue = (statusValue) => {
  return { type: SET_STATUS_VALUE, statusValue };
};
export let setAttractionsValue = (attractionsValue) => {
  return { type: SET_ATTRACTIONS_VALUE, attractionsValue };
};
export let setCategoryValue = (categoryValue) => {
  return { type: SET_CATEGORY_VALUE, categoryValue };
};
export let setCompanyValue = (companyValue) => {
  return { type: SET_COMPANY_VALUE, companyValue };
};
export let setRegionValue = (regionValue) => {
  return { type: SET_REGION_VALUE, regionValue };
};
export let setAreaValue = (areaValue) => {
  return { type: SET_AREA_VALUE, areaValue };
};
export let setLocationValue = (locationValue) => {
  return { type: SET_LOCATION_VALUE, locationValue };
};
export let setAttractionSearchParams = (attractionSearchParams) => {
  return { type: SET_ATTRACTION_SEARCH_PARAMS, attractionSearchParams };
};

export const setFormData = (formData) => {
  return { type: SET_FORM_DATA, payload: formData };
};

export const resetFormData = () => {
  return { type: RESET_FORM_DATA };
};

export let getAttractionsListThunk =
  ({
    destinationId,
    pageNumber = 0,
    pageSize = 10,
    search = '',
    status = '',
    category = '',
    region = '',
    area = '',
    location = '',
    company = '',
    sort = 'NameAsc',
  }) =>
  async (dispatch) => {
    dispatch(setIsLoadingListAttractions(true));
    let response = await getAttractionsListApi({
      destinationId,
      pageNumber,
      pageSize,
      search,
      status,
      category,
      region,
      area,
      location,
      company,
      sort,
    });
    if (response.status >= 200 && response.status < 300) {
      dispatch(setAttractionsList(response.data.items));

      if (response.data.totalPages <= pageNumber) dispatch(setPageNumber(0));
      else dispatch(setPageNumber(pageNumber));

      dispatch(setPageSize(pageSize));
      dispatch(setTotalCount(response.data.totalCount));
      dispatch(setTotalPages(response.data.totalPages));
      dispatch(setIsLoadingListAttractions(false));
    }
  };

export let LoadAttractionsListThunk =
({
  destinationId,
  pageNumber = 0,
  pageSize = 10,
  search = '',
  status = '',
  category = '',
  region = '',
  area = '',
  location = '',
  company = '',
  sort = 'NameAsc',
}) =>
async (dispatch) => {
  let response = await getAttractionsListApi({
    destinationId,
    pageNumber,
    pageSize,
    search,
    status,
    category,
    region,
    area,
    location,
    company,
    sort,
  });
  if (response.status >= 200 && response.status < 300) {
    dispatch(loadAttractionsList(response.data.items));

    dispatch(setTotalCount(response.data.totalCount));
    dispatch(setTotalPages(response.data.totalPages));
    dispatch(setIsLoadingListAttractions(false));
  }
};

export let getSmallAttractionsDataThunk =
  (destinationId) => async (dispatch) => {
    let response = await getSmallAttractionsApi(destinationId);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setSmallAttractionsData(response.data));
    }
  };

export let getAttractionThunk = (id) => async (dispatch) => {
  let response = await getAttractionsApi(id);
  if (response.status >= 200 && response.status < 300) {
    dispatch(setAttraction(response.data));
    dispatch(setIsLoadingAttractions(true));
  }
};

export let getAttractionSearchThunk =
  (
    destinationId,
    pageNumber,
    pageSize,
    attractions,
    status,
    category,
    region,
    area,
    location,
    company,
    sort
  ) =>
  async (dispatch) => {
    let response = await getAttractionsSearchApi(
      destinationId,
      pageNumber,
      pageSize,
      attractions,
      status,
      category,
      region,
      area,
      location,
      company,
      sort
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(setAttractionsList(response.data.items));
      dispatch(setIsLoadingAttractions(true));
      dispatch(setTotalCount(response.data.totalCount));
      dispatch(setIsLoadingListAttractions(true));
    }
  };

export let addAttractionsThunk = (data) => async (dispatch) => {
  let response = await addAttractionsApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Setup', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
  return response;
};

export let editAttractionSetupThunk = (id, data) => async (dispatch) => {
  let response = await editAttractionSetupApi(id, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Setup', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
}

export let editAttractionContentThunk = (id, data) => async (dispatch) => {
  let response = await editAttractionContentApi(id, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Content', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
}
export let editAttractionContentTranslateThunk = (id, data) => async (dispatch) => {
  let response = await editAttractionContentTranslateApi(id, data);
  
  if(response.status === 200) {
    return response.data;
  }
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Content', { _error: response.data.errors }));
    return Promise.reject(response.data.errors || response.data.detail);
  }
}



export let deleteAttractionsThunk = (attractionId) => async () => {
  let response = await deleteAttractionApi(attractionId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export let addAttractionPublicationThunk = (attractionId) => async (dispatch) => {
  let response = await addAttractionPublicationApi(attractionId);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Setup', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
  return response;
}

export const getAttractionListByRegionIdThunk =
  ({ destinationId, region = '' }) =>
  async (dispatch) => {
    const response = await getAttractionsListApi({
      destinationId,
      region,
      pageSize: 1000,
      pageNumber: 0,
      search: '',
      status: '',
      category: '',
      area: '',
      location: '',
      company: '',
      sort: 'NameAsc',
    });

    if (response.status >= 200 && response.status < 300) {
      dispatch(setAttractionsSetupList(response.data.items));
    }
  };
