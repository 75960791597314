import {
  createArticleRecord,
  deleteArticleRecord,
  getArticleList,
  updateArticleRecord,
} from '../../../api/publishing/articlesApi';

export const SET_ARTICLE_LIST = 'articles/setArticleList';
export const SET_IS_LOADING = 'articles/setIsLoading';

export const setArticleList = (data) => {
  return {
    type: SET_ARTICLE_LIST,
    payload: { data },
  };
};

export const setIsArticleListLoading = (data) => {
  return {
    type: SET_IS_LOADING,
    payload: { data },
  };
};

export const getArticleListThunk =
  (destinationId, type = '', sort = '', isLoading = true) =>
  async (dispatch) => {
    isLoading && dispatch(setIsArticleListLoading(true));

    const response = await getArticleList(destinationId, type, sort);

    if (response.status === 200) {
      dispatch(setArticleList(response.data));
      isLoading && dispatch(setIsArticleListLoading(false));
    }
  };

export const updateArticleRecordThunk = (recordId, data) => async () => {
  const response = await updateArticleRecord(recordId, data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const createArticleRecordThunk = (data) => async () => {
  const response = await createArticleRecord(data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const deleteArticleRecordThunk = (articleId) => async () => {
  const response = await deleteArticleRecord(articleId);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
