import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box, Input, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { ImageDropPreviewContainer } from '../../../publishing/HomePageTab/imageDrop/styled';

import { readFile, getAcceptedFileTypes, isImage, isImageUrl } from '../../../publishing/HomePageTab/imageDrop/utils';
import { UNLIMITED_FILES_LIMIT, DEFAULT_FILES_LIMIT } from '../../../publishing/HomePageTab/imageDrop/constants';
import ImageViewListDragAndDrop from './ImageViewListDragAndDrop';

const styles = ({ palette, shape, spacing }) => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '250px',
    height: '100%',
    backgroundColor: palette.background.paper,
    border: 'solid',
    borderColor: palette.divider,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  active: {
    animation: '$progress 2s linear infinite !important',
    backgroundImage: `repeating-linear-gradient(-45deg, ${palette.background.paper}, ${palette.background.paper} 25px, ${palette.divider} 25px, ${palette.divider} 50px)`,
    backgroundSize: '150% 100%',
    border: 'solid',
    borderColor: palette.primary.light,
  },
  invalid: {
    backgroundImage: `repeating-linear-gradient(-45deg, ${palette.error.light}, ${palette.error.light} 25px, ${palette.error.dark} 25px, ${palette.error.dark} 50px)`,
    borderColor: palette.error.main,
  },
  textContainer: {
    textAlign: 'center',
    display: 'flex',
    margin: '0 auto',
  },
  text: {
    marginBottom: spacing(1),
    marginTop: spacing(3),
  },
  icon: {
    paddingTop: 10,
    width: 51,
    height: 51,
    color: palette.text.primary,
  },
  resetButton: {
    display: 'block',
    margin: '10px 0',
  },
  disablePointer: {
    cursor: 'default',
  },
  contrastBackground: {
    backgroundColor: 'lightgrey',
  },
});

const ImageDragAndDrop = (props) => {
  const {
    filesLimit,
    dropzoneText,
    disabled,
    classes,
    allowMultipleFileSelect,
    allowedFileTypes,
    fileList,
    onFileListChange,
    columns,
    onDelete,
    rowHeight,
    contrastBackground,
    isCanceled,
    imagesWithId,
    setImagesWithId, 
  } = props;

  const acceptedFileTypes = getAcceptedFileTypes(allowedFileTypes);

  const handleFileDrop = async (droppedFiles) => {
    
    if (!droppedFiles.length) return;

    const newFileObjs = await Promise.all(
      droppedFiles.map(async (file) => {
        const data = await readFile(file);
        return {
          file,
          data,
        };
      })
    );
    const validFileList = imagesWithId.filter(file => file !== null);

    if (
      validFileList.length + newFileObjs.length > filesLimit &&
      filesLimit !== UNLIMITED_FILES_LIMIT
    ) return;

    let updatedFileList;
    if (filesLimit === UNLIMITED_FILES_LIMIT || filesLimit > 1) {
      updatedFileList = validFileList.concat(newFileObjs);
    } else {
      updatedFileList = validFileList.concat(newFileObjs[0]);
    }


    const updatedImagesWithId = updatedFileList.map((item, index) => {
      if(item.id) return item
      const url = isImageUrl(item) ? item : isImage(item.file) ? item.data : URL.createObjectURL(item.file);
      return { id: `${url}-${index}`, file: item.file, url };
    });

    setImagesWithId(updatedImagesWithId);
  };

  return (
    <Dropzone
      accept={acceptedFileTypes}
      multiple={allowMultipleFileSelect}
      onDrop={handleFileDrop}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          {...getRootProps({
            className: clsx(classes.root, {
              [classes.disablePointer]: disabled,
              [classes.contrastBackground]: contrastBackground,
            }),
          })}
        >
          <Input {...getInputProps()} />
          <ImageDropPreviewContainer>
            <Box className={classes.textContainer}>
              {dropzoneText && (
                <Typography variant="h5" className={clsx(classes.text)}>
                  {dropzoneText}
                </Typography>
              )}
              <CloudUploadIcon className={classes.icon} />
            </Box>
            <ImageViewListDragAndDrop
              disabled={disabled}
              imageList={fileList}
              previewGridProps={props.previewGridProps}
              previewGridClasses={props.previewGridClasses}
              columns={columns}
              rowHeight={rowHeight}
              onFileListChange = {onFileListChange}
              isCanceled = {isCanceled}
              imagesWithId = {imagesWithId}
              setImagesWithId = {setImagesWithId}
            />
          </ImageDropPreviewContainer>
        </Box>
      )}
    </Dropzone>
  );
};

ImageDragAndDrop.defaultProps = {
  filesLimit: DEFAULT_FILES_LIMIT,

  fileList: [],
  previewGridClasses: {},
  previewGridProps: {},
  dropzoneText: null,
  allowedFileTypes: [],
};

ImageDragAndDrop.propTypes = {
  clearOnUnmount: PropTypes.bool,
  filesLimit: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withStyles(styles, { name: 'MuiDropzoneArea' })(ImageDragAndDrop);
