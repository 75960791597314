import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import ReviewForm from '../../attractions/reviewsTab/forms/ReviewForm';

import { ReviewPageWrapper } from '../styled';

import {
  getAttractionReivewsListThunk,
  addAttractionReviewThunk,
} from '../../../redux/attractionsReducers/reviews/reviewActions';
import { REVIEW_STATUS } from '../../../consts/consts';
import { DEFAULT_REVIEW_LANGUAGE } from '../../attractions/reviewsTab/constants';

const ReviewAddFormContainer = (props) => {
  const {
    addAttractionReviewThunk,
    statusOptionList,
    selectedLanguage,
    attractionList,
    selectedDestinationId,
  } = props;

  const attractionOptionList = attractionList.sort(function (a, b) {
    var textA = a.name.toUpperCase().trim();
    var textB = b.name.toUpperCase().trim();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);

    formData.date = new Date(formData.date.setHours(12, 0, 0, 0));

    const newReview = {
      destinationId: selectedDestinationId,
      attractionId: formData.attractionId,
      status: formData.status,
      userName: formData.userName,
      text: formData.text,
      language: DEFAULT_REVIEW_LANGUAGE,
      rating: parseInt(formData.rating),
      date: formData.date.toISOString(),
      userPlace: formData.userPlace,
      respondentPosition: formData.respondentPosition,
      reply: formData.reply,
    };

    addAttractionReviewThunk(newReview)
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('reviews.success_add_review')}`, 'Success');
        navigate(-1);
      })
      .catch((error) => {
        addError(`${t('reviews.error_add_review')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  return (
    <ReviewPageWrapper>
      <ReviewForm
        isGlobalReview
        isInEditMode
        isInAddMode
        attractionList={attractionOptionList}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
        statusOptionList={statusOptionList}
        showSaveAnimation={showSaveAnimation}
        selectedLanguage={selectedLanguage}
      />
    </ReviewPageWrapper>
  );
};

const mapStateToProps = (state) => {
  const statusOptionList = state.appReducer.globalSettingsData.find(
    (item) => item.name.en === REVIEW_STATUS
  );
  return {
    statusOptionList,
    currentAttraction: state.attractionsReducer.currentAttraction,
    attractionList: state.attractionsReducer.attractionsSmallData,
    selectedLanguage: state.appReducer.selectedLanguage,
    selectedDestinationId: state.appReducer.selectedDestination.id
  };
};

export default connect(mapStateToProps, {
  addAttractionReviewThunk,
  getAttractionReivewsListThunk,
})(ReviewAddFormContainer);
